import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    TablePagination,
    Slider,
    InputLabel,
    FormControl,
} from '@mui/material';
import axios from 'axios';
import { useCallback } from "react";
import debounce from "lodash.debounce";

import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import GrainDetailPanel from './GrainDetailPanel';

import { styled } from '@mui/system';
import { useUnit } from '../../context/UnitContext';
import { Pause, QueryStatsRounded } from '@mui/icons-material';

const StyledButton = styled(Button)({
    padding: '10px 20px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    backgroundColor: '#2b2d42',
    color: '#f4f1de',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: 'none',
    transition: 'background-color 0.3s ease-in-out, transform 0.2s',
    '&:hover': {
        backgroundColor: '#FABC18',
        color: '#2b2d42',
        transform: 'scale(1.15)',
    },
});

const StyledSelect = styled(Select)({
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#fabc18',
        },
    },
});

const AdvancedButton = styled(Button)({
    color: '#2b2d42',
    textTransform: 'none',
});

const SliderWrapper = styled(Box)({
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
});

const GrainFinder = () => {
    const { unit, switchToImperial, switchToMetric } = useUnit();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [filters, setFilters] = useState({
        category: [],
        type: [],
        styleGuide: [],
        origin: [],
        color: [0, 100],
        dp: [0, 300],
        acid: [0, 10],
    });

    const [totalResults, setTotalResults] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedGrain, setSelectedGrain] = useState(null);
    const [showAdvanced, setShowAdvanced] = useState(false);

    // **Build query string for API request**
    const buildQueryString = () => {
        const query = new URLSearchParams();

        if (searchInput) query.append('search', searchInput);
        if (filters.category.length) query.append('malt_category', filters.category.join(','));
        if (filters.type.length) query.append('malt_type', filters.type.join(','));
        if (filters.styleGuide.length) query.append('beer_style_malt', filters.styleGuide.join(','));
        if (filters.origin.length) query.append('malt_origin', filters.origin.join(','));

        if (filters.color[0] > 0) query.append('malt_color_min', filters.color[0]);
        if (filters.color[1] > 0) query.append('malt_color_max', filters.color[1]);
        if (filters.dp[0] > 0) query.append('diastatic_power_min', filters.dp[0]);
        if (filters.dp[1] > 0) query.append('diastatic_power_max', filters.dp[1]);
        if (filters.acid[0] > 0) query.append('acid_min', filters.acid[0]);
        if (filters.acid[1] > 0) query.append('acid_max', filters.acid[1]);

        query.append('page', page + 1);
        query.append('size', rowsPerPage);

        return query.toString();
    };


    // **Apply filters and fetch data**
    const applyFilters = async () => {
        try {
            setLoading(true);
            const queryString = buildQueryString();
            const response = await axios.get(
                `https://5ja6v4gg19.execute-api.us-east-1.amazonaws.com/prod/ingredients?type=fermentables_malted_grains&${queryString}`
            );
            const { total, data } = response.data;
            setFilteredData(data);
            setTotalResults(total);
            setLoading(false);
        } catch (error) {
            setFilteredData([]);
            setTotalResults(0);
            setLoading(false);
        }
    };


    // **Handle search button click**
    const handleSearchClick = () => {
        setPage(0); // Reset to first page
        applyFilters(); // Fetch data based on current filters and search input
    };

    // **Clear filters and reset**
    const clearFilters = async () => {
        const defaultFilters = {
            category: [], //
            type: [], // 
            styleGuide: [], //
            origin: [], // 
            color: [0, 100], // 
            dp: [0, 300], //
            acid: [0, 10],
        };

        setSearchInput(''); // Clear search input
        setPage(0); // Reset pagination
        setFilters(defaultFilters); // Update filters in state

        // Pass default filters directly to applyFilters
        await applyFilters({
            search: '',
            ...defaultFilters,
        });
    };



    // **Pagination handlers**
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        applyFilters(); // Ensure this is correctly updating the filtered data
    };

    const handleChangeRowsPerPage = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setRowsPerPage(newSize);
        setPage(0); // Reset to first page
        applyFilters(); // Ensure new size is applied
    };

    // **Fetch initial data on mount**
    useEffect(() => {
        applyFilters();
    }, []); // Initial data load only




    // Update the search input value
    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };



    const handleFilterChange = (key, value) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters, [key]: value };
            // applyFilters(); // Trigger API call with updated filters
            return updatedFilters;
        });
    };



    const handleRowClick = (grain) => {
        setSelectedGrain(grain.uid); // Pass only the hop ID
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedGrain(null);
    };




    return (
        <Box sx={{ padding: '20px', backgroundColor: '#FAF9F1', minHeight: '100vh' }}>
            {/* Header Section */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #ccc',
                    paddingBottom: '10px',
                }}
            >
                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                    Malt & Grain Finder
                </Typography>
                <StyledSelect
                    value={unit}
                    onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px' }}
                >
                    <MenuItem value="Metric">Metric</MenuItem>
                    <MenuItem value="Imperial">Imperial</MenuItem>
                </StyledSelect>
            </Box>

            {/* Introduction */}
            <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '16px', marginTop: '20px' }}>
                Discover a curated selection of grains and malts, carefully chosen to provide detailed insights into their flavor, color,
                and enzymatic properties. These ingredients play a vital role in defining the body, aroma, and character of your beer.
                Each entry is clickable, offering additional details about its origin, typical beer styles, and usage recommendations.
                This list is an invaluable resource for brewers looking to perfect their recipes or explore new styles.
            </Typography>


            {/* Search Bar */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px', gap: '10px' }}>
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <SearchIcon
                        sx={{
                            position: 'absolute',
                            left: '10px',
                            color: '#555',
                        }}
                    />
                    <TextField
                        value={searchInput}
                        onChange={handleInputChange}
                        placeholder="Search Malt & Grain"
                        variant="outlined"
                        fullWidth
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                paddingLeft: '40px', // Add padding to make space for the icon
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FABC18', // Change border color when focused
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#555', // Change text color inside the input
                            },
                        }}
                    />
                </Box>
                <AdvancedButton
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    sx={{ alignSelf: 'flex-end', textTransform: 'none', color: '#2b2d42' }}
                >
                    {showAdvanced ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                </AdvancedButton>

            </Box>



            {/* Advanced Filters */}
            {showAdvanced && (
                <Box sx={{ marginTop: '20px', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                    Our advanced filters enable you to refine your search results with precision.
                    The dropdown menus support selecting multiple options. Keep in mind that selecting multiple options
                    (e.g., Roasted Malt, Extract, Base) will return grains associated with either Chocolate or Nutty flavors.
                    This behavior applies to all dropdowns, allowing you to explore a wide range of malts that fit your brewing needs.

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Category (e.g. Grain, Extract)</InputLabel>
                            <StyledSelect
                                value={filters.category}
                                multiple
                                onChange={(e) => handleFilterChange('category', e.target.value)}
                            >
                                {['Grain', 'Liquid Extract', 'Adjunct', 'Dry Extract'].map((category) => (
                                    <MenuItem key={category} value={category}>
                                        {category}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>


                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Malt Types (TBD)</InputLabel>
                            <StyledSelect
                                value={filters.type}
                                multiple
                                onChange={(e) => handleFilterChange('type', e.target.value)}
                            >
                                {['Specialty Malt',
                                    'Roasted Malt',
                                    'Raw',
                                    'Gluten-free Malt',
                                    'Extract',
                                    'Crystal Malt',
                                    'Base Malt',
                                    'Acidulated Malt'
                                ].map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>

                    </Box>

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Beer Styles (e.g. APA, Lager)</InputLabel>
                            <StyledSelect
                                value={filters.styleGuide}
                                multiple
                                onChange={(e) => handleFilterChange('styleGuide', e.target.value)}
                            >
                                {[
                                    'APA',
                                    'Blonde Ale',
                                    'Brown Ale',
                                    'Cream Ale',
                                    'Double IPA',
                                    'English Bitter',
                                    'English IPA',
                                    'English Pale Ale',
                                    'Golden Ale',
                                    'IPA',
                                    'Kolsch',
                                    'Lager',
                                    'NEIPA',
                                    'Pils',
                                    'Stout',
                                    'Wheat Beer',

                                ].map((style) => (
                                    <MenuItem key={style} value={style}>
                                        {style}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>



                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Origin</InputLabel>
                            <StyledSelect
                                value={filters.origin}
                                multiple
                                onChange={(e) => handleFilterChange('origin', e.target.value)}
                            >
                                {['Argentina',
                                    'Australia',
                                    'Belgium',
                                    'Brazil',
                                    'Canada',
                                    'Chile',
                                    'Czech Republic',
                                    'Finland',
                                    'France',
                                    'Germany',
                                    'Ireland',
                                    'Netherlands',
                                    'New Zealand',
                                    'Poland',
                                    'Spain',
                                    'United Kingdom',
                                    'United States of America'
                                ].map((origin) => (
                                    <MenuItem key={origin} value={origin}>
                                        {origin}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>



                    </Box>
                    The sliders can be adjusted as needed to refine your search. The selected range will include
                    all values between the minimum and maximum you set. For example, searching for grains with a
                    color range of 5-20°L will return malts with a color rating of at least 5°L and at most 20°L,
                    including both 5°L and 20°L. Similarly, diastatic power and acidity sliders allow you to explore
                    grains with specific enzymatic and pH characteristics that match your brewing requirements.

                    {[
                        { key: 'color', label: 'Color (°L)', max: 100 },
                        { key: 'dp', label: 'Diastatic Power (°L)', max: 300 },
                        { key: 'acid', label: 'Acid (%)', max: 10 },
                    ].map((slider) => (
                        <SliderWrapper key={slider.key}>
                            {/* Display label with selected range */}
                            <Typography>
                                {slider.label} | {filters[slider.key][0]} - {filters[slider.key][1]}
                            </Typography>
                            <Slider
                                value={filters[slider.key]}
                                onChange={(e, newValue) => handleFilterChange(slider.key, newValue)}
                                valueLabelDisplay="auto"
                                min={0}
                                max={slider.max}
                                sx={{
                                    '& .MuiSlider-thumb': {
                                        bgcolor: '#FABC18', // Button color
                                        border: '2px solid #FABC18555', // Border color

                                        '&:hover, &.Mui-focusVisible': {
                                            boxShadow: '0px 0px 0px 8px rgba(250, 188, 24, 0.16)',
                                        },
                                    },
                                    '& .MuiSlider-track': {
                                        bgcolor: '#FABC18', // Selected area color
                                        border: '2px solid #FABC18', // Border color

                                    },
                                    '& .MuiSlider-rail': {
                                        bgcolor: '#2b2d42', // Background rail color
                                    },
                                }}
                            />
                        </SliderWrapper>
                    ))}


                    Click the "Search Malt & Grain" button to update the search after refining the filters.

                </Box>


            )
            }
            {/* Clear and Search Buttons */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '20px',
                    borderTop: '1px solid #ccc',
                    paddingTop: '10px',
                }}
            >
                <Button
                    onClick={clearFilters}
                    variant="outlined"
                    sx={{ color: '#2b2d42', borderColor: '#2b2d42' }}
                    startIcon={<DeleteIcon />}
                >
                    Clear Filters
                </Button>
                <Button
                    onClick={applyFilters}
                    variant="contained"
                    sx={{ bgcolor: '#FABC18', color: '#2b2d42' }}
                    endIcon={<SearchIcon />}
                >
                    Search Malt & Grain
                </Button>
            </Box>

            {/* Table Section */}
            <Typography
                sx={{
                    marginTop: '20px',
                    textAlign: 'left',
                    fontSize: '12px',
                    color: '#555'
                }}
            >
                {totalResults} different malt & grains found
            </Typography>


            {loading ? (
                <Typography>Loading grain data...</Typography>
            ) : (
                <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#f4f1de' }}>Name</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Category</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Type</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Color (°L)</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Diastatic Power</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Acid (%)</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }} >Origin</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.length ? (
                                filteredData.map((row) => (
                                    <TableRow
                                        key={row.uid}
                                        onClick={() => handleRowClick(row)}
                                        sx={{ cursor: "pointer", "&:hover": { bgcolor: "#f5f5f5" } }}
                                    >
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.category}</TableCell>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.color_l}</TableCell>
                                        <TableCell>{row.dp}</TableCell>
                                        <TableCell>{row.acidity}</TableCell>
                                        <TableCell>{row.origin}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No matching grain entries found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
            )}

            {/* Use the GrainDetailPanel*/}
            <GrainDetailPanel
                open={open}
                onClose={handleClose}
                grainId={selectedGrain} // Pass the selectedHop as hopId
            />
            <TablePagination
                component="div"
                count={totalResults} // Total number of records
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25]} // Optional customization
            />


            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <StyledButton
                    onClick={() => {
                        window.location.href = "mailto:contact@connected-brewery.com?subject=Submit%20New%20Grain%20Information";
                    }}
                >
                    Submit New Grain Information
                </StyledButton>
            </Box>



            <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '20px', color: '#555' }}>
                Malts and grains are the foundation of brewing, providing fermentable sugars, color, flavor, and body to beer.
                They play a crucial role in creating the character and style of the final product, influencing everything from
                the beer’s sweetness to its mouthfeel and visual appeal. Without grains and malts, beer as we know it would not exist.
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                How to Use and Filter Options
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Use the search bar and advanced filters to explore a wide range of grains and malts. Filter by categories, origin,
                type, color (°L), diastatic power (DP), and acidity (pH) to find malts that match your brewing needs. Each filter
                is designed to help you narrow down your options and make informed decisions for your recipes. For example, you
                can select "Caramel Malts" under category, specify an origin like "Germany," and adjust the sliders to target
                malts with a color range of 10-20°L.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The database allows you to compare multiple malts side-by-side, helping you evaluate their flavor contributions,
                enzymatic strength, and compatibility with your brewing goals. Whether you're designing a recipe for a crisp
                lager or a robust stout, this tool simplifies the process of selecting the perfect grains.
            </Typography>


            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '20px', color: '#555' }}>
                What Are Malts?
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Malts are processed grains, typically barley, that have been germinated, dried, and sometimes roasted or kilned.
                This process develops enzymes and converts stored starches into sugars that yeast can ferment. While barley is
                the most common grain used in brewing due to its high enzyme content and husk, other grains like wheat, rye, and oats
                are also used to add specific flavors and textures. Malts are classified into two main categories: base malts,
                which provide fermentable sugars and enzymes, and specialty malts, which contribute flavor, color, and complexity.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '20px', color: '#555' }}>
                Why Are Malts Essential?
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Malts are indispensable to brewing because they provide the sugars that yeast ferments to produce alcohol and carbonation.
                Beyond fermentation, malts define the beer’s flavor profile, adding notes of bread, caramel, chocolate, coffee, and more.
                They also influence the beer’s body, mouthfeel, and visual characteristics, such as color and clarity. Additionally,
                the enzymatic activity in malts is critical for converting starches into fermentable sugars during the mashing process.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Types of Malts and Their Functions
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Malts are categorized into two primary types: base malts and specialty malts. Each type serves a unique purpose in brewing,
                from providing fermentable sugars to creating complex flavors and enhancing visual appeal. Understanding the different types
                of malts and their functions helps brewers craft beers with depth, balance, and consistency.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Base Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Base malts, such as Pilsner, Pale Ale, and Munich malts, are the backbone of most beer recipes. They contain high levels
                of enzymatic activity (diastatic power), which is essential for converting starches into fermentable sugars during mashing.
                These malts typically have a light color (e.g., 1.5–10°L) and provide subtle flavors such as grainy, bready, or slightly sweet
                notes. Base malts contribute the majority of the fermentable sugars in beer and form 60–100% of the grain bill, depending on
                the style being brewed.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Specialty Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Specialty malts are kilned or roasted to develop specific flavors, colors, and characteristics. These malts typically have
                little to no diastatic power and are used in smaller proportions (5–40%) to complement the base malts. Caramel and crystal
                malts add sweetness, caramel notes, and a fuller body, while roasted malts, such as Chocolate Malt or Black Patent Malt,
                contribute deep colors and flavors like coffee, cocoa, and burnt toast. Specialty malts are key for creating distinct beer
                styles such as stouts, porters, and amber ales.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Caramel and Crystal Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Caramel and crystal malts are unique in that their starches are converted into sugars during the kilning process, resulting
                in a glassy texture. These malts add sweetness, color, and flavors ranging from toffee and caramel to dried fruit. They are
                commonly used in beers like amber ales, red ales, and bocks to enhance malt complexity and body.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Roasted Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Roasted malts are exposed to high temperatures, developing dark colors and intense flavors. Examples include Chocolate Malt,
                Black Malt, and Roasted Barley. These malts contribute roasted, burnt, or coffee-like flavors and are responsible for the
                deep brown to black colors in stouts, porters, and some lagers. Due to their strong flavor, roasted malts are used sparingly,
                typically comprising less than 10% of the grain bill.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Adjunct Grains
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Adjunct grains, such as corn, rice, oats, and wheat, are used alongside barley malts to add unique flavors, textures, or
                cost-effective fermentable sugars. For instance, wheat adds protein for better head retention and a creamy mouthfeel, while
                oats create a silky texture often desired in stouts and hazy IPAs. Adjuncts are common in styles like wheat beers, cream ales,
                and light lagers.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                How to Evaluate Malt Quality
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Evaluating the quality of malts is critical to achieving consistent results in brewing. High-quality malts provide the
                desired flavor, color, and fermentable sugars, while poor-quality malts can negatively impact the beer’s taste, clarity,
                and stability. Brewers assess malt quality by examining several key attributes, including appearance, aroma, texture, and
                storage conditions.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Appearance
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Good-quality malts have a consistent size, shape, and color. Uniformity ensures predictable results during milling and mashing,
                as evenly crushed grains maximize sugar extraction. Avoid malts with excessive dust, broken kernels, or discoloration, as these
                may indicate improper handling, aging, or pest infestation. Specialty malts should display the rich hues associated with their
                type, such as the deep brown of roasted malts or the amber tones of caramel malts.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Aroma
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The aroma of malt is a reliable indicator of freshness and quality. High-quality malts have a pleasant, sweet, and nutty smell
                that reflects their type and level of kilning. For example, Pilsner malt should smell clean and slightly grainy, while roasted
                malts may have coffee or chocolate-like aromas. Avoid malts with musty, sour, or off-putting odors, as these suggest spoilage,
                oxidation, or improper storage.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Texture
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The texture of malt provides clues about its moisture content and processing. High-quality malts feel firm and slightly dry to
                the touch. Excessively dry or brittle malts may have been over-dried, while sticky or soft malts can indicate high moisture
                content, which increases the risk of mold or spoilage. Specialty malts, such as caramel malts, may have a glassy or crystalline
                texture, which is a normal result of their production process.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Moisture Content
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Low moisture content is essential for preserving malt quality during storage. Malts with a moisture content between 3% and 6%
                are ideal, as this minimizes the risk of microbial growth and degradation. Malts with higher moisture levels may spoil quickly,
                particularly in warm or humid environments. Always check the packaging or specifications for moisture levels and store malts
                in dry, controlled conditions.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Storage Conditions
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The way malts are stored directly affects their quality and usability. Proper storage involves keeping malts in a cool, dry,
                and dark environment to prevent oxidation, moisture absorption, and pest infestation. Vacuum-sealed or airtight bags are ideal
                for maintaining freshness. Malts stored in open or poorly sealed containers are more susceptible to degradation and should be
                inspected carefully before use.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                How to Identify Poor-Quality Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Poor-quality malts often exhibit discoloration, inconsistent kernel sizes, or an excessive amount of fine dust and debris.
                Musty or sour odors indicate spoilage, while brittle or soft kernels suggest improper processing or storage. If the malts
                show signs of mold, pests, or significant deterioration, they should not be used for brewing.
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Storage and Handling of Malts
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Proper storage and handling of malts are crucial to preserving their quality, ensuring consistent brewing results, and
                preventing spoilage. Malts are sensitive to environmental factors like moisture, heat, and light, which can degrade their
                flavor, color, and enzymatic properties. By following best practices for storage and handling, brewers can maintain the
                freshness and functionality of their grains.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Storage Environment
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The ideal storage environment for malts is cool, dry, and free from light. Temperatures should be kept below 20°C (68°F),
                with a relative humidity of less than 50%. High temperatures can accelerate the breakdown of enzymes and reduce the malt's
                ability to convert starches into sugars, while excessive humidity promotes mold growth and pest infestations. Protect malts
                from light exposure to prevent discoloration and loss of flavor.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Packaging and Containers
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Malts should be stored in airtight containers or vacuum-sealed bags to minimize exposure to oxygen and moisture. Plastic
                buckets with tight-fitting lids, food-grade bins, or heavy-duty resealable bags are effective for homebrewers. For commercial
                brewers, bulk malts are often stored in dedicated silos or large bags lined with protective layers. Ensure that storage
                containers are clean, dry, and free of contaminants to maintain the integrity of the malt.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Pest Prevention
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Grains are a natural attractant for pests like weevils and rodents. To prevent infestations, store malts in sealed containers
                that are resistant to pests. Inspect storage areas regularly for signs of activity, such as holes in packaging, droppings, or
                damaged kernels. If an infestation occurs, discard the affected malts and thoroughly clean the storage area before restocking.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                When to Grind Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Grinding, or milling, malts should be done as close to the brewing session as possible to preserve their freshness and
                prevent oxidation. Once ground, malts are more susceptible to moisture and degradation, which can negatively affect flavor
                and enzymatic activity. Use a malt mill to crush grains evenly, creating a mixture of coarse grits and intact husks. This
                balance ensures efficient starch conversion during mashing while maintaining proper lautering.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Shelf Life of Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The shelf life of malts depends on their type and storage conditions. Base malts can typically be stored for up to one year
                under optimal conditions, while specialty malts, which have lower moisture content, may last longer. Always check the
                malt’s aroma and appearance before use, and discard any malts that show signs of spoilage, such as off odors, discoloration,
                or mold growth.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Tips for Long-Term Storage
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                For long-term storage, divide large quantities of malt into smaller, single-use portions before sealing them in airtight
                containers. Label each container with the malt type, batch number, and date of purchase to track freshness. Consider
                using oxygen absorbers or silica gel packs in storage containers to further extend shelf life by reducing moisture and
                oxygen exposure.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Key Metrics: PPG, Color (°L), Diastatic Power (DP), and pH
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Understanding key malt metrics such as PPG (Points Per Pound Per Gallon), color (°L), diastatic power (DP), and pH
                is essential for creating well-balanced and consistent beers. These metrics allow brewers to predict the performance
                and contributions of malts in a recipe, helping to fine-tune the flavor, color, and fermentability of the wort.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                PPG (Points Per Pound Per Gallon)
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                PPG measures the potential sugar extract of a malt. It indicates how much specific gravity (SG) one pound of malt
                will add to one gallon of water at 100% extraction efficiency. For example, a malt with a PPG of 36 will yield a
                specific gravity of 1.036 if one pound is mashed in one gallon of water. Brewers use PPG to calculate the expected
                original gravity (OG) of their wort and adjust their recipes for desired alcohol levels. Note that actual extraction
                efficiency will depend on the brewing system, typically ranging from 65% to 85% for most brewers.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Color (°L)
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Color, measured in degrees Lovibond (°L), represents the malt’s contribution to the beer’s color. Lighter malts,
                such as Pilsner or Pale Malt, have values between 1–10°L and produce pale beers like lagers or pale ales. Darker
                malts, such as Chocolate Malt or Black Patent Malt, can have values exceeding 400°L and contribute rich brown or
                black hues to stouts, porters, and dark lagers. The combined color contributions of all malts in a recipe determine
                the beer’s final color, often calculated using the SRM (Standard Reference Method) scale.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Diastatic Power (DP)
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Diastatic power, measured in degrees Lintner (°L), indicates the enzymatic strength of a malt and its ability to
                convert starches into fermentable sugars. Base malts typically have high DP values (120–160°L), making them capable
                of converting their own starches and those of adjunct grains. Specialty malts, which undergo intense kilning or roasting,
                often have little to no diastatic power and rely on the enzymes in base malts for starch conversion. For recipes with
                high percentages of adjuncts or specialty malts, ensure that the total DP of the grain bill is sufficient for efficient
                mashing, typically above 30°L.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                pH
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The pH of malt influences the mash pH, which is a critical factor for enzymatic activity during mashing. Base malts
                generally have a pH between 5.7–6.0, while darker malts, such as Roasted Barley or Black Malt, tend to be more acidic,
                with pH values around 4.5–5.5. An optimal mash pH of 5.2–5.6 promotes efficient starch conversion, enhances beer flavor
                stability, and improves clarity. Brewers often adjust mash pH using brewing salts, acids, or alkaline water treatments
                based on the contributions of their chosen malts.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                How to Use These Metrics in Recipe Design
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                By understanding PPG, color, diastatic power, and pH, brewers can design recipes that achieve their target gravity,
                flavor, and visual characteristics. For example, selecting a combination of base malts and specialty malts with
                complementary PPG and color values ensures the beer has both adequate fermentable sugars and the desired hue.
                Adjusting for diastatic power ensures proper starch conversion, while monitoring pH levels maintains enzymatic
                activity and balances flavor.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Flavor Contributions of Malts
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Malts are the primary contributors to a beer’s flavor profile, influencing its sweetness, richness, and complexity.
                Each type of malt brings unique characteristics to the final product, from subtle bready notes to intense roasted or
                caramel flavors. Understanding how different malts contribute to flavor allows brewers to craft balanced and flavorful
                beers across a wide range of styles.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Base Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Base malts provide the foundation for most beers, offering mild, grainy, or bready flavors. Pilsner malt has a
                clean, slightly sweet profile suitable for lagers and pale ales, while Pale Ale malt adds a richer, malty depth
                commonly associated with English ales. Munich and Vienna malts, while still considered base malts, contribute
                more pronounced toasty and biscuity notes, making them ideal for darker lagers like Märzens and Dunkels.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Caramel and Crystal Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Caramel and crystal malts are known for their sweet, toffee-like flavors and fuller body. Lighter caramel malts
                impart honey and golden syrup notes, while darker varieties offer flavors of caramelized sugar, molasses, or
                dried fruit. These malts also enhance mouthfeel, making them a staple in amber ales, red ales, and stouts.
                Their sweetness helps balance the bitterness of hops, creating a harmonious flavor profile.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Roasted Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Roasted malts provide bold, intense flavors like coffee, dark chocolate, and burnt toast. Chocolate malt delivers
                rich cocoa and nutty notes, while Black Patent Malt offers sharper, burnt flavors reminiscent of espresso.
                Roasted Barley, which is unmalted, contributes a dry, astringent roast character. These malts are integral to
                dark beers like porters, stouts, and some dark lagers, where they add complexity and a distinctive edge.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Adjunct Grains and Specialty Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Adjunct grains and specialty malts expand the brewer’s flavor palette. Wheat adds a creamy mouthfeel and subtle
                breadiness, while oats contribute a silky texture and smooth body. Rye introduces a spicy, earthy flavor, often
                found in rye IPAs and roggenbiers. Specialty malts like Victory or Biscuit Malt bring nutty, toasty flavors
                reminiscent of freshly baked bread, enhancing beers like amber ales and brown ales.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Balancing Malt Flavors in Beer
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Crafting a balanced malt profile requires careful selection and proportioning of malts. For example, using too
                much roasted malt can overpower lighter flavors, resulting in an overly bitter or burnt taste. Conversely,
                relying solely on base malts may produce a beer that lacks depth and complexity. Brewers often blend base malts
                with smaller amounts of specialty and roasted malts to achieve the desired balance of sweetness, body, and
                flavor intensity. The result is a beer that showcases the full spectrum of malt characteristics while remaining
                harmonious and drinkable.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Substitutions and Recommendations
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Substituting malts can be a useful strategy when your preferred variety is unavailable or when you wish to experiment
                with new flavors. Understanding the characteristics of different malts helps brewers make informed decisions about
                suitable replacements that maintain the intended flavor, color, and body of the beer. Substitutions can also provide
                opportunities to explore creative variations in recipes.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Guidelines for Substituting Malts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                When substituting malts, choose replacements with similar properties, such as color (°L), flavor profile, and diastatic
                power. For instance, if a recipe calls for Vienna Malt but it’s unavailable, you can use a combination of Pale Ale Malt
                and a small amount of Munich Malt to replicate its toasty and biscuity qualities. Always consider the malt’s role in the
                recipe—whether it contributes fermentable sugars, color, or flavor—and adjust the proportions accordingly to maintain
                balance in the grain bill.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Common Malt Substitutions
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                - **Pilsner Malt**: Substitute with Pale Malt for a slightly richer flavor, or blend Pale Malt with a small percentage
                of Wheat Malt for light lagers or Belgian ales.
                - **Munich Malt**: Replace with Vienna Malt for a milder toastiness, or combine Pale Ale Malt with a small amount of
                Caramel Malt for added depth.
                - **Crystal/Caramel Malts**: Swap similar °L varieties (e.g., 40°L for 30°L or 50°L) to maintain sweetness and body.
                Adjust quantities for darker malts to prevent overpowering flavors.
                - **Roasted Malts**: Chocolate Malt can often replace Black Malt for a less intense roast character. Similarly, Roasted
                Barley can be substituted with Carafa Special Malts for a smoother, less astringent profile.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Experimentation and Creativity
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Substitutions are not limited to necessity; they can also be a means of exploring creative variations in beer styles.
                For example, using Rye Malt instead of Wheat Malt in a recipe can add a spicy complexity, while replacing traditional
                Caramel Malt with Honey Malt can introduce unique sweetness and floral undertones. Experimenting with substitutions
                allows brewers to push the boundaries of traditional styles and develop signature recipes.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Potential Risks of Substitutions
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                While substitutions can be beneficial, they come with potential risks. Differences in diastatic power may affect the
                mash’s efficiency and fermentability, while flavor and color deviations can alter the beer’s intended profile. Brewers
                should test substitutions in small batches before scaling up and carefully document changes to refine their recipes.
                When in doubt, consult malt specifications and style guidelines to ensure the substitute aligns with your brewing goals.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                The Malt Style Guide
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The malt style guide serves as a roadmap for brewers, helping them align their grain choices with specific beer styles.
                Every malt contributes unique flavors, colors, and aromas that define the character of a beer. By matching malts to
                styles, brewers can maintain traditional profiles or innovate while staying true to a style’s essence. Whether brewing
                a crisp lager or a bold stout, the style guide is an indispensable tool for crafting balanced and authentic recipes.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Matching Malts to Beer Styles
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Each beer style has a distinct malt profile that contributes to its flavor, appearance, and mouthfeel. For example:
                - **Lagers**: Use Pilsner or Pale Malt as the base for light color and clean flavors. Add Vienna or Munich Malt for toasty
                and malty notes in amber lagers or Märzens.
                - **IPAs and Pale Ales**: Pale Ale Malt provides a robust base, while small amounts of Caramel Malt add sweetness to balance
                the bitterness of hops.
                - **Stouts and Porters**: Roasted malts like Chocolate Malt or Roasted Barley create the dark color and rich flavors of coffee
                and cocoa. Add Caramel Malts for sweetness and complexity.
                - **Wheat Beers**: Wheat Malt is essential for creamy textures and a hazy appearance, complemented by Pilsner Malt for a light,
                crisp finish.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Balancing Flavor and Color
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The style guide emphasizes balancing flavor and color to achieve harmony in the final product. For example, adding too much
                roasted malt to a porter can result in harsh bitterness, while overusing Caramel Malts in an IPA may overpower the hops.
                Brewers can use the guide to adjust grain proportions, ensuring that the beer's flavor profile and visual appeal align with
                the intended style.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Innovating Within Styles
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                While the style guide provides a foundation, it also encourages innovation. Brewers can experiment with non-traditional
                grains, such as Rye Malt in IPAs for a spicy twist, or Honey Malt in lagers for subtle sweetness. By understanding the
                principles of malt contributions, brewers can push the boundaries of traditional styles while maintaining balance and
                drinkability.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Examples of Malt Pairings by Style
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                - **Amber Ale**: Pale Ale Malt as the base, with Caramel Malt (40°L) for sweetness and Biscuit Malt for toasty notes.
                - **Hazy IPA**: Pale Malt and Wheat Malt for haze and mouthfeel, with Oats for added smoothness.
                - **Doppelbock**: Munich Malt as the base, with Caramel Malt (120°L) for depth and a touch of Chocolate Malt for color.
                - **Saison**: Pilsner Malt as the base, with a small addition of Wheat Malt for creaminess and Spelt for rustic character.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Using the Style Guide Effectively
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The style guide is most effective when used alongside tools like malt specification sheets and brewing software. These
                resources help calculate metrics like color (°L), original gravity (OG), and bitterness-to-sweetness ratios. By combining
                the guide’s recommendations with precise calculations, brewers can create beers that meet stylistic expectations while
                showcasing their creativity.
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Why Origin is Relevant
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The origin of malts influences their flavor, enzymatic strength, and suitability for specific beer styles. Factors such as
                soil composition, climate, and traditional agricultural practices give malts from different regions their unique profiles.
                For example, German malts are prized for their clean, balanced flavors and high-quality craftsmanship, making them ideal for
                lagers and wheat beers. In contrast, British malts often provide rich, nutty, and biscuity flavors, perfect for ales and stouts.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Regional differences also affect enzymatic activity and protein levels. American six-row barley, for instance, has higher
                protein and diastatic power, making it well-suited for adjunct brewing. European two-row barley, on the other hand, typically
                has lower protein content and produces smoother, less grainy flavors. By understanding the origin of malts, brewers can select
                grains that align with their recipes and brewing goals, ensuring stylistic authenticity and optimal results.
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Understanding Acidity in Brewing
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Acidity in malts, expressed in terms of pH, plays a crucial role in the brewing process, particularly during mashing. The pH
                of malt influences enzymatic activity, wort stability, and flavor balance. Base malts generally have a pH of 5.7–6.0, while
                darker specialty malts, such as Roasted Barley, are more acidic, with pH values around 4.5–5.5.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                An optimal mash pH of 5.2–5.6 promotes efficient starch conversion and protein breakdown, enhances yeast performance during
                fermentation, and improves the clarity and flavor stability of the final beer. Brewers often use darker malts to naturally
                adjust mash pH downward or add brewing salts and acids to achieve the target range. Monitoring and managing pH is essential
                for producing beers with balanced flavors and consistent quality.
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                The Role of Enzymes in Brewing
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Enzymes in malt are critical for converting starches into fermentable sugars and breaking down proteins during the mashing
                process. The primary enzymes involved in brewing include alpha-amylase, beta-amylase, proteases, and beta-glucanases. Each
                plays a specific role in creating a wort that is rich in fermentable sugars and suitable for yeast fermentation.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                - **Alpha-Amylase**: Breaks down large, complex starches into shorter sugar chains. This enzyme is most active at temperatures
                of 65–70°C (149–158°F), contributing to the wort's body and mouthfeel.
                - **Beta-Amylase**: Converts starches into simple fermentable sugars like maltose. It operates best at lower temperatures of
                60–65°C (140–149°F), producing a highly fermentable wort.
                - **Proteases**: Break down proteins into amino acids and peptides, improving yeast nutrition and aiding head retention. These
                enzymes are active in the 45–55°C (113–131°F) range.
                - **Beta-Glucanases**: Reduce beta-glucans, which can cause viscosity and lautering issues. These enzymes are active at
                35–45°C (95–113°F).
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Proper temperature management during mashing is essential for optimizing enzyme activity. By tailoring mash rests to specific
                enzymes, brewers can control the fermentability, body, and clarity of the beer, ensuring the final product meets their desired
                specifications.
            </Typography>


            {/* Disclaimer */}
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Disclaimer
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Malts are an agricultural product, and their composition, flavor, and enzymatic properties can vary due to factors such as
                growing conditions, harvest timing, and processing methods. Environmental influences, including soil quality, climate, and
                regional agricultural practices, play a significant role in shaping the unique characteristics of each batch of malt.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                While we strive to ensure the accuracy and reliability of the information provided in our database, variations in malt
                properties may occur between harvests or suppliers. Additionally, storage conditions such as temperature, humidity, and
                exposure to air can significantly affect the freshness and quality of malts over time.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Brewers are encouraged to inspect their malts before use, checking for visual consistency, aroma, and overall condition.
                Following best practices for storage and handling will help preserve malt integrity and maintain consistent brewing results.
                Always refer to malt specification sheets for detailed information on each variety.
            </Typography>


        </Box >
    );
};

export default GrainFinder;
