import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    TablePagination,
    Slider,
    InputLabel,
    FormControl,
} from '@mui/material';
import axios from 'axios';
import { useCallback } from "react";
import debounce from "lodash.debounce";

import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import HopDetailPanel from './HopDetailPanel';

import { styled } from '@mui/system';
import { useUnit } from '../../context/UnitContext';
import { Pause, QueryStatsRounded } from '@mui/icons-material';

const StyledButton = styled(Button)({
    padding: '10px 20px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    backgroundColor: '#2b2d42',
    color: '#f4f1de',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: 'none',
    transition: 'background-color 0.3s ease-in-out, transform 0.2s',
    '&:hover': {
        backgroundColor: '#FABC18',
        color: '#2b2d42',
        transform: 'scale(1.15)',
    },
});

const StyledSelect = styled(Select)({
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#fabc18',
        },
    },
});

const AdvancedButton = styled(Button)({
    color: '#2b2d42',
    textTransform: 'none',
});

const SliderWrapper = styled(Box)({
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
});

const HopFinder = () => {
    const { unit, switchToImperial, switchToMetric } = useUnit();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [filters, setFilters] = useState({
        purpose: [],
        aroma: [],
        styleGuide: [],
        type: [],
        alpha: [0, 45],
        beta: [0, 25],
        coHumulone: [0, 65],
        totalOils: [0, 15],
    });
    const [totalResults, setTotalResults] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedHop, setSelectedHop] = useState(null);
    const [showAdvanced, setShowAdvanced] = useState(false);

    // **Build query string for API request**
    const buildQueryString = () => {
        const query = new URLSearchParams();

        if (searchInput) query.append('search', searchInput);
        if (filters.purpose.length) query.append('purpose', filters.purpose.join(','));
        if (filters.aroma.length) query.append('aroma', filters.aroma.join(','));
        if (filters.styleGuide.length) query.append('beer_style', filters.styleGuide.join(','));
        if (filters.type.length) query.append('hop_type', filters.type.join(','));

        if (filters.alpha[0] > 0) query.append('alpha_min', filters.alpha[0]);
        if (filters.alpha[1] < 20) query.append('alpha_max', filters.alpha[1]);
        if (filters.beta[0] > 0) query.append('beta_min', filters.beta[0]);
        if (filters.beta[1] < 10) query.append('beta_max', filters.beta[1]);
        if (filters.coHumulone[0] > 0) query.append('cohum_min', filters.coHumulone[0]);
        if (filters.coHumulone[1] < 40) query.append('cohum_max', filters.coHumulone[1]);
        if (filters.totalOils[0] > 0) query.append('oil_min', filters.totalOils[0]);
        if (filters.totalOils[1] < 3) query.append('oil_max', filters.totalOils[1]);

        query.append('page', page + 1);
        query.append('size', rowsPerPage);

        return query.toString();
    };

    // **Apply filters and fetch data**
    const applyFilters = async () => {
        try {
            setLoading(true);
            const queryString = buildQueryString();
            const response = await axios.get(
                `https://5ja6v4gg19.execute-api.us-east-1.amazonaws.com/prod/ingredients?type=hops&${queryString}`
            );
            const { total, data } = response.data;
            setFilteredData(data);
            setTotalResults(total);
            setLoading(false);
        } catch (error) {
            setFilteredData([]);
            setTotalResults(0);
            setLoading(false);
        }
    };

    // **Handle search button click**
    const handleSearchClick = () => {
        setPage(0); // Reset to first page
        applyFilters(); // Fetch data based on current filters and search input
    };

    // **Clear filters and reset**
    const clearFilters = async () => {
        const defaultFilters = {
            purpose: [],
            aroma: [],
            styleGuide: [],
            type: [],
            alpha: [0, 45],
            beta: [0, 25],
            coHumulone: [0, 65],
            totalOils: [0, 15],
        };

        setSearchInput(''); // Clear search input
        setPage(0); // Reset pagination
        setFilters(defaultFilters); // Update filters in state

        // Pass default filters directly to applyFilters
        await applyFilters({
            search: '',
            ...defaultFilters,
        });
    };


    // **Pagination handlers**
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        applyFilters(); // Ensure this is correctly updating the filtered data
    };

    const handleChangeRowsPerPage = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setRowsPerPage(newSize);
        setPage(0); // Reset to first page
        applyFilters(); // Ensure new size is applied
    };

    // **Fetch initial data on mount**
    useEffect(() => {
        applyFilters();
    }, []); // Initial data load only




    // Update the search input value
    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };



    const handleFilterChange = (key, value) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters, [key]: value };
            // applyFilters(); // Trigger API call with updated filters
            return updatedFilters;
        });
    };



    const handleRowClick = (hop) => {
        setSelectedHop(hop.uid); // Pass only the hop ID
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedHop(null);
    };




    return (
        <Box sx={{ padding: '20px', backgroundColor: '#FAF9F1', minHeight: '100vh' }}>
            {/* Header Section */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #ccc',
                    paddingBottom: '10px',
                }}
            >
                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                    Hop Finder
                </Typography>
                <StyledSelect
                    value={unit}
                    onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px' }}
                >
                    <MenuItem value="Metric">Metric</MenuItem>
                    <MenuItem value="Imperial">Imperial</MenuItem>
                </StyledSelect>
            </Box>

            {/* Introduction */}
            <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '16px', marginTop: '20px' }}>
                Explore a curated collection of hops submitted by brewers worldwide. These hops provide valuable insights into aroma,
                bitterness, and characteristics, helping you choose the perfect hops for your brewing needs. Each hop is clickable,
                offering additional details about its origin, substitutes, and more. This list is an excellent resource for your
                brewing projects.
            </Typography>

            {/* Search Bar */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px', gap: '10px' }}>
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <SearchIcon
                        sx={{
                            position: 'absolute',
                            left: '10px',
                            color: '#555',
                        }}
                    />
                    <TextField
                        value={searchInput}
                        onChange={handleInputChange}
                        placeholder="Search Hops"
                        variant="outlined"
                        fullWidth
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                paddingLeft: '40px', // Add padding to make space for the icon
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FABC18', // Change border color when focused
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#555', // Change text color inside the input
                            },
                        }}
                    />
                </Box>
                <AdvancedButton
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    sx={{ alignSelf: 'flex-end', textTransform: 'none', color: '#2b2d42' }}
                >
                    {showAdvanced ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                </AdvancedButton>

            </Box>



            {/* Advanced Filters */}
            {showAdvanced && (
                <Box sx={{ marginTop: '20px', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                    Our advanced filters enable you to refine your search results with precision.
                    The dropdown menus support selecting multiple options. Keep in mind that selecting multiple options
                    (e.g., flavors: Chocolate, Dank) will return hops associated with either Chocolate or Dank flavors.
                    This behavior applies to all dropdowns.
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Select Purpose (e.g. Aroma)</InputLabel>
                            <StyledSelect
                                value={filters.purpose}
                                multiple
                                onChange={(e) => handleFilterChange('purpose', e.target.value)}
                            >
                                {[
                                    'Aroma',
                                    'Bittering',
                                    'Bittering & Aroma', // Add or modify hardcoded purposes here
                                ].map((purpose) => (
                                    <MenuItem key={purpose} value={purpose}>
                                        {purpose}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>


                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Flavour Profiles (e.g. Citrus, Pine)</InputLabel>
                            <StyledSelect
                                value={filters.aroma}
                                multiple
                                onChange={(e) => handleFilterChange('aroma', e.target.value)}
                            >
                                {[
                                    'Berry',
                                    'Caramel',
                                    'Chocolate',
                                    'Citrus',
                                    'Coconut',
                                    'Dank',
                                    'Earthy',
                                    'Eucalyptus',
                                    'Floral',
                                    'Grassy',
                                    'Herbal',
                                    'Licorice',
                                    'Minty',
                                    'Pine',
                                    'Resinous',
                                    'Spicy',
                                    'Stone Fruit',
                                    'Tea',
                                    'Tropical',
                                    'Vanilla',
                                    'Woody',

                                ].map((aroma) => (
                                    <MenuItem key={aroma} value={aroma}>
                                        {aroma}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>

                    </Box>

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Beer Styles (e.g. APA, Lager)</InputLabel>
                            <StyledSelect
                                value={filters.styleGuide}
                                multiple
                                onChange={(e) => handleFilterChange('styleGuide', e.target.value)}
                            >
                                {[
                                    'APA',
                                    'Blonde Ale',
                                    'Brown Ale',
                                    'Cream Ale',
                                    'Double IPA',
                                    'English Bitter',
                                    'English IPA',
                                    'English Pale Ale',
                                    'Golden Ale',
                                    'IPA',
                                    'Kolsch',
                                    'Lager',
                                    'NEIPA',
                                    'Pils',
                                    'Stout',
                                    'Wheat Beer',

                                ].map((style) => (
                                    <MenuItem key={style} value={style}>
                                        {style}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>



                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Type (e.g. Leafs, Pallets)</InputLabel>
                            <StyledSelect
                                value={filters.type}
                                multiple
                                onChange={(e) => handleFilterChange('type', e.target.value)}
                            >
                                {[
                                    'Whole Leaf',
                                    'Pellets',
                                    'Cryo',
                                    'Powder',
                                    'Extract',
                                    'Other',
                                ].map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>



                    </Box>
                    The sliders can be adjusted as needed to refine your search. The selected range will include
                    all values between the minimum and maximum you set. For example, searching for hops with an
                    alpha acid range of 5-12% will return hops with a concentration of at least 5.0% and at most
                    12.0%, including both 5.0 and 12.0.
                    {[
                        { key: 'alpha', label: 'Alpha Acid (%)', max: 45 },
                        { key: 'beta', label: 'Beta Acid (%)', max: 25 },
                        { key: 'coHumulone', label: 'Co-Humulone (%)', max: 65 },
                        { key: 'totalOils', label: 'Total Oils (ml/100g)', max: 15 },
                    ].map((slider) => (
                        <SliderWrapper key={slider.key}>
                            {/* Display label with selected range */}
                            <Typography>
                                {slider.label} | {filters[slider.key][0]}{slider.key !== 'totalOils' ? '%' : ''} -{' '}
                                {filters[slider.key][1]}{slider.key !== 'totalOils' ? '%' : ''}
                            </Typography>
                            <Slider
                                value={filters[slider.key]}
                                onChange={(e, newValue) => handleFilterChange(slider.key, newValue)}
                                valueLabelDisplay="auto"
                                min={0}
                                max={slider.max}
                                sx={{
                                    '& .MuiSlider-thumb': {
                                        bgcolor: '#FABC18', // Button color
                                        border: '2px solid #FABC18555', // Border color

                                        '&:hover, &.Mui-focusVisible': {
                                            boxShadow: '0px 0px 0px 8px rgba(250, 188, 24, 0.16)',
                                        },
                                    },
                                    '& .MuiSlider-track': {
                                        bgcolor: '#FABC18', // Selected area color
                                        border: '2px solid #FABC18', // Border color

                                    },
                                    '& .MuiSlider-rail': {
                                        bgcolor: '#2b2d42', // Background rail color
                                    },
                                }}
                            />
                        </SliderWrapper>
                    ))}


                    Click the "Search Hops" button to update the search after refining the filters.

                </Box>


            )
            }
            {/* Clear and Search Buttons */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '20px',
                    borderTop: '1px solid #ccc',
                    paddingTop: '10px',
                }}
            >
                <Button
                    onClick={clearFilters}
                    variant="outlined"
                    sx={{ color: '#2b2d42', borderColor: '#2b2d42' }}
                    startIcon={<DeleteIcon />}
                >
                    Clear Filters
                </Button>
                <Button
                    onClick={applyFilters}
                    variant="contained"
                    sx={{ bgcolor: '#FABC18', color: '#2b2d42' }}
                    endIcon={<SearchIcon />}
                >
                    Search Hops
                </Button>
            </Box>

            {/* Table Section */}
            <Typography
                sx={{
                    marginTop: '20px',
                    textAlign: 'left',
                    fontSize: '12px',
                    color: '#555'
                }}
            >
                {totalResults} different hops found
            </Typography>


            {loading ? (
                <Typography>Loading hops data...</Typography>
            ) : (
                <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#5a5a5a' }}>
                                <TableCell sx={{ color: '#f4f1de' }}>Name</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Purpose</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Aroma</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Alpha Acid</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Beta Acid</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Co-Humulone</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Total Oils</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Origin</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.length ? (
                                filteredData.map((row) => (
                                    <TableRow
                                        key={row.uid}
                                        onClick={() => handleRowClick(row)}
                                        sx={{ cursor: "pointer", "&:hover": { bgcolor: "#f5f5f5" } }}
                                    >
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.purpose}</TableCell>
                                        <TableCell>{row.aroma}</TableCell>
                                        <TableCell>{row.alpha} %</TableCell>
                                        <TableCell>{row.beta} %</TableCell>
                                        <TableCell>{row.cohumulone} %</TableCell>
                                        <TableCell>{row.total_oil} mL/100g</TableCell>
                                        <TableCell>{row.origin}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        No matching hop entries found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Use the HopDetailPanel */}
            <HopDetailPanel
                open={open}
                onClose={handleClose}
                hopId={selectedHop} // Pass the selectedHop as hopId
            />
            <TablePagination
                component="div"
                count={totalResults} // Total number of records
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25]} // Optional customization
            />


            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <StyledButton
                    onClick={() => {
                        window.location.href = "mailto:contact@connected-brewery.com?subject=Submit%20New%20Hop%20Information";
                    }}
                >
                    Submit New Hop Information
                </StyledButton>
            </Box>




            {/* Content Area */}
            <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '20px', color: '#555' }}>
                Hops are an essential ingredient in brewing, contributing bitterness, aroma, and unique flavors to beer.
                Use this tool to discover and experiment with hops that best suit your brewing style and preferences.
            </Typography>

            <Box sx={{ marginTop: '20px', color: '#555' }}>
                {/* How to Use and Filter Options */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    How to Use and Filter Options
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px' }}>
                    Use the search bar and advanced filters to explore a wide range of hops. Filter by alpha and beta acid content,
                    aroma, purpose, and more to find hops that match your brewing needs. The database is designed to help you
                    compare multiple hops side-by-side, making it easier to select the best options for your beer style.
                </Typography>

                {/* What Hops Are and Why They're Important */}

                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    What Are Hops and Why Are They Important?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Hops are the flowers, or cones, of the hop plant (<i>Humulus lupulus</i>) and are a cornerstone of brewing. They serve multiple critical roles, including contributing bitterness, enhancing flavor and aroma, acting as a natural preservative, and stabilizing beer. Without hops, beer would lack the balance, complexity, and longevity that define the beverage we know today.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    The Role of Hops in Brewing
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Hops bring essential qualities to beer. The alpha acids in hops are transformed during boiling into iso-alpha acids, which provide the bitterness that balances the sweetness of malt. Essential oils in hops create diverse flavors and aromas, ranging from citrus and pine to tropical fruit, herbal, floral, and earthy notes. These are enhanced when hops are added late in the brewing process or during dry hopping. Hops also have natural antibacterial properties that help protect beer from spoilage organisms, extending its shelf life and maintaining quality.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Parts of the Hop Cone and Their Contributions
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    The hop cone contains several components, each with a specific role in brewing. Lupulin glands, located inside the cone, contain alpha acids, beta acids, and essential oils, which are responsible for bitterness, flavor, and aroma. Alpha acids provide the characteristic bitterness that balances the beer’s flavor profile, while beta acids oxidize over time, contributing subtle flavors to aged beers. Essential oils create the diverse and distinctive aromas and flavors in beer, such as citrus, floral, or resinous notes. The bracts and strig, which are the leafy parts and central stem of the cone, provide structure but contribute minimally to flavor.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Storage and Quality of Hops
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Proper storage is essential to maintain hop quality. Hops are sensitive to light, heat, and oxygen, which can degrade their alpha acids and essential oils. To preserve their quality, hops should be stored in vacuum-sealed, airtight packaging to minimize oxygen exposure. They should be kept in a freezer or refrigerator to slow degradation caused by heat and protected from light by using opaque packaging to prevent spoilage.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    How to Evaluate Hop Quality
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    To determine if hops are good for brewing, assess their appearance, aroma, and storage conditions. Fresh hops should have a vibrant green color with visible yellow lupulin glands. Brown or dull cones indicate poor quality or age. High-quality hops have a strong, pleasant aroma characteristic of their variety, while musty or cheesy smells suggest oxidation or spoilage. Fresh hops feel slightly sticky due to lupulin, whereas dry or brittle hops may have been improperly stored or aged.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Why Hops Are Essential
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Hops are fundamental to beer’s identity. They balance the sweetness of malt, add layers of flavor and aroma, and preserve the beer’s quality. Whether creating a hop-forward IPA, a crisp pilsner, or a malty stout, hops provide the versatility and character that define beer styles. Their importance in brewing cannot be overstated.
                </Typography>



                {/* Explanation of Alpha, Beta, Co-Humulone, and Total Oils */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Understanding Alpha Acids, Beta Acids, Co-Humulone, and Total Oils
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Hops are an intricate part of beer brewing, and their chemical components significantly influence the flavor, aroma, and bitterness of the final product. Four key aspects of hops—alpha acids, beta acids, co-humulone, and total oils—each play a unique role in defining a beer’s character. Understanding these components at a scientific level allows brewers to select and use hops more effectively to achieve desired results.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Alpha Acids
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Alpha acids, primarily humulone, adhumulone, and co-humulone, are the primary bittering agents in hops. These compounds are located within the lupulin glands of hop cones. When hops are boiled, alpha acids undergo a chemical transformation known as isomerization, converting into iso-alpha acids. Iso-alpha acids are more soluble in the wort and are responsible for imparting beer’s characteristic bitterness. The degree of bitterness is measured in International Bitterness Units (IBUs), with higher concentrations of iso-alpha acids producing more pronounced bitterness. The efficiency of isomerization depends on boiling time, temperature, and pH. Hops with high alpha acid content are often referred to as “bittering hops,” as they are typically added early in the boil to maximize their bittering potential.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Beta Acids
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Beta acids, including lupulone, colupulone, and adlupulone, contribute to beer’s bitterness over time through a process of oxidation. Unlike alpha acids, beta acids do not isomerize during the boil and are largely insoluble in wort. However, when beer is aged or stored, beta acids oxidize to form compounds that impart bitterness. This oxidation-driven bitterness is less intense than that of iso-alpha acids and may add a smoother, more subdued bitter character to aged beers. Beta acids are also thought to play a minor role in the preservation of beer due to their natural antimicrobial properties. While not the primary focus for most brewers, understanding the beta acid content of hops can be useful for those interested in brewing beers intended for aging.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Co-Humulone
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Co-humulone is one of the three main alpha acids and is often associated with the quality of bitterness in beer. It is chemically distinct from humulone and adhumulone due to its lower molecular weight and slightly higher acidity. Bitterness derived from co-humulone is often described as sharper or harsher compared to the smoother bitterness from other alpha acids. As a result, brewers frequently prefer hops with a lower percentage of co-humulone relative to total alpha acids, especially for delicate or well-balanced beer styles. However, for some assertive styles, such as certain IPAs, the sharp bitterness of high co-humulone hops can enhance the beer’s character. Understanding the co-humulone percentage in hops helps brewers fine-tune the bitterness profile of their beers.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Total Oils
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Total oils in hops refer to the volatile compounds extracted from the lupulin glands, responsible for beer’s aroma and flavor. These oils include myrcene, humulene, caryophyllene, and farnesene, among others, each contributing distinct sensory characteristics. For example, myrcene imparts citrusy and fruity notes, while humulene adds earthy or woody aromas. The total oil content varies widely among hop varieties and is typically measured in milliliters per 100 grams of hops. Unlike alpha acids, total oils do not directly contribute to bitterness but instead provide the aromatic complexity that defines many beer styles. Late hop additions or dry hopping techniques maximize the contribution of total oils by minimizing their degradation during the boil. Total oil content and composition are critical considerations for brewers aiming to create highly aromatic beers such as IPAs or NEIPAs.
                </Typography>



                {/* Alias Names and Alternatives */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Alias Names and Alternatives
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Many hops have alias names or are known by different names in various regions. For instance, the same hop variety might be marketed under one name in Europe and another in the United States due to branding, local preferences, or historical usage. This can create confusion for brewers trying to identify or source specific hops, especially when recipes or suppliers use unfamiliar aliases. However, understanding these aliases can also expand your knowledge of available hops and help you discover similar varieties with subtle differences.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Our database provides comprehensive information on hop aliases and offers a curated list of alternatives and substitutes. These alternatives are particularly valuable when your preferred variety is unavailable due to seasonal limitations, supply chain issues, or market trends. Substitutes are recommended based on similarities in flavor profile, alpha acid content, and aroma characteristics, ensuring that the replacement hop fits seamlessly into your brewing process.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Pros of Hop Aliases and Alternatives
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Knowing alias names increases accessibility by helping brewers find the same hop variety under different names in local markets or international suppliers. Alternatives provide flexibility in brewing, allowing you to adapt recipes without compromising the flavor profile when specific hops are unavailable. Additionally, discovering substitutes can introduce you to new hop varieties with unique nuances that enhance your brewing creativity.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Cons of Hop Aliases and Alternatives
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Alias names can sometimes lead to confusion, making it difficult to identify the exact variety needed, especially for novice brewers or when purchasing hops internationally. Even the best substitutes may have slight differences in aroma, bitterness, or flavor intensity, which can impact your final product. Additionally, not all resources or suppliers provide clear alias information, leading to potential misunderstandings.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    By leveraging our database, you can confidently navigate hop aliases and alternatives, ensuring your brewing process remains consistent and innovative. Whether you’re trying to replicate a recipe or experiment with new flavors, having access to this information empowers you to make informed choices.
                </Typography>



                {/* About the Style Guide */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    About the Style Guide
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    The style guide is an essential tool for brewers, helping them identify which beer styles pair best with specific hop varieties. Every hop variety brings its unique set of flavors, aromas, and bitterness levels, and the style guide ensures that these characteristics align with the intended beer style. For example, brewing a crisp lager typically calls for hops with delicate floral or earthy notes, while a fruity IPA benefits from hops with bold citrus or tropical fruit aromas.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Why is the Style Guide Important?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    The style guide serves multiple purposes. It helps maintain the traditional flavor profiles and characteristics of established beer styles, ensuring that brews meet consumer expectations. For innovative brewers, the guide provides a framework to experiment with hops while staying true to the essence of a particular style. By matching hops to beer styles, the guide reduces trial and error, saving time and resources during the brewing process. Additionally, for homebrewers and newcomers, the guide is a valuable learning tool, offering insights into how hop characteristics influence different beer styles.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    The style guide is especially beneficial for brewers working with new hop varieties or substitutes. By referencing the guide, brewers can ensure that the hops they choose enhance the beer’s flavor, aroma, and overall profile while maintaining stylistic integrity. Whether you are crafting a robust stout, a refreshing wheat beer, or a hop-forward IPA, the style guide is your roadmap to achieving the perfect balance and complexity in your brew.
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Why is Origin Relevant?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    The origin of hops affects their flavor and aroma characteristics. For example, American hops are often known for their bold, citrusy profiles, while European hops, such as Noble hops, are prized for their delicate, earthy, and floral notes. Knowing the origin helps you choose hops that align with traditional styles or experimental brews, enabling brewers to craft beers that reflect regional authenticity or embrace new flavor profiles.
                </Typography>


                {/* Types of Hops Available on the Market */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Types of Hops Available on the Market
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Hops are available in a variety of forms, each tailored to different brewing techniques and desired outcomes. These forms vary in their processing, concentration, and application, allowing brewers to achieve specific flavor, aroma, and bitterness profiles while optimizing brewing efficiency. Understanding the types of hops and their scientific properties is essential for selecting the right product for your brewing needs.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Leaf Hops
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Also known as whole cone hops, leaf hops are the traditional form of hops, consisting of intact, dried cones. They provide a rich, full flavor and are often favored by brewers who prioritize natural brewing processes. However, leaf hops take up more space in storage and brewing equipment and may absorb more wort during the brewing process, leading to reduced yield. Despite these challenges, their lower processing level ensures that the essential oils and resins remain relatively intact, making them ideal for brewers seeking traditional methods and flavors.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Pellet Hops
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Pellet hops are made by grinding dried hop cones into a fine powder and compressing them into small pellets. This process concentrates the active components, such as alpha acids and essential oils, making pellet hops more efficient for storage and brewing. They dissolve easily in the wort, maximizing extraction during the boil. Pellet hops are widely used by commercial and craft brewers due to their consistent quality, ease of use, and reduced storage space requirements. However, some loss of volatile oils may occur during processing, which can slightly impact the hop’s aroma potential.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Cryo Hops
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Cryo hops are a cutting-edge product created through a cryogenic process that separates lupulin—the concentrated resin and essential oil gland—from the hop’s leafy material. The result is a highly potent product with double the concentration of alpha acids and essential oils compared to traditional hops. Cryo hops are ideal for brewers seeking intense aroma and flavor without introducing excess plant material, which can contribute to astringency or vegetal flavors. They are particularly effective in late hopping and dry hopping applications for hop-forward styles like IPAs.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Hop Powder
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Hop powder is essentially ground hops that have not been compressed into pellets. While less common than pellet hops, hop powder retains a high concentration of active compounds. It provides greater surface area for extraction, allowing for efficient utilization during brewing. However, its lightweight, uncompressed nature makes it more challenging to handle and store, and it may not be as widely available as other forms.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Hop Extract
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Hop extract is a liquid form of hops produced by extracting alpha acids, beta acids, and essential oils using solvents like CO2. This highly concentrated product is particularly popular in large-scale brewing due to its efficiency and consistency. Hop extract is typically used to add bitterness during the boil, as its liquid form ensures complete utilization of alpha acids. Additionally, hop extracts reduce the amount of vegetal matter in the brewing process, minimizing waste and improving filtration efficiency. However, hop extract lacks some of the nuanced aromas found in whole hops, making it less suitable for aroma-focused additions.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Hop Oil
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Hop oil is derived from the essential oils of hops and is specifically designed for post-fermentation applications. It delivers intense and focused aroma characteristics without contributing bitterness or vegetal matter. Hop oil is often used for fine-tuning aroma in beers that require precision, such as heavily dry-hopped IPAs or experimental brews. While its potency and purity are advantageous, hop oil must be used carefully to avoid overwhelming the beer’s balance.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Each type of hop product offers unique advantages and challenges. Brewers must consider factors such as desired flavor and aroma, brewing technique, and equipment limitations when choosing the appropriate hop format. By understanding the scientific properties and applications of these hop types, brewers can optimize their recipes and create exceptional beers.
                </Typography>



                {/* How Long Can You Store Hops? */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    How Long Can You Store Hops?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Hops are a delicate brewing ingredient, and their quality is highly sensitive to storage conditions. Over time, exposure to oxygen, heat, and light can significantly degrade their alpha acids, beta acids, and essential oils, leading to diminished bitterness and aromatic intensity. Proper storage techniques are essential to preserving hop quality and ensuring their effectiveness in brewing.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Key Factors Affecting Hop Longevity
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Oxygen exposure reacts with the alpha acids and essential oils in hops, causing them to oxidize. This results in a loss of bitterness and the development of a stale, cheesy aroma due to compounds like isovaleric acid. Vacuum-sealing hops is one of the most effective ways to minimize oxygen exposure.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Heat accelerates the degradation of alpha acids and essential oils. Hops stored at room temperature can lose up to 50% of their alpha acid content within six months. Freezing hops dramatically slows this process, extending their usable lifespan. Light exposure can break down the essential oils in hops and initiate chemical reactions that degrade their quality. This phenomenon, often referred to as "lightstruck" hops, can be avoided by using opaque or UV-resistant packaging.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Optimal Storage Recommendations
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Always store hops in airtight containers to prevent oxidation. Vacuum-sealed bags are highly recommended as they remove nearly all air and protect the hops from moisture and contaminants. Keep hops in a freezer at a consistent temperature, ideally below -18°C (0°F). Freezing significantly slows the degradation of alpha acids and essential oils, preserving hop quality for up to two years or more. Use opaque or foil-lined packaging to shield hops from light exposure. Clear or translucent containers should be avoided.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Clearly label each batch of hops with the variety, harvest year, and alpha acid percentage to track their freshness and ensure proper usage in recipes. Divide large quantities of hops into smaller, single-use portions before freezing. This minimizes repeated exposure to air and moisture when retrieving hops for brewing.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Expected Storage Lifespan
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    When stored properly, vacuum-sealed and frozen hops can retain most of their alpha acid content and aroma for up to two years, with minimal degradation. Hops stored in a refrigerator will degrade faster, typically within six months to a year. Hops stored at room temperature can lose significant quality within a few weeks to months, depending on packaging and environmental conditions.
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Signs of Degraded Hops
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Fresh hops are vibrant green with visible yellow lupulin glands. Brown or faded hops indicate degradation. Good-quality hops have a strong, pleasant aroma. A cheesy, musty, or faint smell suggests oxidation and spoilage. Fresh hops feel slightly sticky due to lupulin. Brittle or dry cones indicate the loss of essential oils. If hops show significant degradation, they may still be usable for bittering but should not be relied on for aromatic additions.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    By following these best practices, brewers can maximize the shelf life and quality of their hops, ensuring consistency and excellence in their beer recipes.
                </Typography>


                {/* About the Database */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    About the Database
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    The hop database, hosted at <strong>connected-brewery.com</strong>, is a comprehensive resource designed to serve both homebrewers and commercial brewers. For homebrewers, it offers free access to an extensive collection of hop varieties, allowing you to explore, compare, and experiment with different options to enhance your brewing projects.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    For commercial brewers, the database includes robust APIs and seamless interface options for integration directly into your brewing processes. These tools simplify the management of hop selection, streamline recipe development, and ensure you stay updated with the latest hop varieties and substitutes.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Whether you are discovering new hops, comparing alternatives, or seeking substitutes, the database is an indispensable tool that provides the information and flexibility needed to elevate your brewing operations. With a commitment to supporting innovation and quality, <strong>connected-brewery.com</strong> empowers brewers to make informed decisions with confidence.
                </Typography>


                {/* Disclaimer */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    Disclaimer
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Hops are an agricultural product, and as such, their chemical composition and sensory characteristics can vary due to factors such as growing conditions, harvest timing, and post-harvest handling. Environmental influences, including soil composition, weather patterns, and regional climate, play a significant role in determining the quality and profile of each crop.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    While we strive to ensure that the information provided in our database is accurate, reliable, and up-to-date, variations in hop characteristics may occur from year to year and between suppliers. Additionally, storage conditions such as temperature, exposure to light, and oxygen levels can significantly impact the quality, potency, and sensory attributes of hops over time.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                    Brewers are encouraged to evaluate hops before use to ensure they meet the desired quality and sensory standards for their specific applications. We recommend following best practices for storage and handling to preserve hop integrity and achieve consistent brewing results.
                </Typography>

            </Box>




        </Box >
    );
};

export default HopFinder;
