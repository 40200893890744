import React from 'react';
import GetStartedSidebar from '../components/GetStartedSidebar';
import LoginCallToAction from '../components/LoginCallToAction';
import GetStartedDisclaimer from '../components/GetStartedDisclaimer';
import Signup from '../components/Signup';
import { Outlet, useLocation } from 'react-router-dom';
import '../global.css';

const GetStarted = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/get-started';

  return (
    <div className="tools-layout" style={{ backgroundColor: '#FAF9F1', padding: '20px' }}>
      {/* Sidebar */}
      <GetStartedSidebar />
      <div className="tools-content" style={{ backgroundColor: '#FAF9F1' }}>
        {isLandingPage ? (
          <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
            <h1 style={{ textAlign: 'center', fontSize: '2.5rem', color: '#2b2d42' }}>Get Started with Our Tools</h1>
            <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
              Welcome to the starting point of your brewing journey! Our tools are designed to help homebrewers and
              professional brewers alike craft the perfect beer. The best part? It's <strong>completely FREE</strong> for
              private use, and it will <strong>always stay free</strong> for individuals.
            </p>
            <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
              Signing up takes just a few seconds. Once logged in, you'll gain access to recipe-building tools, advanced
              calculators, and brewing resources—all at no cost. Whether you're a novice or a seasoned brewer, there's
              something here for you.
            </p>
            <h2 style={{ textAlign: 'center', fontSize: '2rem', color: '#2b2d42', marginTop: '30px' }}>
              Join Our Community
            </h2>
            <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
              Stay updated on the latest brewing tools and features by joining our newsletter. We’re constantly working to
              improve and add new tools based on feedback from brewers like you. Don’t miss out!
            </p>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <Signup />
            </div>
            <h3 style={{ marginTop: '40px', fontSize: '1.5rem', color: '#2b2d42' }}>Getting Started</h3>
            <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
              Getting started is simple—no special equipment or subscriptions needed! Just log in, build your recipes, and
              dive into brewing. Our tools are intuitive and designed to make your brewing experience as seamless as
              possible.
            </p>
            <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
              Looking to automate your brewing process? Explore our free resources for enhancing your setup, and unlock
              additional features once logged in. Automation tools can help take your brewing to the next level, ensuring
              consistency and repeatability in every batch.
            </p>
            <h3 style={{ marginTop: '40px', fontSize: '1.5rem', color: '#2b2d42' }}>Commercial Use</h3>
            <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
              Are you a commercial brewer? While this platform is tailored for homebrewers, we also cater to professional
              needs through our sister endeavor, <strong>Connected Brewery</strong>. Head over to{' '}
              <a href="https://connected-brewery.com" target="_blank" rel="noopener noreferrer" style={{ color: '#4c9a2a', textDecoration: 'none' }}>
                Connected-Brewery.com
              </a>{' '}
              for advanced features and tools specifically designed for breweries at scale.
            </p>
            <h3 style={{ marginTop: '40px', fontSize: '1.5rem', color: '#2b2d42' }}>Your Feedback Matters</h3>
            <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
              We're committed to making brewing accessible and enjoyable for everyone. Your input helps us shape the future
              of this platform. Have feature requests or questions? Get in touch—we’d love to hear from you!
            </p>
          </div>
        ) : (
          <Outlet />
        )}
        <div style={{ margin: '20px 0' }}></div>
        <hr />
        {/* Login Call to Action */}
        <div className="login-call-to-action">
          <LoginCallToAction />
        </div>
        <hr />
        {/* Get Started Disclaimer */}
        <div className="tools-disclaimer">
          <GetStartedDisclaimer />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
