import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Link,
    Button,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Radar } from 'react-chartjs-2';
import axios from 'axios';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

// Register necessary Chart.js components
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const HopDetailPanel = ({ open, onClose, hopId }) => {
    const [hopDetails, setHopDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (hopId && open) {
            const fetchHopDetails = async () => {
                try {
                    setLoading(true);
                    const response = await axios.get(
                        `https://5ja6v4gg19.execute-api.us-east-1.amazonaws.com/prod/ingredients?type=hops&id=${hopId}`
                    );
                    setHopDetails(response.data);
                } catch (error) {
                    setHopDetails(null);
                } finally {
                    setLoading(false);
                }
            };
            fetchHopDetails();
        }
    }, [hopId, open]);

    // Chart data setup
    const chartData = hopDetails
        ? {
            labels: [
                'Citrus',
                'Tropical Fruit',
                'Stone Fruit',
                'Berry',
                'Floral',
                'Grassy',
                'Herbal',
                'Spice',
                'Resin/Pine',
            ],
            datasets: [
                {
                    label: `${hopDetails.name} Aroma Profile`,
                    data: [
                        hopDetails.chart_citrus || 0,
                        hopDetails.chart_tropical || 0,
                        hopDetails.chart_stone || 0,
                        hopDetails.chart_berry || 0,
                        hopDetails.chart_floral || 0,
                        hopDetails.chart_grassy || 0,
                        hopDetails.chart_herbal || 0,
                        hopDetails.chart_spice || 0,
                        hopDetails.chart_resin || 0,
                    ],
                    backgroundColor: 'rgba(76,154,42, 0.3)',
                    borderColor: '#4c9a2a',
                    borderWidth: 2,
                    pointBackgroundColor: '#4c9a2a',
                },
            ],
        }
        : null;

    const chartOptions = {
        scales: {
            r: {
                min: -1,
                max: 5,
                ticks: {
                    stepSize: 1,
                    display: false,
                    backdropColor: 'transparent',
                },
                grid: {
                    color: '#ddd',
                },
                angleLines: {
                    color: '#ddd',
                },
                pointLabels: {
                    font: {
                        size: 16,
                    },
                    color: '#2b2d42',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold', position: 'relative' }}>
                {hopDetails ? hopDetails.name : 'Loading...'}
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ borderBottom: '2px solid #ddd', marginTop: '10px' }} />
            </DialogTitle>


            <DialogContent>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                        <CircularProgress />
                    </Box>
                ) : hopDetails ? (
                    <>
                        <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: '20px' }}>
                            {hopDetails.characteristics || 'No characteristics available'}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                            {chartData && <Radar data={chartData} options={chartOptions} />}
                        </Box>
                        <Table sx={{ marginBottom: '30px' }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>Type</strong></TableCell>
                                    <TableCell>{hopDetails.type || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Purpose</strong></TableCell>
                                    <TableCell>{hopDetails.purpose || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Aroma</strong></TableCell>
                                    <TableCell>{hopDetails.aroma || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Origin</strong></TableCell>
                                    <TableCell>{hopDetails.origin || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell colSpan={2} >
                                        Of course our credo is that brewing is more science than art, hence here all the 
                                        <b> compounds and percentages</b> for {hopDetails.name}:
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Alpha Acid</strong></TableCell>
                                    <TableCell>{hopDetails.alpha || 'N/A'}%</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Beta Acid</strong></TableCell>
                                    <TableCell>{hopDetails.beta || 'N/A'}%</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Co-Humulone</strong></TableCell>
                                    <TableCell>{hopDetails.cohumulone || 'N/A'}%</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Total Oils</strong></TableCell>
                                    <TableCell>{hopDetails.total_oil || 'N/A'} mL/100g</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        {hopDetails.name} is often used for the following <b>beer styles</b>:
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Style Guide</strong></TableCell>
                                    <TableCell>{hopDetails.style_guide || 'No known recipes.'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} >
                                        If you currently can't get your hands on {hopDetails.name}, it's not too bad. <br />
                                        You can often use one of the following <b>substitutes</b>:
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Substitutes</strong></TableCell>
                                    <TableCell>{hopDetails.substitutes || 'No known supplier.'}</TableCell>



                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography variant="body1" sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
                            Description:
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: '20px' }}>
                            {hopDetails.description || 'No description available'}
                        </Typography>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>Known Suppliers</strong></TableCell>
                                    <TableCell>{hopDetails.suppliers || 'No known supplier.'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Typography
                            sx={{
                                textAlign: 'right',
                                fontSize: '12px',
                                color: '#555',
                                marginTop: '10px',
                            }}
                        >
                            Data by Connected Brewery | Private Use Only
                        </Typography>

                    </>
                ) : (
                    <Typography>No details available for this hop.</Typography>
                )}
            </DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                        borderColor: '#555', // Grey outline
                        color: '#555',       // Text color
                        textTransform: 'none',
                        '&:hover': {
                            borderColor: '#333', // Darker grey on hover
                            color: '#333',       // Darker text on hover
                            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light grey hover background
                        },
                    }}
                >
                    Close
                </Button>

            </Box>
        </Dialog>
    );
};

export default HopDetailPanel;
