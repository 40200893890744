import React, { useState } from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { styled } from '@mui/system';
import { useUnit } from '../../context/UnitContext'; // Use UnitContext for unit switching

const StyledButton = styled(Button)({
    fontSize: '1rem',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    color: '#2b2d42',
    border: '1px solid #2b2d42',
    borderRadius: '8px',
    textTransform: 'none',
    width: '100%',
    textAlign: 'left',
    padding: '10px',
    transition: 'background-color 0.3s ease-in-out, transform 0.2s',
    '&:hover': {
        backgroundColor: '#FABC18',
        color: '#2b2d42',
    },
});

const calculators = [
    { id: 1, name: 'Gravity Adjustments' },
    { id: 2, name: 'New Gravity After Dilution / Boil-Off' },
    { id: 3, name: 'Determining Boil-Off Time to Reach Target' },
    { id: 4, name: 'Gravity Change from Adding Fermentables' },
    { id: 5, name: 'Final Gravity After Combining Batches' },
];

const GravityAdjustment = () => {
    const { unit, switchToMetric, switchToImperial } = useUnit(); // Use UnitContext for Metric/Imperial
    const [selectedCalculator, setSelectedCalculator] = useState(1);
    const [gravityScale, setGravityScale] = useState('SG'); // SG or °P
    const [inputs, setInputs] = useState({});
    const [results, setResults] = useState([]);

    const handleCalculatorChange = (id) => {
        setSelectedCalculator(id);
        setInputs({});
        setResults([]);
    };

    const handleInputChange = (field, value) => {
        setInputs({ ...inputs, [field]: value });
    };

    const handleGravityScaleChange = (e) => {
        setGravityScale(e.target.value);
        setInputs({});
        setResults([]);
    };

    const calculateResult = () => {
        const normalizeGravity = (value) => {
            const parsedValue = parseFloat(value);
            if (isNaN(parsedValue)) return 0; // Handle invalid input gracefully

            // Normalize large values
            let normalizedGravity = parsedValue > 700 ? parsedValue / 1000 : parsedValue;

            // Apply the new logic for specific range
            if (normalizedGravity >= 1.000 && normalizedGravity <= 1.150) {
                normalizedGravity = (normalizedGravity * 1000) - 1000;
            }

            return normalizedGravity;
        };



        const results = [];
        switch (selectedCalculator) {
            case 1: { // Gravity Adjustments
                const startGravity = normalizeGravity(inputs.startGravity);
                const desiredGravity = normalizeGravity(inputs.desiredGravity);
                const startVolume = parseFloat(inputs.startVolume) || 0;

                // Target Volume Calculation

                const targetVolume = (startVolume * startGravity) / desiredGravity;
                const additiveNeeded = targetVolume - startVolume;

                results.push({ label: 'Target Volume', value: `${targetVolume.toFixed(2)} ${unit === 'Metric' ? 'L' : 'gal'}` });
                results.push({ label: 'Water to Add', value: `${additiveNeeded.toFixed(2)} ${unit === 'Metric' ? 'L' : 'gal'}` });
                break;
            }
            case 2: { // New Gravity After Dilution / Boil-Off
                const startGravity = normalizeGravity(inputs.startGravity);
                const startVolume = parseFloat(inputs.startVolume) || 0;
                const newVolume = parseFloat(inputs.newVolume) || 0;

                // Resulting Gravity Calculation
                const resultingGravity = (startGravity * startVolume) / newVolume;

                let adjustedResultingGravity = resultingGravity;

                // Additional Calculation for SG
                if (gravityScale === "SG") {
                    adjustedResultingGravity = 1 + (resultingGravity / 1000);
                }

                results.push({
                    label: 'Resulting Gravity',
                    value: `${adjustedResultingGravity.toFixed(3)} ${gravityScale}`
                });
                break;

            }
            case 3: { // Determining Boil-Off Time to Reach Target
                const startVolume = parseFloat(inputs.startVolume) || 0;
                const startGravity = normalizeGravity(inputs.startGravity);
                const targetGravity = normalizeGravity(inputs.targetGravity);
                const boilRate = parseFloat(inputs.boilRate) || 0;

                if (boilRate === 0) {
                    results.push({ label: 'Error', value: 'Boil rate cannot be 0.' });
                    break;
                }

                // Calculate target volume based on start gravity and target gravity
                const targetVolume = (startVolume * startGravity) / targetGravity;

                // Calculate the volume difference
                const volumeDifference = startVolume - targetVolume;

                if (volumeDifference <= 0) {
                    // No boil-off needed
                    results.push({ label: 'Boil-Off Needed', value: 'No Boil Off needed' });
                    results.push({ label: 'Final Volume', value: `${startVolume.toFixed(2)} ${unit === 'Metric' ? 'L' : 'gal'}` });
                } else {
                    // Calculate boil-off time and final volume
                    const boilOffTime = volumeDifference / boilRate;
                    const finalVolume = startVolume - volumeDifference;
                    const hours = Math.floor(boilOffTime); // Extract whole hours
                    const minutes = Math.round((boilOffTime - hours) * 60); // Convert fractional hours to minutes

                    results.push({ label: 'Volume to Boil Off', value: `${volumeDifference.toFixed(2)} ${unit === 'Metric' ? 'L' : 'gal'}` });

                    results.push({
                        label: 'Boil-Off Time',
                        value: `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`,
                    });
                    results.push({ label: 'Final Volume', value: `${finalVolume.toFixed(2)} ${unit === 'Metric' ? 'L' : 'gal'}` });
                }
                break;
            }

            case 4: { // Gravity Change from Adding Fermentables
                const startVolumeImperial = parseFloat(inputs.startVolume) || 0; // Volume in gallons (Imperial) or liters (Metric)
                const sugarAddedImperial = parseFloat(inputs.sugarAdded) || 0; // Sugar in pounds (Imperial) or grams (Metric)
                let inputGravity = parseFloat(inputs.startGravity); // Gravity (SG or Plato)

                if (startVolumeImperial === 0 || sugarAddedImperial === 0 || isNaN(inputGravity)) {
                    results.push({ label: 'Error', value: 'Invalid input for volume, sugar, or gravity.' });
                    break;
                }

                // Convert Imperial to Metric
                const startVolume = unit === 'Imperial' ? startVolumeImperial * 3.78541 : startVolumeImperial; // Gallons to Liters
                const sugarAdded = unit === 'Imperial' ? sugarAddedImperial * 453.592 : sugarAddedImperial; // Pounds to Grams

                // Metric Constants
                const sugarContribution = 12.14; // Gravity points per gram per liter

                // Determine if gravity scale is SG
                const isSG = gravityScale === 'SG';

                // Normalize SG input if needed (e.g., 1007 to 1.007 for SG)
                let startingGravitySG = inputGravity;
                if (isSG && startingGravitySG > 10) {
                    startingGravitySG = startingGravitySG / 1000; // Convert large SG inputs like 1007 to 1.007
                }

                // Convert Plato to SG if needed
                if (!isSG) {
                    startingGravitySG = 1 + inputGravity / (258.6 - inputGravity / 258.2); // Plato to SG
                }

                // Convert SG to Gravity Points
                const startingGravityPoints = (startingGravitySG * 1000) - 1000; // SG to points (e.g., 1.007 -> 7)

                // Calculate Gravity Points Increase (Metric formula)
                const gravityPointsIncrease = (sugarAdded * sugarContribution) / startVolume;

                // New Gravity Points
                const newGravityPoints = startingGravityPoints + gravityPointsIncrease;

                // Convert Gravity Points Back to SG
                let newGravitySG = (newGravityPoints + 1000) / 1000;

                // Convert SG back to Plato if needed
                let finalGravity = newGravitySG;
                if (!isSG) {
                    finalGravity = (newGravitySG - 1) * 258.6 / (1 - (newGravitySG - 1) / 258.2); // SG to Plato
                }

                // Push results
                results.push({ label: 'New Gravity', value: `${finalGravity.toFixed(3)} ${gravityScale}` });
                break;
            }





            case 5: { // Final Gravity After Combining Batches
                const volume1 = parseFloat(inputs.volume1) || 0;
                const gravity1 = normalizeGravity(inputs.gravity1);
                const volume2 = parseFloat(inputs.volume2) || 0;
                const gravity2 = normalizeGravity(inputs.gravity2);

                // Weighted Average Gravity Calculation
                const totalVolume = volume1 + volume2;
                let finalGravity = ((gravity1 * volume1) + (gravity2 * volume2)) / totalVolume;

                // Additional Calculation for SG
                if (gravityScale === 'SG') {
                    finalGravity = 1 + (finalGravity / 1000);
                }

                results.push({
                    label: 'Final Total Volume',
                    value: `${totalVolume.toFixed(2)} ${unit === 'Metric' ? 'L' : 'gal'}`
                });
                results.push({
                    label: 'Final Gravity',
                    value: `${finalGravity.toFixed(3)} ${gravityScale}`
                });
                break;

            }
            default:
                results.push({ label: 'Error', value: 'Invalid calculation' });
        }
        setResults(results);
    };





    const getInputFields = () => {
        const gravityLabel = gravityScale === 'SG' ? 'Gravity (SG)' : 'Gravity (°P)';
        switch (selectedCalculator) {
            case 1:
                return (
                    <>
                        <TextField
                            label={`Starting Volume (${unit === 'Metric' ? 'L' : 'gal'})`}
                            variant="outlined"
                            value={inputs.startVolume || ''}
                            onChange={(e) => handleInputChange('startVolume', e.target.value)}
                        />
                        <TextField
                            label={`Starting ${gravityLabel}`}
                            variant="outlined"
                            value={inputs.startGravity || ''}
                            onChange={(e) => handleInputChange('startGravity', e.target.value)}
                        />
                        <hr />
                        <TextField
                            label={`Desired ${gravityLabel}`}
                            variant="outlined"
                            value={inputs.desiredGravity || ''}
                            onChange={(e) => handleInputChange('desiredGravity', e.target.value)}
                        />
                    </>
                );
            case 2:
                return (
                    <>
                        <TextField
                            label={`Starting Volume (${unit === 'Metric' ? 'L' : 'gal'})`}
                            variant="outlined"
                            value={inputs.startVolume || ''}
                            onChange={(e) => handleInputChange('startVolume', e.target.value)}
                        />
                        <TextField
                            label={`Starting ${gravityLabel}`}
                            variant="outlined"
                            value={inputs.startGravity || ''}
                            onChange={(e) => handleInputChange('startGravity', e.target.value)}
                        />
                        <hr />
                        <TextField
                            label={`New Volume (${unit === 'Metric' ? 'L' : 'gal'})`}
                            variant="outlined"
                            value={inputs.newVolume || ''}
                            onChange={(e) => handleInputChange('newVolume', e.target.value)}
                        />
                    </>
                );
            case 3:
                return (
                    <>
                        <TextField
                            label={`Pre Boil Volume (${unit === 'Metric' ? 'L' : 'gal'})`}
                            variant="outlined"
                            value={inputs.startVolume || ''}
                            onChange={(e) => handleInputChange('startVolume', e.target.value)}
                        />
                        <TextField
                            label={`Starting ${gravityLabel}`}
                            variant="outlined"
                            value={inputs.startGravity || ''}
                            onChange={(e) => handleInputChange('startGravity', e.target.value)}
                        />
                        <hr />
                        <TextField
                            label={`Boil Rate (${unit === 'Metric' ? 'L' : 'gal'}/h)`}
                            variant="outlined"
                            value={inputs.boilRate || ''}
                            onChange={(e) => handleInputChange('boilRate', e.target.value)}
                        />
                        <hr />
                        <TextField
                            label={`Target ${gravityLabel}`}
                            variant="outlined"
                            value={inputs.targetGravity || ''}
                            onChange={(e) => handleInputChange('targetGravity', e.target.value)}
                        />
                    </>
                );
            case 4:
                return (
                    <>
                        <TextField
                            label={`Starting ${gravityLabel}`}
                            variant="outlined"
                            value={inputs.startGravity || ''}
                            onChange={(e) => handleInputChange('startGravity', e.target.value)}
                        />
                        <TextField
                            label={`Starting Volume (${unit === 'Metric' ? 'L' : 'gal'})`}
                            variant="outlined"
                            value={inputs.startVolume || ''}
                            onChange={(e) => handleInputChange('startVolume', e.target.value)}
                        />
                        <hr />
                        <TextField
                            label={`Sugar Added (${unit === 'Metric' ? 'g' : 'lbs'})`}
                            variant="outlined"
                            value={inputs.sugarAdded || ''}
                            onChange={(e) => handleInputChange('sugarAdded', e.target.value)}
                        />
                    </>
                );
            case 5:
                return (
                    <>
                        <TextField
                            label={`Volume Batch 1 (${unit === 'Metric' ? 'L' : 'gal'})`}
                            variant="outlined"
                            value={inputs.volume1 || ''}
                            onChange={(e) => handleInputChange('volume1', e.target.value)}
                        />
                        <TextField
                            label={`Batch 1 ${gravityLabel}`}
                            variant="outlined"
                            value={inputs.gravity1 || ''}
                            onChange={(e) => handleInputChange('gravity1', e.target.value)}
                        />
                        <hr />
                        <TextField
                            label={`Volume Batch 2 (${unit === 'Metric' ? 'L' : 'gal'})`}
                            variant="outlined"
                            value={inputs.volume2 || ''}
                            onChange={(e) => handleInputChange('volume2', e.target.value)}
                        />
                        <TextField
                            label={`Batch 2 ${gravityLabel}`}
                            variant="outlined"
                            value={inputs.gravity2 || ''}
                            onChange={(e) => handleInputChange('gravity2', e.target.value)}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ padding: '20px', minHeight: '100vh', maxWidth: '1200px', margin: 'auto', backgroundColor: '#FAF9F1' }}>
            {/* Dropdown and Headline */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #ccc',
                    paddingBottom: '10px',
                }}
            >
                <Typography variant="h3" sx={{ textAlign: 'left' }}>
                    Gravity Adjustment Calculator
                </Typography>
                <Select
                    value={unit}
                    onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px' }}
                >
                    <MenuItem value="Metric">Metric</MenuItem>
                    <MenuItem value="Imperial">Imperial</MenuItem>
                </Select>
            </Box>
            <hr />

            {/* Main Layout */}
            <Typography sx={{ marginTop: '20px', marginBottom: '20px', textAlign: 'left' }}>
                The Gravity Adjustment Calculator provides precise and versatile tools to calculate various adjustments
                to project gravity changes, ensuring accuracy in brewing processes. Designed to help brewers optimize recipes with ease.
            </Typography>
            <Box sx={{ display: 'flex', flex: '1', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, mdFlexDirection: 'row', gap: '20px', marginBottom: '20px', marginTop: '20px' }}>
                {/* Calculator Options */}
                <Box sx={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    {calculators.map((calc) => (
                        <StyledButton
                            key={calc.id}
                            onClick={() => handleCalculatorChange(calc.id)}
                            sx={{
                                borderColor: selectedCalculator === calc.id ? '#FABC18' : '#2b2d42',
                                color: selectedCalculator === calc.id ? '#FABC18' : '#2b2d42',
                            }}
                        >
                            {calc.name}
                        </StyledButton>
                    ))}
                    <Select
                        value={gravityScale}
                        onChange={handleGravityScaleChange}
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: '10px', width: '100%' }}
                    >
                        <MenuItem value="SG">Specific Gravity (SG)</MenuItem>
                        <MenuItem value="°P">Plato (°P)</MenuItem>
                    </Select>
                </Box>

                {/* Input Fields */}
                <Box sx={{ flex: '2', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Typography variant="h5">
                        {calculators.find((calc) => calc.id === selectedCalculator)?.name}
                        <hr />
                    </Typography>
                    {/* <Box sx={{ marginBottom: '12px' }} /> */}
                    {getInputFields()}
                </Box>
            </Box>

            {/* Calculate Button */}
            <Box sx={{ marginBottom: '20px' }}>
                <Button
                    variant="contained"
                    onClick={calculateResult}
                    fullWidth
                    sx={{
                        backgroundColor: '#2b2d42',
                        color: '#f4f1de',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        '&:hover': { backgroundColor: '#FABC18', color: '#2b2d42' },
                    }}
                >
                    Calculate
                </Button>
            </Box>

            {/* Results Table */}
            <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Result</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {calculators.find((calc) => calc.id === selectedCalculator)?.name ? (
                            results.map((result, index) => (
                                <TableRow key={index}>
                                    <TableCell>{result.label}</TableCell>
                                    <TableCell>{result.value}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                                    Enter Inputs to Begin Calculations
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px', paddingTop: "24px", color: '#2b2d42', }}>
                About the Calculators </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> The calculators provided here are designed to offer brewers accurate and practical tools to estimate brewing variables such as gravity, volume, and boil-off time. These calculations are based on standard brewing formulas, but results can vary depending on the details of your brewing process, including ingredient characteristics, equipment efficiency, and environmental factors. While the calculators provide excellent indications, slight variations in the final product are normal. </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> In addition to these gravity adjustment tools, we encourage you to explore other calculators for beer color, hop bitterness (IBU), water adjustments, and carbonation levels. Together, they form a comprehensive toolkit for creating and refining beer recipes, ensuring precision and consistency from brew to brew. </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px', color: '#2b2d42', }}>
                Gravity Adjustment </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> Gravity adjustment is an essential step in brewing, used to modify the specific gravity (SG) or Plato of the wort to meet a desired target. The formula for calculating the target volume is: </Typography> <Typography variant="body2" sx={{ marginBottom: '16px', paddingLeft: '64px' }}> <strong>Target Volume = (Start Volume × Start Gravity) / Desired Gravity</strong> </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> This formula ensures that your beer achieves the desired alcohol content and flavor profile. Gravity adjustment is commonly used when additional water needs to be added to dilute the wort or when concentrated wort is blended to increase gravity. For example, if your starting wort has a gravity of 1.050, but you aim for a gravity of 1.040, the formula helps determine the additional volume required. </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> Gravity adjustments are particularly useful when compensating for boil-off losses, ensuring precise consistency across batches. This calculator helps you align your wort's gravity with your recipe's intended specifications, guaranteeing both efficiency and quality in your brewing process. </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px', color: '#2b2d42', }}>
                New Gravity After Dilution </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> Dilution is a common practice in brewing, used to adjust the final gravity by adding water to the wort. The formula for calculating the new gravity after dilution is: </Typography> <Typography variant="body2" sx={{ marginBottom: '16px', paddingLeft: '64px' }}> <strong>Resulting Gravity = (Start Gravity × Start Volume) / New Volume</strong> </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> This tool is especially useful when a brewer has a high-gravity wort but wishes to dilute it to meet a specific target gravity for a beer style. For example, if you have 10 liters of wort with a gravity of 1.070 and add 5 liters of water, the resulting gravity can be easily calculated to ensure the dilution aligns with your recipe's requirements. </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> Adjusting the gravity through dilution is essential for maintaining consistency and achieving the desired alcohol content. This calculator eliminates guesswork, providing precise results to help you create the perfect batch. </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px', color: '#2b2d42', }}>
                Determining Boil-Off Time </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> Boil-off time is a critical parameter in brewing, helping brewers concentrate wort to achieve the desired gravity. The formula used is: </Typography> <Typography variant="body2" sx={{ marginBottom: '16px', paddingLeft: '64px' }}> <strong>Boil-Off Time = Volume Difference / Boil Rate</strong> </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> This calculation ensures precise control over the concentration process, especially when targeting specific gravity or compensating for inconsistencies in the boil rate. If your wort requires concentration to achieve a gravity of 1.060 from 1.050, the boil-off calculator determines the time needed based on the volume difference and your boil-off rate. </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> Understanding boil-off time helps brewers optimize energy use and prevents over-concentration, ensuring both efficiency and quality. This tool simplifies the process, offering precise results for any brewing scale. </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px', color: '#2b2d42', }}>
                Gravity Change from Adding Fermentables </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> Adding fermentable sugars, such as sugar or malt extract, increases the wort's gravity. The formula for calculating this increase is: </Typography> <Typography variant="body2" sx={{ marginBottom: '16px', paddingLeft: '64px' }}> <strong>Gravity Increase = (Sugar Added × Points per Unit) / Volume</strong> </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> For example, if you add 500 grams of sugar to 20 liters of wort, the calculator determines the gravity increase, which can then be added to the original gravity. This adjustment is vital when aiming to create high-gravity beers, such as barleywines or imperial stouts. </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> This tool allows brewers to experiment with fermentable additions while ensuring that the beer's final gravity meets expectations. By accurately predicting gravity changes, this calculator supports creativity and precision in recipe design. </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px', color: '#2b2d42', }}>
                Final Gravity After Combining Batches </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> When combining two batches of wort, the final gravity is calculated as a weighted average of the individual gravities. The formula is: </Typography> <Typography variant="body2" sx={{ marginBottom: '16px', paddingLeft: '64px' }}> <strong>Final Gravity = (Gravity1 × Volume1 + Gravity2 × Volume2) / Total Volume</strong> </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> This calculator is particularly useful when blending batches to achieve a desired gravity. For instance, if one batch has a gravity of 1.060 and another has a gravity of 1.040, the calculator determines the final gravity based on the volumes of each batch. </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> By understanding the combined gravity, brewers can ensure consistency across different brewing sessions, maintaining quality and achieving the intended beer profile. </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px', color: '#2b2d42', }}>
                Summary </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> Brewing is a combination of art and science, and these calculators bridge the gap by providing precise, reliable tools for essential brewing adjustments. From gravity corrections and dilutions to boil-off time and batch blending, each tool serves a vital role in creating high-quality beer. </Typography> <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '12px', textAlign: 'justify' }}> While these calculators offer excellent estimations, variations in brewing processes may lead to minor differences. By using these tools alongside brewing experience, brewers can achieve consistency, quality, and creativity in every batch. Cheers to perfecting your craft! </Typography>

        </Box >
    );
};

export default GravityAdjustment;
