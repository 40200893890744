import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Modal,
    TextField,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Radio,
} from '@mui/material';
import axios from 'axios';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import GrainDetailPanel from './GrainDetailPanel';
import YeastDetailPanel from './YeastDetailPanel';
import HopDetailPanel from './HopDetailPanel';

const CompareIngredientsPanel = ({
    open,
    onClose,
    selectionType,
    handleAddIngredient,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [totalResults, setTotalResults] = useState(0);
    const [results, setResults] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Pagination state
    const [isDetailPanelOpen, setIsDetailPanelOpen] = useState(false);
    const [detailRowId, setDetailRowId] = useState(null);

    const rowsPerPage = 5;
    const typeTitleMap = {
        'fermentables_malted_grains': 'Malts & Grains',
        'hops': 'Hops',
        'yeast': 'Yeasts',
    };
    const typeTitle = typeTitleMap[selectionType] || 'Unknown Type';


    // Fetch ingredients from the API
    const fetchIngredients = async () => {
        setLoading(true);
        setError(null);
        try {
            const query = searchQuery || '';
            const url = query
                ? `https://5ja6v4gg19.execute-api.us-east-1.amazonaws.com/prod/ingredients?type=${selectionType}&search=${query}&size=${rowsPerPage}&page=${currentPage}`
                : `https://5ja6v4gg19.execute-api.us-east-1.amazonaws.com/prod/ingredients?type=${selectionType}&size=${rowsPerPage}&page=${currentPage}`;
            const response = await axios.get(url);

            const { total, data } = response.data;
            setResults(data || []);
            setTotalResults(total || 0);
        } catch (error) {
            console.error('Error fetching ingredients:', error);
            setError('Failed to load data. Please try again.');
            setResults([]);
            setTotalResults(0);
        } finally {
            setLoading(false);
        }
    };

    // Fetch data when the panel opens or pagination changes
    useEffect(() => {
        if (open) {
            fetchIngredients();
        }
    }, [open, selectionType, currentPage]);

    // Handle search input
    const handleSearch = async () => {
        setCurrentPage(1); // Reset to the first page on new search
        await fetchIngredients();
    };

    // Open detail panel for the selected row
    const openDetailPanel = (rowId) => {
        console.log(rowId);

        switch (selectionType) {
            case 'fermentables_malted_grains':
                return <GrainDetailPanel
                    open={true}
                    onClose={closeDetailPanel}
                    grainId={rowId} />;
            case 'yeast':
                return <YeastDetailPanel open={true} onClose={closeDetailPanel} yeastId={rowId} />;
            case 'hops':
                return <HopDetailPanel open={true} onClose={closeDetailPanel} hopId={rowId} />;
            default:
                console.error('Invalid selection type');
                return null;
        }
    };


    // Close the detail panel
    const closeDetailPanel = () => {
        setDetailRowId(null);
        setIsDetailPanelOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="add-ingredient-modal"
            aria-describedby="add-ingredient-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                    p: 4,
                    '@media (max-width: 880px)': {
                        width: '90%', // Adjust width for smaller screens
                    },
                }}
            >
                {/* Modal Header */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '16px',
                    }}
                >
                    <Typography id="add-ingredient-modal" variant="h6">
                        {typeTitle.charAt(0).toUpperCase() + typeTitle.slice(1)} Finder
                    </Typography>
                    <IconButton onClick={onClose} sx={{ color: '#2b2d42' }}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Search Bar */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '16px',
                        position: 'relative', // To position the clear button inside the TextField
                    }}
                >
                    <TextField
                        fullWidth
                        label={`Search ${typeTitle.charAt(0).toUpperCase() + typeTitle.slice(1)}`}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{
                            marginRight: '8px',
                            '& .MuiInputBase-root': {
                                paddingRight: '40px', // Add padding for the clear button
                            },
                        }}
                    />
                    {searchQuery && (
                        <IconButton
                            onClick={() => {
                                setSearchQuery('');
                                handleSearch();
                            }} // Clears the input
                            sx={{
                                position: 'absolute',
                                right: '48px', // Adjust position to fit inside the TextField
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: '#2b2d42',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                    <IconButton onClick={handleSearch} sx={{ color: '#2b2d42' }}>
                        <Typography
                            variant="body2"
                            sx={{
                                marginRight: '4px', // Space between the label and icon
                                color: '#2b2d42',
                            }}
                        >
                            Search
                        </Typography>
                        <SearchIcon />
                    </IconButton>
                </Box>

                <Typography
                    sx={{
                        marginTop: '20px',
                        textAlign: 'left',
                        fontSize: '12px',
                        color: '#555',
                    }}
                >
                    {totalResults} different {typeTitle.charAt(0).toUpperCase() + typeTitle.slice(1)} found
                </Typography>

                {/* Table with Search Results */}
                {loading ? (
                    <Typography>Loading {typeTitle} data...</Typography>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#5a5a5a' }}>
                                    <TableCell sx={{ color: '#f4f1de' }}>Select</TableCell>
                                    <TableCell sx={{ color: '#f4f1de' }}>Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results.length ? (
                                    results.map((row) => (
                                        <TableRow
                                            key={row.uid}
                                            sx={{
                                                cursor: 'pointer',
                                                backgroundColor: '#ffffff',
                                                '&:hover': { bgcolor: '#f5f5f5' },
                                            }}
                                        >
                                            <TableCell
                                                onClick={() => setSelectedRow(row.uid)}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <Radio
                                                    checked={selectedRow === row.uid}
                                                    onChange={() => setSelectedRow(row.uid)}
                                                    value={row.uid}
                                                    name="ingredient-selector"
                                                    sx={{
                                                        color: '#2B2D42', // Unselected color outline
                                                        '&.Mui-checked': {
                                                            color: '#FACB18', // Selected button color and outline
                                                        },
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                // onClick={() => openDetailPanel(row.uid)}
                                                sx={{ textAlign: 'left' }}
                                            >
                                                {row.name}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={2} align="center">
                                            No matching {typeTitle} entries found.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Typography
                    sx={{
                        textAlign: 'right',
                        fontSize: '12px',
                        color: '#555',
                        marginTop: '10px',
                    }}
                >
                    Data by Connected Brewery | Private Use Only
                </Typography>

                {/* Pagination */}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                    <Button
                        variant="text"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        sx={{
                            marginRight: '8px', color: '#2b2d18', '&:hover': {
                                backgroundColor: '#5a5a5a', // Hover background color
                                color: '#fff'
                            }
                        }}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="text"
                        disabled={results.length < rowsPerPage}
                        onClick={() => setCurrentPage((prev) => prev + 1)}
                        sx={{
                            marginRight: '8px', color: '#2b2d18', '&:hover': {
                                backgroundColor: '#5a5a5a', // Hover background color
                                color: '#fff'
                            }
                        }}
                    >
                        Next
                    </Button>
                </Box>

                {/* Action Buttons */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '16px',
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        sx={{
                            color: '#2b2d42',
                            borderColor: '#2b2d42',
                            '&:hover': {
                                backgroundColor: '#f4f4f4',
                                borderColor: '#2b2d42',
                            },
                        }}
                    >
                        Cancel & Close
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleAddIngredient(selectedRow);
                            console.log("from Add Button; adding ID:");
                            console.log(selectedRow);
                            onClose();
                        }}
                        disabled={!selectedRow}
                        sx={{
                            backgroundColor: '#2b2d42',
                            color: '#f4f1de',
                            '&:hover': {
                                backgroundColor: '#FACB18',
                                color: '#2b2d42',
                            },
                        }}
                    >
                        Add {typeTitle.charAt(0).toUpperCase() + typeTitle.slice(1)}
                    </Button>
                </Box>

                {/* Detail Panels */}
                {selectionType === 'compareMalt' && (
                    <GrainDetailPanel
                        open={isDetailPanelOpen}
                        onClose={closeDetailPanel}
                        grainId={detailRowId}
                    />
                )}
                {selectionType === 'compareYeasts' && (
                    <YeastDetailPanel
                        open={isDetailPanelOpen}
                        onClose={closeDetailPanel}
                        yeastId={detailRowId}
                    />
                )}
                {selectionType === 'compareHops' && (
                    <HopDetailPanel
                        open={isDetailPanelOpen}
                        onClose={closeDetailPanel}
                        hopId={detailRowId}
                    />
                )}
            </Box>
        </Modal >
    );
};

export default CompareIngredientsPanel;
