import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem, TextField, Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useUnit } from '../../context/UnitContext';

const BeerXMLConverter = () => {
  const { unit, switchToImperial, switchToMetric } = useUnit();
  const [selectedUnit, setSelectedUnit] = useState('default'); // Example state for left-column selections
  const [inputValues, setInputValues] = useState(''); // Example state for right-column input
  const [results, setResults] = useState([]); // Example state for results

  const handleCalculate = () => {
    // Perform calculation logic here and set the results
    setResults([
      { result: 'Example Result 1', value: 'Value 1' },
      { result: 'Example Result 2', value: 'Value 2' },
    ]);
  };

  return (
    <Box sx={{ padding: '20px', minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
      {/* Top Right Metric/Imperial Selector */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <Select
          value={unit}
          onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
          variant="outlined"
          size="small"
          sx={{ width: '150px' }}
        >
          <MenuItem value="Metric">Metric</MenuItem>
          <MenuItem value="Imperial">Imperial</MenuItem>
        </Select>
      </Box>

      {/* Header Section */}
      <Typography variant="h1" sx={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '10px' }}>
        BEER XML CONVERTER
      </Typography>
      <Divider sx={{ marginBottom: '20px' }} />

      {/* Intro Statement */}
      <Typography variant="body1" sx={{ marginBottom: '40px' }}>
        New calculator coming soon. Stay tuned!
      </Typography>

      {/* Main Layout */}
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '40px' }}>
        {/* Left Column */}
        <Box
          sx={{
            flex: '1 1 30%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '10px',
            backgroundColor: '#f7f7f7',
            borderRadius: '8px',
          }}
        >
          <Typography variant="h6">Select Units Here</Typography>
          <Select
            value={selectedUnit}
            onChange={(e) => setSelectedUnit(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
          >
            <MenuItem value="default">Default</MenuItem>
            <MenuItem value="option1">Option 1</MenuItem>
            <MenuItem value="option2">Option 2</MenuItem>
          </Select>
        </Box>

        {/* Right Column */}
        <Box
          sx={{
            flex: '1 1 70%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Typography variant="h6">Enter Values Here</Typography>
          <TextField
            label="Input Value"
            value={inputValues}
            onChange={(e) => setInputValues(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <button onClick={handleCalculate}>Calculate</button>
        </Box>
      </Box>

      {/* Results Section */}
      <Box sx={{ marginBottom: '40px', padding: '10px', backgroundColor: '#f7f7f7', borderRadius: '8px' }}>
        <Typography variant="h5" sx={{ marginBottom: '20px' }}>
          Results
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Result</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.length > 0 ? (
              results.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{result.result}</TableCell>
                  <TableCell>{result.value}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2}>No results yet.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>

      {/* Understanding the Calculator Section */}
      <Box sx={{ padding: '10px', backgroundColor: '#fff', borderRadius: '8px' }}>
        <Typography variant="h5" sx={{ marginBottom: '20px' }}>
          Understanding the Calculator
        </Typography>
        <Typography variant="body1">
          This section provides details about the calculations, formulas, and use cases of this calculator. 
          Customize this content as needed for your specific calculator.
        </Typography>
      </Box>
    </Box>
  );
};

export default BeerXMLConverter;
