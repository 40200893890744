import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    TablePagination,
    Slider,
    InputLabel,
    FormControl,
} from '@mui/material';
import axios from 'axios';
import { useCallback } from "react";
import debounce from "lodash.debounce";

import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import YeastDetailPanel from './YeastDetailPanel';

import { styled } from '@mui/system';
import { useUnit } from '../../context/UnitContext';
import { Pause, QueryStatsRounded } from '@mui/icons-material';

const StyledButton = styled(Button)({
    padding: '10px 20px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    backgroundColor: '#2b2d42',
    color: '#f4f1de',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: 'none',
    transition: 'background-color 0.3s ease-in-out, transform 0.2s',
    '&:hover': {
        backgroundColor: '#FABC18',
        color: '#2b2d42',
        transform: 'scale(1.15)',
    },
});

const StyledSelect = styled(Select)({
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#fabc18',
        },
    },
});

const AdvancedButton = styled(Button)({
    color: '#2b2d42',
    textTransform: 'none',
});

const SliderWrapper = styled(Box)({
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
});

const YeastFinder = () => {
    const { unit, switchToImperial, switchToMetric } = useUnit();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [filters, setFilters] = useState({
        yeast_type: [],
        flocculation: [],
        styleGuide: [],
        alcohol_tolerance: [],
        attenuation: [0, 100],
        temperatureMin: [32, 200],
        temperatureMax: [32, 200],
    });

    const [totalResults, setTotalResults] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedYeast, setSelectedYeast] = useState(null);
    const [showAdvanced, setShowAdvanced] = useState(false);

    // **Build query string for API request**
    const buildQueryString = () => {
        const query = new URLSearchParams();

        if (searchInput) query.append('search', searchInput);

        //Dropdown
        if (filters.yeast_type.length) query.append('yeast_form', filters.yeast_type.join(','));
        if (filters.flocculation.length) query.append('flocculation', filters.flocculation.join(','));
        if (filters.styleGuide.length) query.append('beer_style_yeast', filters.styleGuide.join(','));
        if (filters.alcohol_tolerance.length) query.append('alc_tolerance', filters.alcohol_tolerance.join(','));

        // Sliders
        if (filters.attenuation[0] > 0) query.append('attenuation_min', filters.attenuation[0]);
        if (filters.attenuation[1] < 100) query.append('attenuation_max', filters.attenuation[1]);
        if (filters.temperatureMin[0] > 32) query.append('min_temperature_min', filters.temperatureMin[0]);
        if (filters.temperatureMin[1] < 200) query.append('min_temperature_max', filters.temperatureMin[1]);
        if (filters.temperatureMax[0] > 32) query.append('max_temperature_min', filters.temperatureMax[0]);
        if (filters.temperatureMax[1] < 200) query.append('max_temperature_max', filters.temperatureMax[1]);

        query.append('page', page + 1);
        query.append('size', rowsPerPage);

        return query.toString();
    };


    // **Apply filters and fetch data**
    const applyFilters = async () => {
        try {
            setLoading(true);
            const queryString = buildQueryString();
            const response = await axios.get(
                `https://5ja6v4gg19.execute-api.us-east-1.amazonaws.com/prod/ingredients?type=yeast&${queryString}`
            );
            const { total, data } = response.data;
            setFilteredData(data);
            setTotalResults(total);
            setLoading(false);
        } catch (error) {
            setFilteredData([]);
            setTotalResults(0);
            setLoading(false);
        }
    };


    // **Handle search button click**
    const handleSearchClick = () => {
        setPage(0); // Reset to first page
        applyFilters(); // Fetch data based on current filters and search input
    };

    // **Clear filters and reset**
    const clearFilters = async () => {
        const defaultFilters = {
            yeast_type: [], //
            flocculation: [], // 
            styleGuide: [], //
            alcohol_tolerance: [], // 
            attenuation: [0, 100], // 
            temperatureMin: [32, 200], //
            temperatureMax: [32, 200],
        };

        setSearchInput(''); // Clear search input
        setPage(0); // Reset pagination
        setFilters(defaultFilters); // Update filters in state

        // Pass default filters directly to applyFilters
        await applyFilters({
            search: '',
            ...defaultFilters,
        });
    };



    // **Pagination handlers**
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        applyFilters(); // Ensure this is correctly updating the filtered data
    };

    const handleChangeRowsPerPage = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setRowsPerPage(newSize);
        setPage(0); // Reset to first page
        applyFilters(); // Ensure new size is applied
    };

    // **Fetch initial data on mount**
    useEffect(() => {
        applyFilters();
    }, []); // Initial data load only




    // Update the search input value
    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };



    const handleFilterChange = (key, value) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters, [key]: value };
            // applyFilters(); // Trigger API call with updated filters
            return updatedFilters;
        });
    };



    const handleRowClick = (yeast) => {
        setSelectedYeast(yeast.uid); // Pass only the hop ID
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedYeast(null);
    };




    return (
        <Box sx={{ padding: '20px', backgroundColor: '#FAF9F1', minHeight: '100vh' }}>
            {/* Header Section */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #ccc',
                    paddingBottom: '10px',
                }}
            >
                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                    Yeast Finder
                </Typography>
                <StyledSelect
                    value={unit}
                    onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px' }}
                >
                    <MenuItem value="Metric">Metric</MenuItem>
                    <MenuItem value="Imperial">Imperial</MenuItem>
                </StyledSelect>
            </Box>

            {/* Introduction */}
            <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '16px', marginTop: '20px' }}>
            Discover a curated selection of yeasts, carefully chosen to provide detailed insights into their fermentation 
             characteristics, attenuation, and flocculation properties. These yeasts play a vital role in defining the aroma, 
             flavor, and overall profile of your beer. Each entry is clickable, offering additional details about its form,
             typical beer styles, and optimal temperature range. This list is an invaluable resource for brewers looking to
              perfect their fermentation or experiment with new beer styles.

            </Typography>


            {/* Search Bar */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px', gap: '10px' }}>
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <SearchIcon
                        sx={{
                            position: 'absolute',
                            left: '10px',
                            color: '#555',
                        }}
                    />
                    <TextField
                        value={searchInput}
                        onChange={handleInputChange}
                        placeholder="Search Yeast"
                        variant="outlined"
                        fullWidth
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                paddingLeft: '40px', // Add padding to make space for the icon
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FABC18', // Change border color when focused
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#555', // Change text color inside the input
                            },
                        }}
                    />
                </Box>
                <AdvancedButton
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    sx={{ alignSelf: 'flex-end', textTransform: 'none', color: '#2b2d42' }}
                >
                    {showAdvanced ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                </AdvancedButton>

            </Box>



            {/* Advanced Filters */}
            {showAdvanced && (
                <Box sx={{ marginTop: '20px', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                    Our advanced filters enable you to refine your search results with precision. The dropdown
                     menus support selecting multiple options. Keep in mind that selecting multiple options
                      (e.g., Dry, Liquid, High Flocculation) will return yeasts associated with either of these
                       characteristics. This behavior applies to all dropdowns, allowing you to explore a wide
                        range of yeasts that fit your fermentation and brewing needs.

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Yeast Form (e.g. Liquid, Dry)</InputLabel>
                            <StyledSelect
                                value={filters.yeast_type}
                                multiple
                                onChange={(e) => handleFilterChange('yeast_type', e.target.value)}
                            >
                                {['Dry', 'liquid'].map((yeast_type) => (
                                    <MenuItem key={yeast_type} value={yeast_type}>
                                        {yeast_type}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>


                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Flocculation</InputLabel>
                            <StyledSelect
                                value={filters.flocculation}
                                multiple
                                onChange={(e) => handleFilterChange('flocculation', e.target.value)}
                            >
                                {['Low', 'Medium', 'High'
                                ].map((flocculation) => (
                                    <MenuItem key={flocculation} value={flocculation}>
                                        {flocculation}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>

                    </Box>

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Beer Styles (e.g. APA, Lager)</InputLabel>
                            <StyledSelect
                                value={filters.styleGuide}
                                multiple
                                onChange={(e) => handleFilterChange('styleGuide', e.target.value)}
                            >
                                {[
                                    'APA',
                                    'Blonde Ale',
                                    'Brown Ale',
                                    'Cream Ale',
                                    'Double IPA',
                                    'English Bitter',
                                    'English IPA',
                                    'English Pale Ale',
                                    'Golden Ale',
                                    'IPA',
                                    'Kolsch',
                                    'Lager',
                                    'NEIPA',
                                    'Pils',
                                    'Stout',
                                    'Wheat Beer',

                                ].map((style) => (
                                    <MenuItem key={style} value={style}>
                                        {style}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>



                        <FormControl
                            fullWidth
                            sx={{
                                '& .MuiInputLabel-root': {
                                    backgroundColor: '#FAF9F1',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    color: '#555', // Default gray color for the label
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#555', // Keep gray color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FABC18', // Change border color when dropdown is selected
                                    },
                                },
                            }}
                        >
                            <InputLabel>Alcohol Tolerance</InputLabel>
                            <StyledSelect
                                value={filters.alcohol_tolerance}
                                multiple
                                onChange={(e) => handleFilterChange('alcohol_tolerance', e.target.value)}
                            >
                                {['Low', 'Medium', 'High'
                                ].map((alcohol_tolerance) => (
                                    <MenuItem key={alcohol_tolerance} value={alcohol_tolerance}>
                                        {alcohol_tolerance}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>



                    </Box>
                    The sliders can be adjusted as needed to refine your search. The selected range will include
                     all values between the minimum and maximum you set. For example, searching for yeasts with a
                      temperature range of 60-75°F will return yeasts that perform optimally at temperatures of at
                       least 60°F and at most 75°F, including both 60°F and 75°F. Similarly, attenuation and alcohol
                        tolerance sliders allow you to explore yeasts with specific fermentation characteristics that
                         match your brewing requirements.

                    {[
                        { key: 'attenuation', label: 'Attenuation', min: 0, max: 100 },
                        { key: 'temperatureMin', label: 'Temperature Min (°F)', min: 32, max: 200 },
                        { key: 'temperatureMax', label: 'Temperature Max (°F)', min: 32, max: 200 },
                    ].map((slider) => (
                        <SliderWrapper key={slider.key}>
                            {/* Display label with selected range */}
                            <Typography>
                                {slider.label} | {filters[slider.key][0]}{slider.key !== 'totalOils' ? '%' : ''} -{' '}
                                {filters[slider.key][1]}{slider.key !== 'totalOils' ? '%' : ''}
                            </Typography>
                            <Slider
                                value={filters[slider.key]}
                                onChange={(e, newValue) => handleFilterChange(slider.key, newValue)}
                                valueLabelDisplay="auto"
                                min={slider.min}
                                max={slider.max}
                                sx={{
                                    '& .MuiSlider-thumb': {
                                        bgcolor: '#FABC18', // Button color
                                        border: '2px solid #FABC18555', // Border color

                                        '&:hover, &.Mui-focusVisible': {
                                            boxShadow: '0px 0px 0px 8px rgba(250, 188, 24, 0.16)',
                                        },
                                    },
                                    '& .MuiSlider-track': {
                                        bgcolor: '#FABC18', // Selected area color
                                        border: '2px solid #FABC18', // Border color

                                    },
                                    '& .MuiSlider-rail': {
                                        bgcolor: '#2b2d42', // Background rail color
                                    },
                                }}
                            />
                        </SliderWrapper>












                    ))
                    }


                    Click the "Search Yeast" button to update the search after refining the filters.

                </Box>


            )
            }
            {/* Clear and Search Buttons */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '20px',
                    borderTop: '1px solid #ccc',
                    paddingTop: '10px',
                }}
            >
                <Button
                    onClick={clearFilters}
                    variant="outlined"
                    sx={{ color: '#2b2d42', borderColor: '#2b2d42' }}
                    startIcon={<DeleteIcon />}
                >
                    Clear Filters
                </Button>
                <Button
                    onClick={applyFilters}
                    variant="contained"
                    sx={{ bgcolor: '#FABC18', color: '#2b2d42' }}
                    endIcon={<SearchIcon />}
                >
                    Search Yeast
                </Button>
            </Box>

            {/* Table Section */}
            <Typography
                sx={{
                    marginTop: '20px',
                    textAlign: 'left',
                    fontSize: '12px',
                    color: '#555'
                }}
            >
                {totalResults} different yeasts found
            </Typography>


            {loading ? (
                <Typography>Loading yeast data...</Typography>
            ) : (
                <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#5a5a5a' }}>
                                <TableCell sx={{ color: '#f4f1de' }}>Name</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Form</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Flocculation</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Attenuation (%)</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Alcohol Tolerance</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Temperature Min (°C)</TableCell>
                                <TableCell sx={{ color: '#f4f1de' }}>Temperature Max (°C)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.length ? (
                                filteredData.map((row) => (
                                    <TableRow
                                        key={row.uid}
                                        onClick={() => handleRowClick(row)}
                                        sx={{ cursor: "pointer", "&:hover": { bgcolor: "#f5f5f5" } }}
                                    >
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.form}</TableCell>
                                        <TableCell>{row.flocculation}</TableCell>
                                        <TableCell>{row.attenuation}%</TableCell>
                                        <TableCell>{row.alc_tolerance}</TableCell>
                                        <TableCell>
                                            {row.min_temp} F
                                        </TableCell>
                                        <TableCell>
                                            {row.max_temp} F
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No matching grain entries found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
            )}

            {/* Use the GrainDetailPanel*/}
            <YeastDetailPanel
                open={open}
                onClose={handleClose}
                yeastId={selectedYeast} // Pass the selectedHop as hopId
            />
            <TablePagination
                component="div"
                count={totalResults} // Total number of records
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25]} // Optional customization
            />



            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <StyledButton
                    onClick={() => {
                        window.location.href = "mailto:contact@connected-brewery.com?subject=Submit%20New%20Yeast%20Information";
                    }}
                >
                    Submit New Yeast Information
                </StyledButton>
            </Box>

            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    How to Use and Filter Options
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px' }}>
    Use the search bar and advanced filters to explore a wide range of yeasts. Filter by form, attenuation, flocculation, alcohol tolerance, and temperature range to find yeasts that match your brewing needs. The database is designed to help you compare multiple yeast strains side-by-side, making it easier to select the best options for your beer style.
</Typography>

<Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    What Is Yeast and Why Is It Important?
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Yeast is a microorganism that plays a critical role in beer brewing by converting sugars into alcohol and carbon dioxide during fermentation. Yeasts also contribute to the flavor and aroma profile of the beer, with specific strains adding unique characteristics such as fruity, spicy, or clean notes. Understanding yeast is essential for achieving consistent and high-quality results in brewing.
</Typography>
<Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    The Fermentation Process
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Fermentation occurs when yeast metabolizes sugars in the wort, producing alcohol, carbon dioxide, and flavor compounds. The process is influenced by factors such as yeast strain, fermentation temperature, oxygen levels, and pressure. Properly managing these variables is key to achieving the desired beer profile and avoiding off-flavors.
</Typography>
<Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    Types of Yeast
</Typography>
<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    Dry Yeast
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Dry yeast is easy to store and use, with a longer shelf life than liquid yeast. It is a reliable option for beginner and experienced brewers alike, offering consistent performance and a variety of strains.
</Typography>
<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    Liquid Yeast
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Liquid yeast provides access to a wider range of specialized strains, allowing brewers to fine-tune their beer's flavor profile. However, it has a shorter shelf life and requires careful handling to maintain viability.
</Typography>
<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    Which Should You Use?
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    The choice between dry and liquid yeast depends on your brewing goals and preferences. Dry yeast is ideal for its simplicity and reliability, while liquid yeast excels in offering unique strains for specific beer styles.
</Typography>
<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    What Is Flocculation?
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Flocculation refers to how yeast cells clump together and settle out of the beer after fermentation. High flocculation strains clear beer faster, while low flocculation strains remain in suspension longer, impacting clarity and mouthfeel.
</Typography>
<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    Why Alcohol Tolerance Matters
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Alcohol tolerance indicates the maximum alcohol content a yeast strain can handle before becoming inactive. This is crucial for brewing high-ABV styles like imperial stouts or barleywines.
</Typography>
<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    What Is Attenuation?
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Attenuation measures how much sugar a yeast strain ferments into alcohol. High attenuation results in a drier beer, while low attenuation leaves more residual sweetness.
</Typography>
<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    The Influence of Temperature and Pressure
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Temperature affects yeast activity and the development of esters and phenols, while pressure impacts carbonation and fermentation rate. Maintaining the optimal range ensures a clean and consistent fermentation.
</Typography>
<Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    How to Store Yeast
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Yeast should be stored in a refrigerator to preserve viability. Dry yeast can last up to 2 years in sealed packaging, while liquid yeast typically lasts 3-6 months. Always check expiration dates and viability before pitching.
</Typography>
<Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    About the Database
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    The yeast database is a comprehensive tool for brewers, offering detailed information on yeast strains. Explore form, attenuation, flocculation, and optimal temperature ranges to find the perfect yeast for your beer style. Whether you are a homebrewer or a commercial brewer, this resource simplifies yeast selection and experimentation.
</Typography>
{/* Disclaimer */}
<Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
    Disclaimer
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Yeast is a biological product, and its performance and characteristics can vary due to factors such as strain genetics, storage conditions, and handling practices. Environmental influences, including temperature, oxygen levels, and fermentation pressure, can significantly impact the activity and behavior of yeast during brewing.
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    While we strive to ensure the accuracy and reliability of the information provided in our database, variations in yeast performance may occur between batches or suppliers. Additionally, improper storage conditions such as exposure to high temperatures, contamination, or extended shelf life can negatively affect yeast viability and effectiveness.
</Typography>
<Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
    Brewers are encouraged to assess yeast viability before use, including inspecting packaging integrity and performing a vitality test if needed. Following best practices for yeast storage, rehydration, and pitching rates will help ensure consistent and high-quality fermentation results. Always refer to yeast supplier specifications for detailed information on each strain.
</Typography>



        </Box >
    );
};

export default YeastFinder;
