import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Link,
    Button,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Radar } from 'react-chartjs-2';
import axios from 'axios';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

// Register necessary Chart.js components
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const YeastDetailPanel = ({ open, onClose, yeastId }) => {
    const [yeastDetails, setYeastDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (yeastId && open) {
            const fetchYeastDetails = async () => {
                try {
                    setLoading(true);
                    const response = await axios.get(
                        `https://5ja6v4gg19.execute-api.us-east-1.amazonaws.com/prod/ingredients?type=yeast&id=${yeastId}`
                    );
                    setYeastDetails(response.data);
                } catch (error) {
                    setYeastDetails(null);
                } finally {
                    setLoading(false);
                }
            };
            fetchYeastDetails();
        }
    }, [yeastId, open]);
    

    // Chart data setup
    const chartData = yeastDetails
    ? {
        labels: [
            'Clean',
            'Fruity',
            'Spicy',
            'Clove-Like',
            'Smoky',
            'Funky',
            'Tart',
        ],
        datasets: [
            {
                label: `${yeastDetails.name} Flavor Profile`,
                data: [
                    yeastDetails.chart_clean || 0,
                    yeastDetails.chart_fruity || 0,
                    yeastDetails.chart_spicy || 0,
                    yeastDetails.chart_clovelike || 0,
                    yeastDetails.chart_smoky || 0,
                    yeastDetails.chart_funky || 0,
                    yeastDetails.chart_tart || 0,
                ],
                backgroundColor: 'rgba(255,181,192, 0.3)',
                borderColor: '#ffb5c0',
                borderWidth: 2,
                pointBackgroundColor: '#ffb5c0',
            },
        ],
    }
    : null;


    const chartOptions = {
        scales: {
            r: {
                min: -1,
                max: 5,
                ticks: {
                    stepSize: 1,
                    display: false,
                    backdropColor: 'transparent',
                },
                grid: {
                    color: '#ddd',
                },
                angleLines: {
                    color: '#ddd',
                },
                pointLabels: {
                    font: {
                        size: 16,
                    },
                    color: '#2b2d42',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold', position: 'relative' }}>
                {yeastDetails ? yeastDetails.name : 'Loading...'}
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ borderBottom: '2px solid #ddd', marginTop: '10px' }} />
            </DialogTitle>


            <DialogContent>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                        <CircularProgress />
                    </Box>
                ) : yeastDetails ? (
                    <>
                        <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: '20px' }}>
                            {yeastDetails.yeast_characteristics || 'No characteristics available'}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                            {chartData && <Radar data={chartData} options={chartOptions} />}
                        </Box>
                        <Table sx={{ marginBottom: '30px' }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>Laboratory</strong></TableCell>
                                    <TableCell>{yeastDetails.laboratory || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Code</strong></TableCell>
                                    <TableCell>{yeastDetails.code || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Form</strong></TableCell>
                                    <TableCell>{yeastDetails.form || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Alcohol Tolerance</strong></TableCell>
                                    <TableCell>{yeastDetails.alc_tolerance || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Flocculation</strong></TableCell>
                                    <TableCell>{yeastDetails.flocculation || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Acid Production</strong></TableCell>
                                    <TableCell>{yeastDetails.acid_production || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Ester Production</strong></TableCell>
                                    <TableCell>{yeastDetails.ester_production || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Phenol Production</strong></TableCell>
                                    <TableCell>{yeastDetails.phenol_production || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Diacetyl Production</strong></TableCell>
                                    <TableCell>{yeastDetails.diacetyl_production || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Fermentation Speed</strong></TableCell>
                                    <TableCell>{yeastDetails.fermentation_speed || 'N/A'}</TableCell>
                                </TableRow>
                               
                                
                                <TableRow>
                                <TableCell colSpan={2} >
                                        Of course our credo is that brewing is more science than art, hence here all the 
                                        <b> compounds and percentages</b> for {yeastDetails.name}:
                                    </TableCell>
                                </TableRow>
                               
                                <TableRow>
                                    <TableCell><strong>Attenuation</strong></TableCell>
                                    <TableCell>{yeastDetails.attenuation || 'N/A'} %</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Temperature Range</strong></TableCell>
                                    <TableCell>{yeastDetails.min_temp || 'N/A'} °F - {yeastDetails.max_temp || 'N/A'} °F</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} >
                                        If you currently can't get your hands on {yeastDetails.name}, it's not too bad. <br />
                                        You can often use one of the following <b>substitutes</b>:
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Substitutes</strong></TableCell>
                                    <TableCell>{yeastDetails.substitutes || 'No known supplier.'}</TableCell>



                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography variant="body1" sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
                            Description:
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: '20px' }}>
                            {yeastDetails.yeast_description || 'No description available'}
                        </Typography>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>Known Suppliers</strong></TableCell>
                                    <TableCell>{yeastDetails.suppliers || 'No known supplier.'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Typography
                            sx={{
                                textAlign: 'right',
                                fontSize: '12px',
                                color: '#555',
                                marginTop: '10px',
                            }}
                        >
                            Data by Connected Brewery | Private Use Only
                        </Typography>

                    </>
                ) : (
                    <Typography>No details available for this yeast.</Typography>
                )}
            </DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                        borderColor: '#555', // Grey outline
                        color: '#555',       // Text color
                        textTransform: 'none',
                        '&:hover': {
                            borderColor: '#333', // Darker grey on hover
                            color: '#333',       // Darker text on hover
                            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light grey hover background
                        },
                    }}
                >
                    Close
                </Button>

            </Box>
        </Dialog>
    );
};

export default YeastDetailPanel;
