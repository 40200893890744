import React, { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

// Styled components
const StyledAccordion = styled(Accordion)({
  backgroundColor: '#F0F4F8',
  borderRadius: '8px',
  marginBottom: '16px',
});

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fabc18',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#fabc18',
    },
  },
});

const UnitConverter = () => {
  //BEER & WORT CONVERSIONS
  const [sg, setSG] = useState('');
  const [plato, setPlato] = useState('');
  const [ebc, setEBC] = useState('');
  const [srm, setSRM] = useState('');
  const [l, setL] = useState('');

  //VOLUME & WEIGHT CONVERSIONS
  const [liters, setLiters] = useState('');
  const [usgal, setUSGal] = useState('');
  const [impgal, setImpGal] = useState('');
  const [oz, setOz] = useState('');
  const [bbl, setBbl] = useState('');
  const [kg, setKG] = useState('');
  const [lbs, setLBS] = useState('');
  const [ozWeight, setOzWeight] = useState('');

  //TEMPERATURE CONVERSIONS
  const [c, setC] = useState('');
  const [f, setF] = useState('');
  const [k, setK] = useState('');

  //OTHER CONVERSIONS
  const [eur, setEUR] = useState('');
  const [gbp, setGBP] = useState('');
  const [usd, setUSD] = useState('');
  const [cad, setCAD] = useState('');
  const [aud, setAUD] = useState('');
  const [meters, setMeters] = useState('');
  const [feet, setFeet] = useState('');

  // COLOR CONVERT
  const handleEBCChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const srm =
        value / 1.97
      const l = value / 2.65

      setEBC(value);
      setSRM(srm.toFixed(2));
      setL(l.toFixed(2));
    } else {
      setEBC('');
      setSRM('');
      setL('');
    }
  };
  const handleSRMChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const ebc = value * 1.97
      const l = ebc / 2.65

      setEBC(ebc.toFixed(2));
      setSRM(value);
      setL(l.toFixed(2));
    } else {
      setEBC('');
      setSRM('');
      setL('');
    }
  };
  const handleLChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const ebc = value * 2.65
      const srm = ebc / 1.97

      setEBC(ebc.toFixed(2));
      setSRM(srm.toFixed(2));
      setL(value);
    } else {
      setEBC('');
      setSRM('');
      setL('');
    }
  };

  // GRAVITY CONVERT
  const handleSpecificGravityChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const plato =
        (value - 1) * 258.6

      setSG(value);
      setPlato(plato.toFixed(2));
    } else {
      setSG('');
      setPlato('');
    }
  };
  const handlePlatoChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const sg = 1 + value / (258.6 - value / 258.2);
      setPlato(value);
      setSG(sg.toFixed(3));
    } else {
      setPlato('');
      setSG('');
    }
  };

  // VOLUME CONVERT
  const handleLitersChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const usgal = value * 0.264172;
      const impgal = value * 0.219969;
      const oz = value * 33814;
      const bbl = value * 0.00628981;

      setLiters(value);
      setUSGal(usgal.toFixed(2));
      setImpGal(impgal.toFixed(2));
      setOz(oz.toFixed(2));
      setBbl(bbl.toFixed(2));
    } else {
      setLiters('');
      setUSGal('');
      setImpGal('');
      setOz('');
      setBbl('');
    }
  };
  const handleUsGalChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const liter = value * 3.78541;
      const impgal = value * 0.832674;
      const oz = value * 128;
      const bbl = value * 0.0238095;


      setLiters(liter.toFixed(2));
      setUSGal(value);
      setImpGal(impgal.toFixed(2));
      setOz(oz.toFixed(2));
      setBbl(bbl.toFixed(2));
    } else {
      setLiters('');
      setUSGal('');
      setImpGal('');
      setOz('');
      setBbl('');
    }
  };
  const handleImpGalChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const liters = value * 4.54609;
      const usgal = value * 1.20095;
      const oz = value * 153.722;
      const bbl = value * 0.0283776;


      setLiters(liters.toFixed(2));
      setUSGal(usgal.toFixed(2));
      setImpGal(value);
      setOz(oz.toFixed(2));
      setBbl(bbl.toFixed(2));
    } else {
      setLiters('');
      setUSGal('');
      setImpGal('');
      setOz('');
      setBbl('');
    }
  };
  const handleOzChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const liters = value * 0.0295735;
      const usgal = value * 0.0078125;
      const impgal = value * 0.00625;
      const bbl = value * 0.0000295735;

      setLiters(liters.toFixed(2));
      setUSGal(usgal.toFixed(2));
      setImpGal(impgal.toFixed(2));
      setOz(value);
      setBbl(bbl.toFixed(4));
    } else {
      setLiters('');
      setUSGal('');
      setImpGal('');
      setOz('');
      setBbl('');
    }
  };
  const handleBBLChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const liters = value * 158.987;
      const usgal = value * 42;
      const impgal = value * 34.9723;
      const oz = value * 5376;

      setLiters(liters.toFixed(2));
      setUSGal(usgal.toFixed(2));
      setImpGal(impgal.toFixed(2));
      setOz(oz.toFixed(2));
      setBbl(value);
    } else {
      setLiters('');
      setUSGal('');
      setImpGal('');
      setOz('');
      setBbl('');
    }
  };


  // WEIGHT CONVERT
  const handleKGChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const lbs = value * 2.20462;
      const oz = value * 35.274;

      setKG(value);
      setLBS(lbs.toFixed(2));
      setOzWeight(oz.toFixed(2));
    } else {
      setKG('');
      setLBS('');
      setOzWeight('');
    }
  };
  const handleLBSChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const kg = value / 2.20462;
      const oz = value * 16;

      setKG(kg.toFixed(2));
      setLBS(value);
      setOzWeight(oz.toFixed(2));
    } else {
      setKG('');
      setLBS('');
      setOzWeight('');
    }
  };
  const handleOzWeightChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const lbs = value / 16;
      const kg = value / 35.274;

      setKG(kg.toFixed(2));
      setLBS(lbs.toFixed(2));
      setOzWeight(value);
    } else {
      setKG('');
      setLBS('');
      setOzWeight('');
    }
  };

  // TEMPERATURE CONVERT
  const handleCChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const fahrenheit = value * 9 / 5 + 32;
      const kelvin = value + 273.15;

      setC(value);
      setF(fahrenheit.toFixed(2));
      setK(kelvin.toFixed(2));
    } else {
      setC('');
      setF('');
      setK('');
    }
  };
  const handleFChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const celsius = (value - 32) * 5 / 9;
      const kelvin = celsius + 273.15;

      setC(celsius.toFixed(2));
      setF(value);
      setK(kelvin.toFixed(2));
    } else {
      setC('');
      setF('');
      setK('');
    }
  };
  const handleKChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      const celsius = value - 273.15;
      const fahrenheit = celsius * 9 / 5 + 32;

      setC(celsius.toFixed(2));
      setF(fahrenheit.toFixed(2));
      setK(value);
    } else {
      setC('');
      setF('');
      setK('');
    }
  };

  // CURRENCY CONVERT
  const handleEURChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {

      setEUR(value);
      setGBP((value * 0.835).toFixed(2));
      setUSD((value * 1.05).toFixed(2));
      setCAD((value * 1.48).toFixed(2));
      setAUD((value * 1.65).toFixed(2));

    } else {
      setEUR('');
      setGBP('');
      setUSD('');
      setCAD('');
      setAUD('');
    }
  };
  const handleGBPChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {

      setGBP(value);
      setEUR((value * 1.21).toFixed(2));
      setUSD((value * 1.26).toFixed(2));
      setCAD((value * 1.80).toFixed(2));
      setAUD((value * 1.99).toFixed(2));

    } else {
      setEUR('');
      setGBP('');
      setUSD('');
      setCAD('');
      setAUD('');
    }
  };
  const handleUSDChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {

      setUSD(value);
      setEUR((value * 0.95).toFixed(2));
      setGBP((value * 0.79).toFixed(2));
      setCAD((value * 1.42).toFixed(2));
      setAUD((value * 1.57).toFixed(2));

    } else {
      setEUR('');
      setGBP('');
      setUSD('');
      setCAD('');
      setAUD('');
    }
  };
  const handleCADChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {

      setCAD(value);
      setEUR((value * 0.67).toFixed(2));
      setGBP((value * 0.56).toFixed(2));
      setUSD((value * 0.70).toFixed(2));
      setAUD((value * 1.11).toFixed(2));

    } else {
      setEUR('');
      setGBP('');
      setUSD('');
      setCAD('');
      setAUD('');
    }
  };
  const handleAUDChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {

      setAUD(value);
      setEUR((value * 0.61).toFixed(2));
      setGBP((value * 0.50).toFixed(2));
      setUSD((value * 0.64).toFixed(2));
      setCAD((value * 0.90).toFixed(2));

    } else {
      setEUR('');
      setGBP('');
      setUSD('');
      setCAD('');
      setAUD('');
    }
  };


  // LENGTHS CONVERT
  const handleMetersChange = (e) => {
    const feet = e.target.value;
    setMeters(feet);
    setFeet(feet ? (feet * 3.28084).toFixed(2) : '');
  };

  const handleFeetChange = (e) => {
    const meter = e.target.value;
    setFeet(meter);
    setMeters(meter ? (meter / 3.28084).toFixed(2) : '');
  };

  return (

    <Box sx={{ padding: '20px', minHeight: '100vh', maxWidth: '1200px', display: 'flex', flexDirection: 'column', gap: '20px', backgroundColor: '#FAF9F1' }}>
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #ccc',
          paddingBottom: '10px',
        }}
      >
        <Typography variant="h3">Beer Color Calculator</Typography>

      </Box>


      <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '16px' }}>
        Access live updates and all the essential brewing calculators and units in one place. Whether you're calculating volumes, weights,
        temperatures, or beer color, this mobile-friendly tool ensures accuracy and convenience for every brewer.
      </Typography>


      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          marginTop: '32px',
          marginBottom: '16px',
          color: '#2b2d42', // White heading font color
          textAlign: 'center',
        }}
      >
        Beer & Wort Conversions
      </Typography>

      {/* BEER COLOR CONVERT */}
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ color: '#2b2d42' }}>EBC - SRM - °L | Beer Color Converter</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <hr />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Typography style={{ textAlign: 'justify', paddingTop: '8px' }}>
              Convert between EBC, SRM, and °L beer color scales.
            </Typography>
            <StyledTextField
              label="EBC"
              type="number"
              value={ebc}
              onChange={handleEBCChange}
              fullWidth
            />
            <StyledTextField
              label="SRM"
              type="number"
              value={srm}
              onChange={handleSRMChange}
              fullWidth
            />
            <StyledTextField
              label="°L"
              type="number"
              value={l}
              onChange={handleLChange}
              fullWidth
            />
            <Typography sx={{ marginTop: '20px', padding: '8px', textAlign: 'justify' }}>
              Beer color is an essential aspect of brewing, offering insight into the style, flavor, and quality of your beer.
              The EBC (European Brewing Convention), SRM (Standard Reference Method), and °L (Lovibond) scales are
              commonly used to measure and describe beer color. Each scale has its unique application, but they all aim to
              capture the visual hue of the final product.
              <br />
              Learn more or use the advanced beer color calculator to refine your recipes at{' '}
              <a href="/tools/calculators/color-srm-ebc" style={{ color: '#4c9a2a', textDecoration: 'none' }}>
                Beer Color Calculator
              </a>.
            </Typography>

          </Box>

        </AccordionDetails>
      </StyledAccordion>

      {/* GRAVITY PLATO CONVERT */}
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ color: '#2b2d42' }}>SG - °P | Gravity Conversion</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <hr />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Typography style={{ textAlign: 'justify', paddingTop: '8px' }}>
              Convert between Specific Gravity (SG) and Plato (°P) beer gravity scales.
            </Typography>
            <StyledTextField
              label="Specific Gravity (SG)"
              type="number"
              value={sg}
              onChange={handleSpecificGravityChange}
              fullWidth
            />
            <StyledTextField
              label="Plato (°P)"
              type="number"
              value={plato}
              onChange={handlePlatoChange}
              fullWidth
            />
            <Typography sx={{ marginTop: '20px', padding: '8px', textAlign: 'justify' }}>
              Specific Gravity (SG) and Degrees Plato (°P) are critical measurements in brewing, used to determine the
              sugar content of wort and track fermentation progress. SG measures the density of the wort compared to
              water, while °P expresses the percentage of dissolved solids by weight. Accurate conversions between SG
              and °P help ensure consistency and quality in every batch.
              <br />
              Explore more gravity related resources and calculators such as {' '}
              <a href="/tools/calculators/abv" style={{ color: '#4c9a2a ', textDecoration: 'none' }}>
                ABV Calculator
              </a>, our <a href="/tools/calculators/hydro-temp-correct" style={{ color: '#4c9a2a ', textDecoration: 'none' }}>
                Hydrometer Temperature Correction Calculator
              </a> to adjust for temperature differences between the hydrometer calibration and the actual wort temperature,
              or learn more about SG and °P at our designated  <a href="/tools/calculators/plato-gravity-converter" style={{ color: '#4c9a2a ', textDecoration: 'none' }}>
                Plato to Gravity Converter
              </a>.
            </Typography>

          </Box>


        </AccordionDetails>
      </StyledAccordion>

      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          marginTop: '32px',
          marginBottom: '16px',
          color: '#2b2d42', // White heading font color
          textAlign: 'center',
        }}
      >
        Volume & Weight Calculators
      </Typography>

      {/* VOLUME CONVERT */}
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ color: '#2b2d42' }}>Liters - Gal(US) - Gal(Imp) - fl.Oz(US) - BBL | Volume Conversions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <hr />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Typography style={{ textAlign: 'justify', paddingTop: '8px' }}>
              Convert various volume units used in brewing to adjust recipes and ensure accurate measurements.
            </Typography>
            <StyledTextField
              label="Liters (L)"
              type="number"
              value={liters}
              onChange={handleLitersChange}
              fullWidth
            />
            <StyledTextField
              label="US Gallons (US Gal)"
              type="number"
              value={usgal}
              onChange={handleUsGalChange}
              fullWidth
            />
            <StyledTextField
              label="Imperial Gallons (Imp. Gal)"
              type="number"
              value={impgal}
              onChange={handleImpGalChange}
              fullWidth
            />
            <StyledTextField
              label="US Fluid Ounce (oz)"
              type="number"
              value={oz}
              onChange={handleOzChange}
              fullWidth
            />
            <StyledTextField
              label="BBL"
              type="number"
              value={bbl}
              onChange={handleBBLChange}
              fullWidth
            />
            <Typography sx={{ marginTop: '20px', padding: '8px', textAlign: 'justify' }}>
              The Volume Calculator helps you easily convert between common brewing volume units, including liters, US gallons,
              imperial gallons, US liquid ounces, and barrels (BBL). Accurate volume conversions are essential for scaling recipes,
              brewing consistency, and ensuring your vessels match your brewing process.
              <br></br>
              When looking at volumes, ensure, you are using the volumes from your recipe. Especially between US Gallons and
              Imperial Gallons, there is a potential for mistakes. Ensure you use the correct unit to avoid discrepancies in your
              brewing calculations.
            </Typography>

          </Box>

        </AccordionDetails>
      </StyledAccordion>

      {/* WEIGHT CONVERT */}
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ color: '#2b2d42' }}>KG - LBS - OZ | Weight Conversions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <hr />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Typography style={{ textAlign: 'justify', paddingTop: '8px' }}>
              Conversion between kilograms, pounds, and ounces for precise ingredient measurements and recipe adjustments.
            </Typography>
            <StyledTextField
              label="Kilograms (KG)"
              type="number"
              value={kg}
              onChange={handleKGChange}
              fullWidth
            />
            <StyledTextField
              label="Punds (lbs)"
              type="number"
              value={lbs}
              onChange={handleLBSChange}
              fullWidth
            />
            <StyledTextField
              label="Ounce"
              type="number"
              value={ozWeight}
              onChange={handleOzWeightChange}
              fullWidth
            />
            <Typography sx={{ marginTop: '20px', padding: '8px', textAlign: 'justify' }}>
              The Weight Calculator allows seamless conversions between kilograms, pounds (lbs), and ounces (oz). Precise weight
              measurements are crucial for calculating ingredient proportions, ensuring consistency, and maintaining recipe integrity
              throughout the brewing process.


            </Typography>

          </Box>

        </AccordionDetails>
      </StyledAccordion>

      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          marginTop: '32px',
          marginBottom: '16px',
          color: '#2b2d42', // White heading font color
          textAlign: 'center',
        }}
      >
        Temperature
      </Typography>

      {/* TEMPERATURE CONVERT */}
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ color: '#2b2d42' }}>°C - °F - K | Temperature Conversions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <hr />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Typography style={{ textAlign: 'justify', paddingTop: '8px' }}>
              Convert between Celsius, Fahrenheit, and Kelvin temperature scales for precise temperature control in brewing.
            </Typography>
            <StyledTextField
              label="Celsius (°C)"
              type="number"
              value={c}
              onChange={handleCChange}
              fullWidth
            />
            <StyledTextField
              label="Fahrenheit (°F)"
              type="number"
              value={f}
              onChange={handleFChange}
              fullWidth
            />
            <StyledTextField
              label="Kelvin (K)"
              type="number"
              value={k}
              onChange={handleKChange}
              fullWidth
            />
            <Typography sx={{ marginTop: '20px', padding: '8px', textAlign: 'justify' }}>
              The Temperature Calculator provides instant conversions between Celsius, Fahrenheit, and Kelvin. Precise temperature
              control is vital in every step of brewing, from mashing to fermentation, ensuring you achieve the desired flavors and
              quality in your beer.
              <br />
              If you need to adjust your gravity reading for a new temperature, visit our {' '}
              <a href="/tools/calculators/hydro-temp-correct" style={{ color: '#4c9a2a', textDecoration: 'none' }}>
                Hydrometer Temperature Correction Calculator
              </a>.
            </Typography>

          </Box>

        </AccordionDetails>
      </StyledAccordion>


      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          marginTop: '32px',
          marginBottom: '16px',
          color: '#2b2d42', // White heading font color
          textAlign: 'center',
        }}
      >
        Others
      </Typography>

      {/* CURRENCY CONVERT */}
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ color: '#2b2d42' }}>EUR - GBP - USD - AUD - CAD | Currency Converter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <hr />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Typography style={{ textAlign: 'justify', paddingTop: '8px' }}>
              Get an indication about conversion rates, when calculation prices of international suppliers.
            </Typography>
            <StyledTextField
              label="Euro (€)"
              type="number"
              value={eur}
              onChange={handleEURChange}
              fullWidth
            />
            <StyledTextField
              label="British Pound (£)"
              type="number"
              value={gbp}
              onChange={handleGBPChange}
              fullWidth
            />
            <StyledTextField
              label="US Dollar ($)"
              type="number"
              value={usd}
              onChange={handleUSDChange}
              fullWidth
            />
            <StyledTextField
              label="Canadian Dollar (CAD)"
              type="number"
              value={cad}
              onChange={handleCADChange}
              fullWidth
            />
            <StyledTextField
              label="Australian Dollar (AUD)"
              type="number"
              value={aud}
              onChange={handleAUDChange}
              fullWidth
            />
            <Typography sx={{ marginTop: '20px', padding: '8px', textAlign: 'justify' }}>
              The Currency Converter provides indicative conversions between common currencies such as GBP, USD, EUR, AUD, and CAD.
              Please note that this tool is not a reliable source for live exchange rates and should only be used as a general guide.
              Conversion rates may not reflect the current market conditions.
              <br />
              For accurate and up-to-date conversion rates, we recommend checking a reliable online source such as{' '}
              <a href="https://www.xe.com/currencyconverter/" target="_blank"
                rel="noopener noreferrer" style={{ color: '#4c9a2a', textDecoration: 'none' }}>
                XE Currency Converter
              </a>.
            </Typography>

          </Box>

        </AccordionDetails>
      </StyledAccordion>

      {/* DISTANCE CONVERT */}
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ color: '#2b2d42' }}>Length Converter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <hr />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Typography style={{ textAlign: 'justify', paddingTop: '8px' }}>
              Convert meters to feet and vice versa.
            </Typography>
            <StyledTextField
              label="Meters"
              type="number"
              value={meters}
              onChange={handleMetersChange}
              fullWidth
            />
            <StyledTextField
              label="Feet"
              type="number"
              value={feet}
              onChange={handleFeetChange}
              fullWidth
            />
            <Typography sx={{ marginTop: '20px', padding: '8px', textAlign: 'justify' }}>
              The Length Converter simplifies conversions between meters and feet, essential for ensuring your brewing setup and
              equipment dimensions align with your workspace. Accurate measurements are crucial for a smooth brewing process.
            </Typography>

          </Box>

        </AccordionDetails>
      </StyledAccordion>

      {/* Text Below Accordion */}
      <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '16px' }}>
        These calculators are designed to provide indicative results, with final values rounded for simplicity. <br/>
        While they help streamline brewing processes, always double-check critical measurements when precision is key to your recipe's success.
        Remember, for us brewing is a science, not an art - And in science, precision is key. <br/><br/>

        As brewing is a global craft, and with recipes and customers using different scales and metrics, these calculators are crucial
        for ensuring consistency and understanding across borders. <br></br><br></br>If you notice a calculator is missing or have suggestions, feel
        free to email us at{' '}
        <a href="mailto:contact@connected-brewery.com?subject=Add%20Calculator" style={{ color: '#4c9a2a', textDecoration: 'none' }}>
          contact@connected-brewery.com
        </a>{' '}<br></br>
         Happy brewing!
      </Typography>

    </Box>
  );
};

export default UnitConverter;
