import React from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import packageJson from '../../package.json';

const Footer = () => {
  // Detect if the screen width is 800px or wider
  const isDesktop = useMediaQuery('(min-width:800px)');

  return (
    <footer style={{ padding: '20px', backgroundColor: '#2b2d42' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
          alignItems: 'center',
          justifyContent: isDesktop ? 'space-between' : 'center',
          paddingLeft: isDesktop ? '24px' : '0',
          paddingRight: isDesktop ? '24px' : '0',
        }}
      >
        
        <Typography variant="body2" sx={{ color: '#F4F1DE', textAlign: isDesktop ? 'left' : 'center', flex: isDesktop ? 1 : 'unset' }}>
          © 2024-2025 Has A Brewery. All rights reserved.<br></br>
          Version: {packageJson.version} - Build: {packageJson.build}
        </Typography>

        <Typography variant="body2" sx={{ color: '#F4F1DE', textAlign: isDesktop ? 'left' : 'center', flex: isDesktop ? 1 : 'unset' }}>
          Made with ❤️ & many 🍺 | by <a href='https://mh-flyhigh.com' style={{ color: '#FABC18', textDecoration: 'none' }} >Mirko</a> & other Homebrewers
        </Typography>

        
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            justifyContent: isDesktop ? 'flex-end' : 'center',
            marginTop: isDesktop ? '0' : '10px',
          }}
        >
          <IconButton
            component="a"
            href="https://github.com/mirkohahn"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="GitHub"
          >
            <GitHubIcon sx={{ color: '#F4F1DE' }} />
          </IconButton>

          <IconButton
            component="a"
            href="https://www.linkedin.com/in/mirkohahn/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <LinkedInIcon sx={{ color: '#F4F1DE' }}/>
          </IconButton>

          <IconButton
            component="a"
            href="https://instagram.com/mh_flyhigh/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <InstagramIcon sx={{ color: '#F4F1DE' }}/>
          </IconButton>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
