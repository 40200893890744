import React, { useState } from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    Button,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    IconButton,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CompareIngredientsPanel from './CompareIngredientsPanel';
import GrainDetailPanel from './GrainDetailPanel';
import YeastDetailPanel from './YeastDetailPanel';
import HopDetailPanel from './HopDetailPanel';
import { Radar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

import { styled } from '@mui/system';
import { useUnit } from '../../context/UnitContext';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);


const StyledButton = styled(Button)({
    padding: '10px 20px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    backgroundColor: '#2b2d42',
    color: '#f4f1de',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: 'none',
    transition: 'background-color 0.3s ease-in-out, transform 0.2s',
    '&:hover': {
        backgroundColor: '#FABC18',
        color: '#2b2d42',
        transform: 'scale(1.15)',
    },
});

const StyledSelect = styled(Select)({
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#fabc18',
        },
    },
});

const CompareIngredients = () => {

    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isDetailPanelOpen, setIsDetailPanelOpen] = useState(false);

    const handleRowClick = (id) => {
        setSelectedRowId(id);
        setIsDetailPanelOpen(true);
    };

    const handleCloseDetailPanel = () => {
        setSelectedRowId(null);
        setIsDetailPanelOpen(false);
    };

    const { unit, switchToImperial, switchToMetric } = useUnit();
    const [selectedOption, setSelectedOption] = useState('compareMalt');
    const [ingredients, setIngredients] = useState([]); // Store ingredient data
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentInput, setCurrentInput] = useState('');

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentInput('');
    };

    const handleAddIngredient = (id) => {
        console.log('Ingredient added with ID (Parent):', id);
        const defaultColors = [
            '#FACB18', '#4C9A2A', '#CCCCFF', '#CCFFE6', '#FFCCFF',
            '#800080', '#00FFFF', '#FFC0CB', '#CACCDD', '#2B2D42',
        ];
    
        const typeMap = {
            compareMalt: 'fermentables_malted_grains',
            compareHops: 'hops',
            compareYeasts: 'yeast',
        };
    
        const selectedType = typeMap[selectedOption];
        console.log('Selected Type for API Call:', selectedType);
    
        fetch(
            `https://5ja6v4gg19.execute-api.us-east-1.amazonaws.com/prod/ingredients?type=${selectedType}&id=${id}`
        )
            .then((response) => {
                console.log('API Response Status:', response.status);
                if (!response.ok) throw new Error(`Error: ${response.status} ${response.statusText}`);
                return response.json();
            })
            .then((data) => {
                console.log('Fetched Ingredient Data:', data);
                setIngredients((prev) => [
                    ...prev,
                    {
                        id,
                        name: data.name || 'Unknown',
                        color: defaultColors[prev.length % defaultColors.length],
                        data,
                    },
                ]);
            })
            .catch((error) => {
                console.error('Error fetching ingredient data:', error);
            });
    };
    



    const handleDeleteIngredient = (index) => {
        setIngredients((prev) => prev.filter((_, i) => i !== index));
    };

    const labelToColumnMapper = {
        axisLabels: {
            compareMalt: {
                Bready: 'chart_bready',
                Caramel: 'chart_caramel',
                Nutty: 'chart_nutty',
                Chocolate: 'chart_chocolate',
                Coffee: 'chart_coffee',
                Honey: 'chart_honey',
                Smokey: 'chart_smoky',
                Biscuity: 'chart_biscuity',
                Roasted: 'chart_roasted',
            },
            compareHops: {
                Citrus: 'chart_citrus',
                'Tropical Fruit': 'chart_tropical',
                'Stone Fruit': 'chart_stone',
                Berry: 'chart_berry',
                Flora: 'chart_floral',
                Grassy: 'chart_grassy',
                Herbal: 'chart_herbal',
                Spice: 'chart_spice',
                'Reslin/Pine': 'chart_resin',
            },
            compareYeasts: {
                Clean: 'chart_clean',
                Fruity: 'chart_fruity',
                Spicy: 'chart_spicy',
                'Clove-Like': 'chart_clovelike',
                Smoky: 'chart_smoky',
                Funky: 'chart_funky',
                Tart: 'chart_tart',
            },
        },
        tableColumns: {
            compareMalt: {
                Name: 'name',
                Type: 'type',
                "Color EBC": 'color_ebc',
                DP: 'dp',
                Acidity: 'acidity',
                Fermentability: 'fermentability',
            },
            compareHops: {
                Name: 'name',
                Purpose: 'purpose',
                "Alpha Acid": 'alpha',
                "Beta Acid": 'beta',
                "Co-Humulone": 'cohumulone',
                'Total Oil': 'total_oil',
            },
            compareYeasts: {
                Name: 'name',
                Attenuation: 'attenuation',
                'Temp Range': 'min_temp',
                Flocculation: 'flocculation',
                Form: 'form',
                'Alc Tolerance': 'alc_tolerance',
            },
        },
    };
    const axisLabels = {
        compareMalt: ['Bready', 'Caramel', 'Nutty', 'Chocolate', 'Coffee', 'Honey', 'Smokey', 'Biscuity', 'Roasted'],
        compareHops: ['Citrus', 'Tropical Fruit', 'Stone Fruit', 'Berry', 'Flora', 'Grassy', 'Herbal', 'Spice', 'Reslin/Pine'],
        compareYeasts: ['Clean', 'Fruity', 'Spicy', 'Clove-Like', 'Smoky', 'Funky', 'Tart'],
    };

    const tableColumns = {
        compareMalt: ['Name', 'Type', 'Color EBC', 'DP', 'Acidity', 'Fermentability'],
        compareHops: ['Name', 'Purpose', 'Alpha Acid', 'Beta Acid', 'Co-Humulone', 'Total Oil'],
        compareYeasts: ['Name', 'Attenuation', 'Temp Range', 'Flocculation', 'Form', 'Alc Tolerance'],
    };


    const tableHeaders = tableColumns[selectedOption];
    const tableDataColumns = tableHeaders.map(
        (header) => labelToColumnMapper.tableColumns[selectedOption][header]
    );

    const tableBody = ingredients.map((ingredient) =>
        tableDataColumns.map((column) => ingredient.data?.[column] || 'N/A')
    );


    const chartData = {
        labels: axisLabels[selectedOption], // Use user-friendly axis labels for display
        datasets: ingredients.map((ingredient) => ({
            label: ingredient.name,
            data: axisLabels[selectedOption].map(
                (label) =>
                    ingredient.data?.[labelToColumnMapper.axisLabels[selectedOption][label]] || 0
            ),
            backgroundColor: `${ingredient.color}4D`, // Uses assigned color with 30% opacity
            borderColor: ingredient.color, // Matches the selected color
        })),
    };


    const chartOptions = {
        scales: {
            r: {
                min: -1, // Set minimum value
                max: 5, // Set maximum value
                ticks: {
                    display: false, // Hide axis labels
                },
                grid: {
                    color: '#ccc', // Optional: Customize grid line color
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                enabled: true, // Enable tooltips
            },
        },
    };






    return (
        <Box sx={{ padding: '20px', backgroundColor: '#FAF9F1', minHeight: '100vh' }}>
            {/* Header Section */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #ccc',
                    paddingBottom: '10px',
                }}
            >
                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                    Compare Brewing Ingredients
                </Typography>
                <StyledSelect
                    value={unit}
                    onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
                    variant="outlined"
                    size="small"
                    sx={{ width: '150px' }}
                >
                    <MenuItem value="Metric">Metric</MenuItem>
                    <MenuItem value="Imperial">Imperial</MenuItem>
                </StyledSelect>
            </Box>
            <Typography
                variant="body1"
                sx={{ textAlign: 'center', marginBottom: '16px', marginTop: '20px' }}
            >
                Discover the Ingredient Finder: a curated selection of grains, hops, and yeasts, designed to provide detailed insights into their flavor, aroma, and brewing characteristics. Explore clickable entries for each ingredient, offering information on origin, typical beer styles, and usage recommendations. Perfect for brewers seeking to refine their recipes or experiment with new beer styles.
            </Typography>


            {/* Selection and Content Section */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    marginTop: '20px',
                }}
            >
                {/* 1/3 section */}
                <Box
                    sx={{
                        flex: { xs: '1 1 auto', md: '1' },
                        padding: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={selectedOption}
                            onChange={handleOptionChange}
                            aria-label="compare options"
                            name="compareOptions"
                        >
                            <FormControlLabel
                                value="compareMalt"
                                control={<Radio sx={{
                                    color: '#2B2D42', // Unselected color outline
                                    '&.Mui-checked': {
                                        color: '#FACB18', // Selected button color and outline
                                    },
                                }} />}
                                label="Compare Malt & Grain"
                            />
                            <FormControlLabel
                                value="compareHops"
                                control={<Radio sx={{
                                    color: '#2B2D42', // Unselected color outline
                                    '&.Mui-checked': {
                                        color: '#FACB18', // Selected button color and outline
                                    },
                                }} />}
                                label="Compare Hops"
                            />
                            <FormControlLabel
                                value="compareYeasts"
                                control={<Radio sx={{
                                    color: '#2B2D42', // Unselected color outline
                                    '&.Mui-checked': {
                                        color: '#FACB18', // Selected button color and outline
                                    },
                                }} />}
                                label="Compare Yeasts"
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>

                {/* 2/3 section */}
                <Box
                    sx={{
                        flex: { xs: '1 1 auto', md: '2' },
                        padding: '16px',
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{ textAlign: 'center', marginBottom: '16px', marginTop: '20px' }}
                    >
                        Explore the Ingredient Finder, featuring grains, hops, and yeasts submitted by brewers worldwide. Select a type to compare, add ingredients, and analyze charts and data tailored to your selection below. Click on a table row to open detailed information about each ingredient, including its origin, usage recommendations, and more. While you can store and manage ingredients across different types, comparisons are shown and most useful for the currently selected type. Perfect for refining recipes or experimenting with new brewing styles.
                    </Typography>

                </Box>
            </Box>

            {/* Ingredients Section */}
            <Box sx={{ marginTop: '20px' }}>
                {ingredients.map((ingredient, index) => {
                    const colorOptions = [
                        { hex: '#FACB18', name: 'Yellow' },
                        { hex: '#4C9A2A', name: 'Green' },
                        { hex: '#CCCCFF', name: 'Lavender' },
                        { hex: '#CCFFE6', name: 'Mint Green' },
                        { hex: '#FFCCFF', name: 'Pink' },
                        { hex: '#800080', name: 'Purple' },
                        { hex: '#00FFFF', name: 'Cyan' },
                        { hex: '#FFC0CB', name: 'Pink' },
                        { hex: '#CACCDD', name: 'Light Gray' },
                        { hex: '#2B2D42', name: 'Dark Gray' },
                    ];
                    const defaultColor = colorOptions[index % colorOptions.length].hex;
                    const selectedColor = ingredient.color || defaultColor;

                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '8px',
                                border: `2px solid ${selectedColor}`,
                                borderRadius: '8px',
                                marginBottom: '8px',
                                backgroundColor: `${selectedColor}4D`, // Adds 30% opacity
                            }}
                        >
                            <Typography
                                sx={{
                                    flex: 1,
                                    textAlign: 'left',
                                }}
                            >
                                {ingredient.name}
                            </Typography>
                            <Select
                                value={selectedColor}
                                onChange={(e) =>
                                    setIngredients((prev) =>
                                        prev.map((ing, i) =>
                                            i === index ? { ...ing, color: e.target.value } : ing
                                        )
                                    )
                                }
                                sx={{
                                    minWidth: '250px',
                                    marginRight: '16px',
                                    border: `1px solid ${selectedColor}`,
                                    '& .MuiSelect-select': {
                                        textAlign: 'right',
                                    },
                                }}
                            >
                                {colorOptions.map((color) => (
                                    <MenuItem key={color.hex} value={color.hex}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <Typography>{color.name}</Typography>
                                            <Typography sx={{ marginLeft: '8px', paddingRight: '8px' }}>
                                                {color.hex}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    width: '20px',
                                                    height: '20px',
                                                    backgroundColor: color.hex,
                                                    marginLeft: '8px',
                                                    border: '1px solid #ddd',
                                                }}
                                            />
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                            <IconButton
                                onClick={() => handleDeleteIngredient(index)}
                                sx={{ color: '#2b2d42' }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    );
                })}

                <Button
                    onClick={handleOpenModal}
                    sx={{
                        marginTop: '10px',
                        minWidth: '150px',
                        width: { xs: '200px', lg: '40%' },
                        justifyContent: 'center',
                        marginLeft: { xs: '0px', lg: '30%' },
                        border: '1px solid #2b2d42',
                        color: '#2b2d42',
                        '&:hover': {
                            backgroundColor: '#FABC18',
                            color: '#2b2d42',
                        },
                    }}
                >
                    <AddIcon sx={{ marginRight: '8px', color: '#2b2d42' }} />
                    {selectedOption === 'compareYeasts'
                        ? 'Add Yeast'
                        : selectedOption === 'compareHops'
                            ? 'Add Hop'
                            : 'Add Malt'}
                </Button>
            </Box>

            {/* Chart Section */}
            <Box sx={{ marginTop: '20px', padding: '20px', backgroundColor: '#fff', borderRadius: '8px' }}>
                <Typography variant="h5" sx={{ marginBottom: '16px' }}>
                    {selectedOption === 'compareMalt'
                        ? 'Malt Radar Chart'
                        : selectedOption === 'compareHops'
                            ? 'Hop Radar Chart'
                            : 'Yeast Radar Chart'}
                </Typography>
                <Radar data={chartData} options={chartOptions} />
            </Box>



            {/* Table Section */}
            <Box sx={{ marginTop: '20px' }}>
                <Typography variant="h5" sx={{ marginBottom: '16px' }}>
                    Comparison Table
                </Typography>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            {tableHeaders.map((header) => (
                                <th
                                    key={header}
                                    style={{
                                        border: '1px solid #ddd',
                                        padding: '12px',
                                        textAlign: 'left',
                                        backgroundColor: '#5A5A5A',
                                        color: '#f4f1de',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableBody.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: '#f5f5f5',
                                    transition: 'background-color 0.3s ease-in-out',
                                }}
                                onClick={() => handleRowClick(ingredients[rowIndex].id)} // Open detail panel with ID
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e0e0e0')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f5f5f5')}
                            >
                                {row.map((cell, cellIndex) => (
                                    <td
                                        key={cellIndex}
                                        style={{
                                            border: '1px solid #ddd',
                                            padding: '12px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {cell}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>

            {selectedOption === 'compareMalt' && (
                <GrainDetailPanel
                    open={isDetailPanelOpen}
                    onClose={handleCloseDetailPanel}
                    grainId={selectedRowId}
                />
            )}
            {selectedOption === 'compareYeasts' && (
                <YeastDetailPanel
                    open={isDetailPanelOpen}
                    onClose={handleCloseDetailPanel}
                    yeastId={selectedRowId}
                />
            )}
            {selectedOption === 'compareHops' && (
                <HopDetailPanel
                    open={isDetailPanelOpen}
                    onClose={handleCloseDetailPanel}
                    hopId={selectedRowId}
                />
            )}



            {/* Outsourced Panel */}
            <CompareIngredientsPanel
                open={isModalOpen}
                onClose={() => {
                    console.log('Modal closed');
                    handleCloseModal();
                }}
                selectionType={
                    selectedOption === 'compareYeasts'
                        ? 'yeast'
                        : selectedOption === 'compareHops'
                            ? 'hops'
                            : 'fermentables_malted_grains'
                }
                currentInput={currentInput}
                setCurrentInput={(input) => {
                    console.log('Current input updated:', input);
                    setCurrentInput(input);
                }}
                handleAddIngredient={(id) => {
                    console.log('Ingredient added with ID:', id); // Debugging log
                    handleAddIngredient(id);
                }}
            />


            <Typography
                sx={{
                    textAlign: 'right',
                    fontSize: '12px',
                    color: '#555',
                    marginTop: '10px',
                }}
            >
                Data by Connected Brewery | Private Use Only
            </Typography>
            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <StyledButton
                    onClick={() => {
                        window.location.href = "mailto:contact@connected-brewery.com?subject=Submit%20New%20Grain%20Information";
                    }}
                >
                    Submit New Grain Information
                </StyledButton>
            </Box>


            {/* Content Section */}
            <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '20px', color: '#555' }}>
                The Malt, Hops, and Yeast Finders are powerful tools designed to help brewers explore, compare, and understand key brewing ingredients.
                Use the <a href="/tools/ingredients/grain-finder" style={{ textDecoration: 'none', color: '#4c9a2a' }}>Malt Finder</a>,
                <a href="/tools/ingredients/hop-finder" style={{ textDecoration: 'none', color: '#4c9a2a' }}>Hops Finder</a>,
                and <a href="/tools/ingredients/yeast-finder" style={{ textDecoration: 'none', color: '#4c9a2a' }}>Yeast Finder</a> to delve into detailed insights
                about flavor profiles, brewing characteristics, and optimal usage of each ingredient.
            </Typography>
            <p></p>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
                Understanding Ingredient Comparison
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Ingredient comparison is a cornerstone of effective recipe design. By selecting and comparing grains, hops, and yeasts, you gain a deeper
                understanding of how each component contributes to your beer's aroma, flavor, and texture. The radar chart visualizes ingredient attributes,
                allowing you to quickly identify key differences or similarities.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                The table offers a side-by-side comparison of ingredient properties, including diastatic power, acidity, alpha acids, attenuation, and more.
                Click on any row to access detailed information about the ingredient, such as its origin, recommended usage, and style compatibility. This
                combination of visualization and data helps brewers make informed decisions and refine their brewing process with precision.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'justify' }}>
                Whether you're crafting a light pilsner, a hop-forward IPA, or a complex Belgian ale, the ingredient comparison feature is your guide to
                unlocking the full potential of your recipe. Experiment, explore, and create beers that stand out with this comprehensive tool.
            </Typography>


        </Box>

    );
};

export default CompareIngredients;
