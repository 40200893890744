import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Link, Element } from 'react-scroll';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import SupportProject from './pages/SupportProject';
import NotFoundPage from './pages/NotFoundPage';
// Tools
import Tools from './pages/Tools';
import CompareIngredients from './pages/ingredients/CompareIngredients';
import GrainFinder from './pages/ingredients/GrainFinder';
import HopFinder from './pages/ingredients/HobFinder';
import YeastFinder from './pages/ingredients/YeastFinder';
import BeerXMLConverter from './pages/recipes/BeerXMLConverter';
import ABVCalculator from './pages/calculators/ABVCalculator';
import HydroTempCorrect from './pages/calculators/HydroTempCorrect';
import PlatoGravity from './pages/calculators/PlatoGravity';
import IBUCalculator from './pages/calculators/IBUCalculator';
import UnitConverter from './pages/calculators/UnitConverter';
import RampTimes from './pages/calculators/RampTimes';
import GravityAdjustments from './pages/calculators/GravityAdjustments';
import BoilOffVolumes from './pages/calculators/BoilOffVolumes';
import ColorSRM from './pages/calculators/ColorSRM';
import BrewhouseEfficiency from './pages/calculators/BrewhouseEfficiency';
import BottleCalculator from './pages/calculators/BottleCalculator';
import PrimingSugar from './pages/calculators/PrimingSugar';
import KegCarbonation from './pages/calculators/KegCarbonation';
import ForceCarbonation from './pages/calculators/ForceCarbonation';
import WaterProfileCalc from './pages/calculators/WaterProfileCalc';
import WaterAmounts from './pages/calculators/WaterAmounts';
import Beginner from './pages/setup/Beginner';
import Intermediate from './pages/setup/Intermediate';
import Advanced from './pages/setup/Advanced';
import AlphaAcid from './pages/charts/AlphaAcidChart';
import BeerStyle from './pages/charts/BeerStyles';
import InfoCharts from './pages/charts/InfoCharts';
import PlateSG from './pages/charts/PlateSGChart';
import WaterProfiles from './pages/charts/WaterProfiles';
import WortCorrection from './pages/charts/WortCorrectionChart';
import BeerTasting from './pages/more/BeerTastingSheet';
import FermentationLogs from './pages/more/FermentationLogs';
import LabelGenerator from './pages/more/LabelGenerator';
// Brew Education
import BrewEducation from './pages/BrewEducation';
// Get Started
import GetStarted from './pages/GetStarted';

function App() {
  return (
    
    <>
      <Header />
      <div style={{ paddingTop: '60px' }}>
        {/* Eagle Animation Container */}
        <div id="eagle-container"></div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/support-project" element={<SupportProject />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/tnc" element={<TermsConditions />} />

          {/* ROUTES FOR TOOLS  */}
          <Route path="/tools" element={<Tools />}>
            {/* ROUTES FOR INGREDIENTS */}
            <Route path="ingredients/compare-ingredients" element={<CompareIngredients />} />
            <Route path="ingredients/grain-finder" element={<GrainFinder />} />
            <Route path="ingredients/hop-finder" element={<HopFinder />} />
            <Route path="ingredients/yeast-finder" element={<YeastFinder/>} />
            {/* ROUTES FOR INGREDIENTS */}
            <Route path="recipes/beerxml-converter" element={<BeerXMLConverter/>} />
            {/* ROUTES FOR CALCULATORS */}
            <Route path="calculators/abv" element={<ABVCalculator />} />
            <Route path="calculators/hydro-temp-correct" element={<HydroTempCorrect />} />
            <Route path="calculators/plato-gravity-converter" element={<PlatoGravity />} />
            <Route path="calculators/gravity-adjustments" element={<GravityAdjustments />} />
            <Route path="calculators/ibu" element={<IBUCalculator />} />
            <Route path="calculators/unit-converter" element={<UnitConverter />} />
            <Route path="calculators/ramp-times" element={<RampTimes />} />
            <Route path="calculators/boil-off-volumnes" element={<BoilOffVolumes />} />
            <Route path="calculators/color-srm-ebc" element={<ColorSRM />} />
            <Route path="calculators/brewhouse-efficiency" element={<BrewhouseEfficiency />} />
            <Route path="calculators/bottle" element={<BottleCalculator />} />
            <Route path="calculators/priming-sugar" element={<PrimingSugar />} />
            <Route path="calculators/keg-carbonation" element={<KegCarbonation />} />
            <Route path="calculators/force-carbonation" element={<ForceCarbonation />} />
            <Route path="calculators/water-profile-calc" element={<WaterProfileCalc />} />
            <Route path="calculators/water-amounts" element={<WaterAmounts />} />
            {/* ROUTES FOR SETUP */}
            <Route path="setup/beginner" element={<Beginner />} />
            <Route path="setup/intermediate" element={<Intermediate />} />
            <Route path="setup/advanced" element={<Advanced />} />
            {/* ROUTES FOR CHARTS */}
            <Route path="charts/alpha-acid" element={<AlphaAcid />} />
            <Route path="charts/beer-styles" element={<BeerStyle />} />
            <Route path="charts/info-charts" element={<InfoCharts />} />
            <Route path="charts/plato-sg" element={<PlateSG />} />
            <Route path="charts/water-profiles" element={<WaterProfiles />} />
            <Route path="charts/wort-correction" element={<WortCorrection />} />
            {/* ROUTES FOR MORE */}
            <Route path="more/beer-tasting-sheet" element={<BeerTasting />} />
            <Route path="more/fermentation-logs" element={<FermentationLogs />} />
            <Route path="more/label-generator" element={<LabelGenerator />} />
          </Route>
          {/* ROUTES FOR BREW EDUCATION */}
          <Route path="/brew-education" element={<BrewEducation />} />
          {/* ROUTES FOR Get Started */}
          <Route path="/get-started" element={<GetStarted />} />

          {/* Fallback Route for 404 */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>

        <Footer />
      </div>
      
    </>
  );
}

export default App;
