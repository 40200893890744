import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Link,
    Button,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Radar } from 'react-chartjs-2';
import axios from 'axios';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

// Register necessary Chart.js components
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const GrainDetailPanel = ({ open, onClose, grainId }) => {
    const [grainDetails, setGrainDetails] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (grainId && open) {
            const fetchGrainDetails = async () => {
                try {
                    setLoading(true);
                    const response = await axios.get(
                        `https://5ja6v4gg19.execute-api.us-east-1.amazonaws.com/prod/ingredients?type=fermentables_malted_grains&id=${grainId}`
                    );
                    setGrainDetails(response.data);
                } catch (error) {
                    setGrainDetails(null);
                } finally {
                    setLoading(false);
                }
            };
            fetchGrainDetails();
        }
    }, [grainId, open]);
    

    // Chart data setup
    const chartData = grainDetails
    ? {
        labels: [
            'Bready',
            'Caramel',
            'Nutty',
            'Chocolate',
            'Coffee',
            'Honey',
            'Smoky',
            'Biscuity',
            'Roasted',
        ],
        datasets: [
            {
                label: `${grainDetails.name} Flavor Profile`,
                data: [
                    grainDetails.chart_bready || 0,
                    grainDetails.chart_caramel || 0,
                    grainDetails.chart_nutty || 0,
                    grainDetails.chart_chocolate || 0,
                    grainDetails.chart_coffee || 0,
                    grainDetails.chart_honey || 0,
                    grainDetails.chart_smoky || 0,
                    grainDetails.chart_biscuity || 0,
                    grainDetails.chart_roasted || 0,
                ],
                backgroundColor: 'rgba(250, 188, 24, 0.3)',
                borderColor: '#FABC18',
                borderWidth: 2,
                pointBackgroundColor: '#FABC18',
            },
        ],
    }
    : null;


    const chartOptions = {
        scales: {
            r: {
                min: -1,
                max: 5,
                ticks: {
                    stepSize: 1,
                    display: false,
                    backdropColor: 'transparent',
                },
                grid: {
                    color: '#ddd',
                },
                angleLines: {
                    color: '#ddd',
                },
                pointLabels: {
                    font: {
                        size: 16,
                    },
                    color: '#2b2d42',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle sx={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold', position: 'relative' }}>
                {grainDetails ? grainDetails.name : 'Loading...'}
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ borderBottom: '2px solid #ddd', marginTop: '10px' }} />
            </DialogTitle>


            <DialogContent>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                        <CircularProgress />
                    </Box>
                ) : grainDetails ? (
                    <>
                        <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: '20px' }}>
                            {grainDetails.characteristics || 'No characteristics available'}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                            {chartData && <Radar data={chartData} options={chartOptions} />}
                        </Box>
                        <Table sx={{ marginBottom: '30px' }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>Name</strong></TableCell>
                                    <TableCell>{grainDetails.product || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Brand</strong></TableCell>
                                    <TableCell>{grainDetails.brand || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Category</strong></TableCell>
                                    <TableCell>{grainDetails.category || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Type</strong></TableCell>
                                    <TableCell>{grainDetails.type || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Origin</strong></TableCell>
                                    <TableCell>{grainDetails.origin || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Beer Styles</strong></TableCell>
                                    <TableCell>{grainDetails.style_guide || 'N/A'}</TableCell>
                                </TableRow>
                                
                                <TableRow>
                                <TableCell colSpan={2} >
                                        Of course our credo is that brewing is more science than art, hence here all the 
                                        <b> compounds and percentages</b> for {grainDetails.name}:
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Color</strong></TableCell>
                                    <TableCell>{grainDetails.color_l || 'N/A'} °L ({grainDetails.color_l ? Math.round(grainDetails.color_l * 2.65) : 'N/A'} EBC)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>PPG (Points p. Pount p. Gallon)</strong></TableCell>
                                    <TableCell>{Math.round(grainDetails.ppg) || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Protein</strong></TableCell>
                                    <TableCell>{grainDetails.protein || 'N/A'} %</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>DP (Diastatic Power)</strong></TableCell>
                                    <TableCell>{grainDetails.dp || 'N/A'} °Lintner ({grainDetails.dp ? Math.round(grainDetails.dp * 3.5) : 'N/A'} WK)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Moisture</strong></TableCell>
                                    <TableCell>{grainDetails.moisture || 'N/A'} %</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Acidity</strong></TableCell>
                                    <TableCell>{grainDetails.acidity || 'N/A'} pH</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>fermentability</strong></TableCell>
                                    <TableCell>{grainDetails.fermentability || 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Usage recommendation</strong></TableCell>
                                    <TableCell>{grainDetails.usage_recommendation || 'N/A'} %</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} >
                                        If you currently can't get your hands on {grainDetails.name}, it's not too bad. <br />
                                        You can often use one of the following <b>substitutes</b>:
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Substitutes</strong></TableCell>
                                    <TableCell>{grainDetails.substitutes || 'No known supplier.'}</TableCell>



                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography variant="body1" sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
                            Description:
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: '20px' }}>
                            {grainDetails.description || 'No description available'}
                        </Typography>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>Known Suppliers</strong></TableCell>
                                    <TableCell>{grainDetails.suppliers || 'No known supplier.'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Typography
                            sx={{
                                textAlign: 'right',
                                fontSize: '12px',
                                color: '#555',
                                marginTop: '10px',
                            }}
                        >
                            Data by Connected Brewery | Private Use Only
                        </Typography>

                    </>
                ) : (
                    <Typography>No details available for this malt.</Typography>
                )}
            </DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                        borderColor: '#555', // Grey outline
                        color: '#555',       // Text color
                        textTransform: 'none',
                        '&:hover': {
                            borderColor: '#333', // Darker grey on hover
                            color: '#333',       // Darker text on hover
                            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light grey hover background
                        },
                    }}
                >
                    Close
                </Button>

            </Box>
        </Dialog>
    );
};

export default GrainDetailPanel;
