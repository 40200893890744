import React, { useState } from 'react';
import LookUpGrain from '../../components/LookUpGrain';

import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import for the expand icon
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { useUnit } from '../../context/UnitContext';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet';


const StyledButton = styled(Button)({
  padding: '10px 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  backgroundColor: '#2b2d42',
  color: '#f4f1de',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  transition: 'background-color 0.3s ease-in-out, transform 0.2s',
  '&:hover': {
    backgroundColor: '#FABC18',
    color: '#2b2d42',
    transform: 'scale(1.15)',
  },
});

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fabc18',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#fabc18',
    },
  },
});

const BeerColorCalculator = () => {

  const { unit, switchToImperial, switchToMetric } = useUnit();
  const [inputValues, setInputValues] = useState([{ weight: '', color: '', colorUnit: 'EBC', weightUnit: 'kg' }]);
  const [volume, setVolume] = useState('');
  const [volumeUnit, setVolumeUnit] = useState(unit === 'Metric' ? 'L' : 'gal');
  const [results, setResults] = useState({
    totalWeight: '0',
    mcu: '0',
    ebc: '0',
    srmMorey: '0',
    srmDaniels: '0',
    srmMosher: '0',
    rgb: 'rgb(255,255,255)',
  });

  const convertToMetric = (value, fromUnit) => {
    if (fromUnit === 'lbs') return value * 0.453592; // lbs to kg
    if (fromUnit === 'gal') return value * 3.78541;  // gallons to liters
    if (fromUnit === 'SRM') return value * 1.97;     // SRM to EBC
    if (fromUnit === '°L') return value * 2.65;      // °L to EBC
    return value; // If already metric or no conversion needed
  };

  const handleInputChange = (index, field, value) => {
    const updatedValues = [...inputValues];
    updatedValues[index][field] = value;
    setInputValues(updatedValues);
  };

  const [lookupModalOpen, setLookupModalOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const openLookupModal = (index) => {
    setSelectedRowIndex(index);
    setLookupModalOpen(true);
  };

  const closeLookupModal = () => {
    setLookupModalOpen(false);
    setSelectedRowIndex(null);
  };


  const overrideAllUnits = (newUnit) => {
    setInputValues((prevValues) =>
      prevValues.map((grain) => ({
        ...grain,
        weightUnit: newUnit === 'Metric' ? 'kg' : 'lbs',
        colorUnit: newUnit === 'Metric' ? 'EBC' : 'SRM',
      }))
    );
    setVolumeUnit(newUnit === 'Metric' ? 'L' : 'gal');
  };

  const addGrainRow = () => {
    setInputValues([...inputValues, { weight: '', color: '', colorUnit: 'EBC', weightUnit: 'kg' }]);
  };

  const deleteGrainRow = (index) => {
    setInputValues(inputValues.filter((_, i) => i !== index));
  };


  const calculateResults = () => {
    console.log(`Entered Volume: ${volume}; Selected Unit: ${volumeUnit}`);
    const metricVolume = convertToMetric(parseFloat(volume || 0), volumeUnit);
    console.log(`Conversion to Metric; ${volume} ${volumeUnit} = ${metricVolume} L`);

    let totalWeight = 0;
    inputValues.forEach((grain, index) => {
      console.log(`Grains ${index + 1} Entered:`);
      console.log(`Entered Weight: ${grain.weight}; Selected Weight Unit: ${grain.weightUnit}`);
      const metricWeight = convertToMetric(parseFloat(grain.weight || 0), grain.weightUnit);
      console.log(`Conversion to Metric; ${grain.weight} ${grain.weightUnit} = ${metricWeight} kg`);

      console.log(`Entered Color: ${grain.color}; Selected Color Unit: ${grain.colorUnit}`);
      const metricColor = convertToMetric(parseFloat(grain.color || 0), grain.colorUnit);
      console.log(`Conversion to EBC; ${grain.color} ${grain.colorUnit} = ${metricColor} EBC`);

      totalWeight += metricWeight;
    });

    console.log(`#### Calculation ####`);
    console.log(`Total Weight = ${totalWeight.toFixed(2)} kg`);

    const mcu = inputValues.reduce(
      (sum, grain) =>
        sum + ((convertToMetric(parseFloat(grain.weight || 0)) * 2.20462) * (convertToMetric(parseFloat(grain.color || 0))) / 2.65) / (metricVolume * 0.219969),
      0
    );

    console.log(`MCU = Σ (Weight * Color) / Volume`);
    console.log(`MCU = ${mcu.toFixed(2)}`);

    const srmMorey = 1.4922 * Math.pow(mcu, 0.6859);
    const srmDaniels = (mcu * 0.2) + 8.4;
    const srmMosher = (mcu * 0.3) + 4.7;
    const ebc = srmMorey * 1.97;

    console.log(`SRM (Morey) = ${srmMorey.toFixed(2)}`);
    console.log(`SRM (Daniels) = ${srmDaniels.toFixed(2)}`);
    console.log(`SRM (Mosher) = ${srmMosher.toFixed(2)}`);
    console.log(`EBC = ${ebc.toFixed(2)}`);

    // Replace this section in your calculateResults function
    const ebcToSrmColor = (ebc) => {
      const srm = ebc / 1.97;

      const srmColorMap = [
        { srm: 1, hex: "#FFE699" },
        { srm: 2, hex: "#FFD878" },
        { srm: 3, hex: "#FFCA5A" },
        { srm: 4, hex: "#FFBF42" },
        { srm: 5, hex: "#FBB123" },
        { srm: 6, hex: "#F8A600" },
        { srm: 7, hex: "#F39C00" },
        { srm: 8, hex: "#EA8F00" },
        { srm: 9, hex: "#E58500" },
        { srm: 10, hex: "#DE7C00" },
        { srm: 11, hex: "#D77200" },
        { srm: 12, hex: "#CF6900" },
        { srm: 13, hex: "#CB6200" },
        { srm: 14, hex: "#C35900" },
        { srm: 15, hex: "#BB5100" },
        { srm: 16, hex: "#B54C00" },
        { srm: 17, hex: "#B04500" },
        { srm: 18, hex: "#A63E00" },
        { srm: 19, hex: "#A13700" },
        { srm: 20, hex: "#9B3200" },
        { srm: 21, hex: "#952D00" },
        { srm: 22, hex: "#8E2900" },
        { srm: 23, hex: "#882300" },
        { srm: 24, hex: "#821E00" },
        { srm: 25, hex: "#7B1A00" },
        { srm: 26, hex: "#771900" },
        { srm: 27, hex: "#701400" },
        { srm: 28, hex: "#6A0E00" },
        { srm: 29, hex: "#660D00" },
        { srm: 30, hex: "#5E0B00" },
        { srm: 31, hex: "#5A0A02" },
        { srm: 32, hex: "#600903" },
        { srm: 33, hex: "#520907" },
        { srm: 34, hex: "#4C0505" },
        { srm: 35, hex: "#470606" },
        { srm: 36, hex: "#440607" },
        { srm: 37, hex: "#3F0708" },
        { srm: 38, hex: "#3B0607" },
        { srm: 39, hex: "#3A070B" },
        { srm: 40, hex: "#36080A" },
      ];

      if (srm >= 60) return "#000000";
      if (srm > 40 && srm < 60) return "#1E0204";

      const closestColor = srmColorMap.reduce((prev, curr) =>
        Math.abs(curr.srm - srm) < Math.abs(prev.srm - srm) ? curr : prev
      );

      return closestColor.hex;
    };

    const hex = ebcToSrmColor(ebc);
    console.log(`EBC: ${ebc}, Hex Color: ${hex}`);
    const rgb = hex;

    // Use the calculated `rgb` value in your results


    setResults({
      totalWeight: unit === 'Metric' ? totalWeight.toFixed(2) : (totalWeight / 0.453592).toFixed(2),
      mcu: mcu.toFixed(2),
      ebc: ebc.toFixed(2),
      srmMorey: srmMorey.toFixed(2),
      srmDaniels: srmDaniels.toFixed(2),
      srmMosher: srmMosher.toFixed(2),
      rgb,
    });
    <LookUpGrain
      open={lookupModalOpen}
      onClose={closeLookupModal}
      rowIndex={selectedRowIndex}
      onSelect={(index, value, unit) => {
        const updatedValues = [...inputValues];
        updatedValues[index] = { ...updatedValues[index], color: value, colorUnit: unit };
        setInputValues(updatedValues);
      }}
    />

  };


  return (
    <div>
      <Helmet>
        <title>Beer Color Calculator - Has A Brewery</title>
        <meta
          name="description"
          content="Calculate beer color with precision using Morey, Daniels, and Mosher formulas. Supports SRM and EBC scales."
        />
      </Helmet>

      <Box sx={{ padding: '20px', minHeight: '100vh', maxWidth: '1200px', display: 'flex', flexDirection: 'column', gap: '20px', backgroundColor: '#FAF9F1' }}>
        {/* Header Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #ccc',
            paddingBottom: '10px',
          }}
        >
          <Typography variant="h3">Beer Color Calculator</Typography>
          <Select
            value={unit}
            onChange={(e) => {
              const newUnit = e.target.value;
              newUnit === 'Metric' ? switchToMetric() : switchToImperial();
              overrideAllUnits(newUnit);
            }}
            variant="outlined"
            size="small"
            sx={{ width: '150px' }}
          >
            <MenuItem value="Metric">Metric</MenuItem>
            <MenuItem value="Imperial">Imperial</MenuItem>
          </Select>
        </Box>

        <Divider />
        <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '16px' }}>
          Estimate the color of your beer using accurate calculations based on grain weight, color contributions, and batch volume.
        </Typography>

        {/* Volume Section */}
        <Typography variant="h6" sx={{ marginTop: '20px', marginBottom: '10px', color: '#2b2d42' }}>Batch Volume</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <StyledTextField
            label={`Batch Volume (${volumeUnit})`}
            type="number"
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
          />
          <Select
            value={volumeUnit}
            onChange={(e) => setVolumeUnit(e.target.value)}
            size="small"
            sx={{ width: '100px', height: '56px' }}
          >
            <MenuItem value="L">L</MenuItem>
            <MenuItem value="gal">gal</MenuItem>
          </Select>
        </Box>

        <Divider sx={{ margin: '20px 0' }} />

        {/* Grains Section */}
<Typography variant="h6" sx={{ marginTop: '20px', marginBottom: '10px', color: '#2b2d42' }}>
  Grains
</Typography>
{inputValues.map((grain, index) => (
  <Box
    key={index}
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', lg: 'row' }, // Stack vertically for small screens
      gap: '10px',
      alignItems: 'flex-start',
      marginBottom: '16px',
    }}
  >
    {/* Weight Section */}
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: '10px', width: '100%' }}>
      <StyledTextField
        label={`Weight (${grain.weightUnit})`}
        type="number"
        value={grain.weight}
        onChange={(e) => handleInputChange(index, 'weight', e.target.value)}
      />
      <Select
        value={grain.weightUnit}
        onChange={(e) => handleInputChange(index, 'weightUnit', e.target.value)}
        size="small"
        sx={{ width: { xs: '100%', sm: '200px' }, height: '56px' }}
      >
        <MenuItem value="kg">kg</MenuItem>
        <MenuItem value="lbs">lbs</MenuItem>
      </Select>
    </Box>

    {/* Color Section */}
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: '10px', width: '100%' }}>
      <StyledTextField
        label={`Color (${grain.colorUnit})`}
        type="number"
        value={grain.color}
        onChange={(e) => handleInputChange(index, 'color', e.target.value)}
      />
      <Select
        value={grain.colorUnit}
        onChange={(e) => handleInputChange(index, 'colorUnit', e.target.value)}
        size="small"
        sx={{ width: { xs: '100%', sm: '200px' }, height: '56px' }}
      >
        <MenuItem value="EBC">EBC</MenuItem>
        <MenuItem value="SRM">SRM</MenuItem>
        <MenuItem value="°L">°L</MenuItem>
      </Select>
    </Box>

    {/* Action Buttons */}
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons vertically for small screens
        gap: '10px',
        width: '100%',
      }}
    >
      <Button
        onClick={() => openLookupModal(index)}
        sx={{
          borderColor: '#2b2d42',
          color: '#2b2d42',
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: '#FABC18',
            color: '#FABC18',
          },
          padding: '5px 10px',
          fontSize: '14px',
        }}
      >
        <SearchIcon sx={{ marginRight: '5px' }} />
        Look Up Grain
      </Button>
      {index > 0 && (
        <Button
          onClick={() => deleteGrainRow(index)}
          sx={{
            background: 'none',
            color: '#2b2d42',
            display: 'flex',
            alignItems: 'center',
            padding: '5px 10px',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#FABC18',
            },
          }}
        >
          <DeleteIcon sx={{ marginRight: '8px', fontSize: '18px' }} />
          Delete
        </Button>
      )}
    </Box>
  </Box>
))}



        <Button
          onClick={addGrainRow}
          sx={{
            marginTop: '10px',
            minWidth: '150px',
            width: {xs: '200px',  lg: '40%'},
            justifyContent: 'center',
            // padding: '8px 16px',
            marginLeft: {xs: '0px', lg: '30%'},
            border: '1px solid #2b2d42',
            color: '#2b2d42', // Font color
            '&:hover': {
              backgroundColor: '#FABC18',
              color: '#2b2d42',
            },
          }}
        >
          <AddIcon sx={{ marginRight: '8px', color: '#2b2d42' }} /> Add Grain
        </Button>


        <StyledButton onClick={calculateResults}>Calculate</StyledButton>

        {/* Results Section */}
        <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#FCFCF8', fontWeight: 'bold' }}>Total Weight</TableCell>
                <TableCell sx={{ color: '#FCFCF8', fontWeight: 'bold' }}>MCU</TableCell>
                <TableCell sx={{ color: '#FCFCF8', fontWeight: 'bold' }}>EBC</TableCell>
                <TableCell sx={{ color: '#FCFCF8', fontWeight: 'bold' }}>SRM Morey</TableCell>
                <TableCell sx={{ color: '#FCFCF8', fontWeight: 'bold' }}>SRM Daniels</TableCell>
                <TableCell sx={{ color: '#FCFCF8', fontWeight: 'bold' }}>SRM Mosher</TableCell>
                <TableCell sx={{ color: '#FCFCF8', fontWeight: 'bold' }}>Color Preview</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{results.totalWeight} {unit === 'Metric' ? 'kg' : 'lbs'}</TableCell>
                <TableCell>{results.mcu}</TableCell>
                <TableCell>{results.ebc}</TableCell>
                <TableCell>{results.srmMorey}</TableCell>
                <TableCell>{results.srmDaniels}</TableCell>
                <TableCell>{results.srmMosher}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor: results.rgb,
                      width: '50px',
                      height: '20px',
                      borderRadius: '4px',
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* Educational Section */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            marginTop: '32px',
            marginBottom: '16px',
            color: '#2b2d42',
            textAlign: 'center',
          }}
        >
          Understanding Beer Color Calculations
        </Typography>
        <Box sx={{
                    position: 'relative',
                    paddingTop: '56.25%', // 16:9 aspect ratio
                    marginBottom: '16px',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <iframe
                        src="https://www.youtube.com/embed/sFBxZaIP8OI"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%'
                        }}
                    ></iframe>
                </Box>
        {/* Introduction */}
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          Beer color is an essential aspect of brewing, offering insight into a beer’s style, flavor, and aroma. This calculator
          provides predictions using standard brewing formulas, but remember that outcomes depend on individual brew efficiencies.
          The displayed hex values are based on observational mapping and may vary slightly from actual beer appearances.
        </Typography>

        {/* Color Mapping Section */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginTop: '32px',
              marginBottom: '16px',
              color: '#2b2d42', // White heading font color
              textAlign: 'center',
            }}
          >
            Color Mapping
          </Typography>
          <Accordion
            sx={{
              backgroundColor: '#F0F4F8', // Light grey background
              borderRadius: '8px', // Rounded corners
              marginBottom: '16px',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1"><u>Expand to View SRM to #Hex Color Mapping</u></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', color: '#ffffff' }}>Color</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#ffffff' }}>SRM Value</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#ffffff' }}>EBC Value</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#ffffff' }}>Hex Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    { srm: 1, ebc: 1.97, hex: '#FFE699' },
                    { srm: 2, ebc: 3.94, hex: '#FFD878' },
                    { srm: 3, ebc: 5.91, hex: '#FFCA5A' },
                    { srm: 4, ebc: 7.88, hex: '#FFBF42' },
                    { srm: 5, ebc: 9.85, hex: '#FBB123' },
                    { srm: 6, ebc: 11.82, hex: '#F8A600' },
                    { srm: 7, ebc: 13.79, hex: '#F39C00' },
                    { srm: 8, ebc: 15.76, hex: '#EA8F00' },
                    { srm: 9, ebc: 17.73, hex: '#E58500' },
                    { srm: 10, ebc: 19.7, hex: '#DE7C00' },
                    { srm: 11, ebc: 21.67, hex: '#D77200' },
                    { srm: 12, ebc: 23.64, hex: '#CF6900' },
                    { srm: 13, ebc: 25.61, hex: '#CB6200' },
                    { srm: 14, ebc: 27.58, hex: '#C35900' },
                    { srm: 15, ebc: 29.55, hex: '#BB5100' },
                    { srm: 16, ebc: 31.52, hex: '#B54C00' },
                    { srm: 17, ebc: 33.49, hex: '#B04500' },
                    { srm: 18, ebc: 35.46, hex: '#A63E00' },
                    { srm: 19, ebc: 37.43, hex: '#A13700' },
                    { srm: 20, ebc: 39.4, hex: '#9B3200' },
                    { srm: 21, ebc: 41.37, hex: '#952D00' },
                    { srm: 22, ebc: 43.34, hex: '#8E2900' },
                    { srm: 23, ebc: 45.31, hex: '#882300' },
                    { srm: 24, ebc: 47.28, hex: '#821E00' },
                    { srm: 25, ebc: 49.25, hex: '#7B1A00' },
                    { srm: 26, ebc: 51.22, hex: '#771900' },
                    { srm: 27, ebc: 53.19, hex: '#701400' },
                    { srm: 28, ebc: 55.16, hex: '#6A0E00' },
                    { srm: 29, ebc: 57.13, hex: '#660D00' },
                    { srm: 30, ebc: 59.1, hex: '#5E0B00' },
                    { srm: 31, ebc: 61.07, hex: '#5A0A02' },
                    { srm: 32, ebc: 63.04, hex: '#600903' },
                    { srm: 33, ebc: 65.01, hex: '#520907' },
                    { srm: 34, ebc: 66.98, hex: '#4C0505' },
                    { srm: 35, ebc: 68.95, hex: '#470606' },
                    { srm: 36, ebc: 70.92, hex: '#440607' },
                    { srm: 37, ebc: 72.89, hex: '#3F0708' },
                    { srm: 38, ebc: 74.86, hex: '#3B0607' },
                    { srm: 39, ebc: 76.83, hex: '#3A070B' },
                    { srm: 40, ebc: 78.8, hex: '#36080A' },
                    { srm: 50, ebc: 80, hex: '#1E0204' },
                    { srm: 60, ebc: 120, hex: '#000000' },
                  ].map((color) => (
                    <TableRow key={color.srm}>
                      <TableCell sx = {{ background: '#F0F4F8' }}>
                        <Box
                          sx={{
                            width: '30px',
                            height: '30px',
                            backgroundColor: color.hex,
                            borderRadius: '4px',
                          }}
                        />
                      </TableCell>
                      <TableCell sx = {{ background: '#F0F4F8' }}>{color.srm}</TableCell>
                      <TableCell sx = {{ background: '#F0F4F8' }}>{color.ebc.toFixed(2)}</TableCell>
                      <TableCell sx = {{ background: '#F0F4F8' }}>{color.hex}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </Box>


        {/* Colors & Beer Styles Section */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginTop: '32px',
              marginBottom: '16px',
              color: '#2b2d42',
              textAlign: 'center',
            }}
          >
            Colors & Beer Styles
          </Typography>
          <Accordion
            sx={{
              backgroundColor: '#F0F4F8', // Light grey background
              borderRadius: '8px', // Rounded corners
              marginBottom: '16px',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1"><u>Expand to View Beer Styles by Color</u></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', color: '#ffffff' }}>Color</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#ffffff' }}>Color Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#ffffff' }}>SRM Range</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#ffffff' }}>Styles</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    {
                      color: "#FFE699",
                      name: "Pale Straw",
                      range: "2-4",
                      styles:
                        "Light Beer including Pale/Lite Lager, Pilsner, Berliner Weisse, Witbier, New England IPA",
                    },
                    {
                      color: "#FFD878",
                      name: "Straw",
                      range: "3-6",
                      styles: "Maibock (Helles), Blonde Ale, California Common, Kolsch, Cream Ale",
                    },
                    {
                      color: "#FFCA5A",
                      name: "Pale Gold",
                      range: "4-8",
                      styles: "Weissbier, Lambic, Belgian Tripel",
                    },
                    {
                      color: "#FBB123",
                      name: "Deep Gold",
                      range: "6-12",
                      styles: "India Pale Ale (IPA), American Pale Ale",
                    },
                    {
                      color: "#F8A600",
                      name: "Pale Amber",
                      range: "8-16",
                      styles: "Saison, English Pale Ale, Irish Red Ale",
                    },
                    {
                      color: "#EA8F00",
                      name: "Medium Amber",
                      range: "10-20",
                      styles: "English Bitter (ESB), Belgian Dubbel",
                    },
                    {
                      color: "#DE7C00",
                      name: "Deep Amber",
                      range: "13-26",
                      styles: "Double IPA, Biere de Garde, Altbier, Barleywine, Scotch Ale",
                    },
                    {
                      color: "#CF6900",
                      name: "Amber Brown",
                      range: "17-33",
                      styles: "Amber Ale, Vienna Lager, Dark Lager, Marzen",
                    },
                    {
                      color: "#BB5100",
                      name: "Brown",
                      range: "20-39",
                      styles: "Bock, Dunkelweizen, Brown Ale, Brown Porter",
                    },
                    {
                      color: "#771900",
                      name: "Ruby Brown",
                      range: "24-47",
                      styles: "Robust Porter, Dopplebock, Irish Dry Stout, Oatmeal Stout",
                    },
                    {
                      color: "#5A0A02",
                      name: "Deep Brown",
                      range: "29-57",
                      styles: "American Stout",
                    },
                    {
                      color: "#1E0204",
                      name: "Black",
                      range: "35-79",
                      styles: "Russian Imperial Stout",
                    },
                  ].map((style) => (
                    <TableRow key={style.name}>
                      <TableCell sx = {{ background: '#F0F4F8' }}>
                        <Box
                          sx={{
                            width: '30px',
                            height: '30px',
                            backgroundColor: style.color,
                            borderRadius: '4px',
                          }}
                        />
                      </TableCell>
                      <TableCell sx = {{ background: '#F0F4F8' }}>{style.name}</TableCell>
                      <TableCell sx = {{ background: '#F0F4F8' }}>{style.range}</TableCell>
                      <TableCell sx = {{ background: '#F0F4F8' }}>{style.styles}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </Box>


        {/* Formula Section */}
        <Typography variant="h5" sx={{ marginTop: '32px', color: '#2b2d42', textAlign: 'center' }}>
          Formulas and Units Used
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          Understanding beer color requires a grasp of various units and their conversions. Below are the key formulas and conversions used in this calculator.
        </Typography>

        <Typography variant="h6" sx={{ marginTop: '16px', color: '#2b2d42' }}>
          Unit Conversions
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          <strong>°L to EBC:</strong> EBC = °L × 2.65 <br />
          <strong>EBC to °L:</strong> °L = EBC / 2.65 <br />
          <strong>SRM to EBC:</strong> EBC = SRM × 1.97 <br />
          <strong>EBC to SRM:</strong> SRM = EBC / 1.97 <br />
          <strong>Imperial Gallons to US Gallons:</strong> US Gal = Imperial Gal × 1.20095 <br />
          <strong>US Gallons to Liters:</strong> Liters = US Gal × 3.78541
        </Typography>

        <Typography variant="h6" sx={{ marginTop: '16px', color: '#2b2d42' }}>
          Malt Color Units (MCU)
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          The Malt Color Units (MCU) is calculated using the formula:
          <strong>MCU = (Grain Weight in lbs × Color in °L) / Batch Volume in gallons</strong>. This provides an estimate of beer color, which is refined using more complex formulas.
        </Typography>

        <Typography variant="h6" sx={{ marginTop: '16px', color: '#2b2d42' }}>
          Color Formulas
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          <strong>Morey Formula:</strong> EBC = 1.4922 × (MCU ^ 0.6859) <br />
          <strong>Daniels Formula:</strong> SRM = MCU × 0.2 <br />
          <strong>Mosher Formula:</strong> SRM = MCU × 0.3
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          These formulas are widely recognized in the brewing community and are detailed in sources such as Ray Daniels' book, "Designing Great Beers." For further insights, this is a recommended read for homebrewers and professionals alike.
        </Typography>

        <Typography variant="h6" sx={{ marginTop: '16px', color: '#2b2d42' }}>
          General Information About Beer Color
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          Beer color is an important aspect of brewing, serving as a visual cue for style, flavor, and quality. The color is derived from the grains used in the brewing process, particularly roasted malts and specialty grains. By adjusting the grain bill, brewers can achieve desired color characteristics while maintaining the intended flavor profile.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          It is important to note that perceived color can vary based on lighting, glassware, and other factors. This calculator provides an estimate based on standard formulas but should be used alongside your brewing experience and visual observations for the best results.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          Adjusting beer color involves adding specific malts or extracts to the recipe. For instance, using roasted malts or caramelized malts can deepen the color and add complexity to the flavor. However, balance is key to ensuring the color aligns with the beer style while preserving the intended taste.
        </Typography>


        {/* General Info Section */}
        <Typography variant="h5" sx={{ marginTop: '32px', color: '#2b2d42', textAlign: 'center' }}>
          What is Beer Color and Why Does it Matter?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          Beer color is more than just its visual appeal—it's an essential clue to understanding a beer’s style, ingredients, and flavor profile.
          The color of beer primarily comes from the type and amount of malts used during the brewing process, with darker malts
          contributing deeper, richer hues. Lighter beers, such as Pilsners and Pale Ales, use pale malts, while Stouts and Porters
          derive their dark, almost black color from roasted malts.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          Historically, beer color was used as a shorthand for identifying styles and even quality. For instance, a deep brown beer might indicate
          a strong, roasted malt character, while a pale straw beer may suggest a lighter, crisper flavor. Today, beer color is also a tool
          for brewers to communicate their creations to consumers and guide expectations.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          Adjusting beer color involves fine-tuning the types and amounts of malts or other grains in your recipe. Roasted barley or caramel malts, for example,
          can deepen the color while adding complexity to the flavor. On the other hand, lighter-colored adjuncts like rice or corn can dilute color
          and soften flavors. The key is to strike a balance so that the color complements the beer's intended style and flavor profile.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          Measuring beer color scientifically involves scales like SRM (Standard Reference Method) or EBC (European Brewing Convention). These scales provide a
          numerical value for color based on light absorption. SRM is most commonly used in the United States, while EBC is more popular in Europe.
          Understanding these scales helps brewers maintain consistency and meet style guidelines.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          In practice, beer color is influenced by many factors beyond malt selection, such as brewing techniques, water chemistry, and even the vessel in which the beer
          is served. It’s important to use color prediction tools, like this calculator, as a guide while also considering these variables.
          Remember, while beer color is an important element, it’s only one aspect of creating an exceptional brew.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
          Whether you’re crafting a crisp, golden lager or a robust, deep brown ale, understanding and controlling beer color is essential for achieving your desired results.
          This not only enhances the visual appeal of your brew but also sets the stage for the taste and experience awaiting your audience.
        </Typography>


      </Box>
      <LookUpGrain
        open={lookupModalOpen}
        onClose={closeLookupModal}
        rowIndex={selectedRowIndex}
        onSelect={(index, value, unit) => {
          const updatedValues = [...inputValues];
          updatedValues[index] = { ...updatedValues[index], color: value, colorUnit: unit };
          setInputValues(updatedValues);
        }}
      />
    </div>

    
  );

};

export default BeerColorCalculator;
